import * as Debug from 'debug';
const debug = Debug('shared:ManageEarningsGuard');

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../types/user';
import { PostService } from '../services/post_service';
import { UserService } from '../services/user_service';

@Injectable()
export class ManageTransfersGuard  {

  constructor(
    private router: Router,
    private uService: UserService
  ) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // debug('postId', postId);

    return new Observable<boolean>( observer => {
      this.uService.curUser$.subscribe( user => {
        if (user) {
          if (user && user.legalAddress &&
              user.legalAddress['city'] &&
              user.legalAddress['countryCode'] &&
              user.legalAddress['line1'] &&
              user.fullLegalName &&
              user.identityDoc) {
            observer.next(true);
          } else {
            this.router.navigate(['/manage/earnings'], { queryParams: { legal: true } });
            observer.next(false);
          }
          observer.complete();
        }
      });
    });
  }

}
