import { Component } from '@angular/core';
import { TooltipService, TooltipDef } from './tooltip.service';

@Component({
  selector: 'postd-tooltip-container',
  templateUrl: './tooltip-container.component.html',
})
export class TooltipContainerComponent {
  tooltips: TooltipDef[];


  constructor(tooltipService: TooltipService) {
    tooltipService.componentList.subscribe((tooltips) => {
      this.tooltips = tooltips;
    });
  }

}
