import * as Debug from 'debug';
const debug = Debug('shared:LoginService');
import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { setAuthToken } from '../interceptors/api.interceptor';

@Injectable()
export class LoginService {
  redirect_uri: string;

  constructor(private http: HttpClient, private router: Router) {
    debug('LoginService constructor');
  }

  validateCode(code: string, state: string) {
    const min_valid_len = 3;

    this.redirect_uri = (window as any).location.origin + (window as any).location.pathname;

    if (code.length > min_valid_len && state.length > min_valid_len) {
      this.validateCode(code, state);
    }
    debug('validateCode', code, state, this.redirect_uri);
    const headers = new HttpHeaders();
    const params: HttpParams = new HttpParams()
      .set('state', state)
      .set('code', code)
      .set('redirect_uri', this.redirect_uri);

    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    this.http
      .post<any>(
        '/api/oauth/code/token',
        {
          headers,
          params,
        },
        { observe: 'response' as 'response' }
      )
      .toPromise()
      .then((response: HttpResponse<any>) => {
        console.log('got token response', response);
        const authorization = response.headers.get('Postd-Auth');
        console.log('auth token', authorization);
        setAuthToken(authorization, false);
      })
      .catch((err: HttpErrorResponse) => {
        console.error('FIXME (login token) display error to user', err);
      });
  }
}
