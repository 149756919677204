import { ActivatedRoute } from '@angular/router';

import * as Debug from 'debug';
const debug = Debug('shared:PurchaseService');

import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { EMPTY, Observable, Subject, from, lastValueFrom, throwError, timer } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { TransactApi } from '../../typings';
import { Payment } from '../types/payment';
import { AnalyticsCustomEvents, AnalyticsService } from './analytics_service';
import { SUBSCRIPTION_PURCHASE_ATTR } from './channel_service';

type AsyncWait<T> = Observable<T> | Promise<T>;

interface PurchaseConfig<T> {
  catchError?: (event: any) => AsyncWait<string>;
  complete?: (token: string) => AsyncWait<T>;
}

export class PurchaseMicroService<T = string> {
  completeCallback: (token: string) => AsyncWait<T>;
  catchFunction: (err: any) => AsyncWait<string>;
  transactApi: any;

  transactModal?: HTMLElement;
  transactBackdrop?: HTMLElement;

  constructor(
    private prePurchaseToken: string,
    purchaseConfig: PurchaseConfig<T>,
    transactApi: TransactApi,
    private analytics: AnalyticsService
  ) {
    this.completeCallback = purchaseConfig.complete;
    this.catchFunction = purchaseConfig.catchError;

    if (transactApi) {
      this.transactApi = transactApi;
      transactApi.setToken(prePurchaseToken);
    }

    if (this.completeCallback && transactApi.purchaseComplete()) {
      this.analytics.fireEvent(AnalyticsCustomEvents.PurchaseComplete);
      const completedPurchaseToken = transactApi.retrieveToken();
      this.completeCallback(completedPurchaseToken);
    }
  }

  purchase(tryCount = 0): Observable<T> {
    const purchaseState = new Subject<string>();

    if (this.transactModal) {
      // if transact purchase modal is already open, don't do anything.
      return EMPTY;
    }

    if (!this.prePurchaseToken) {
      throw Error('Transact API Token not loaded');
    }
    if (!this.transactApi) {
      // If transact API hasn't loaded yet and the user is trying to purchase, then fail
      if (tryCount > 10) {
        return throwError(() => ({
          validation: null,
          popupClosed: true,
          event: { msg: 'Transact API could not be loaded. Please try purchasing again.' },
          complete: false,
        }));
      } else {
        return timer(500).pipe(mergeMap(() => this.purchase(tryCount++)));
      }
    } else {
      this.transactApi.setToken(this.prePurchaseToken);
      this.transactApi.authorizeRedirect();
    }

    const returnObservable = purchaseState.pipe(filter((token) => typeof token === 'string'));
    if (this.completeCallback) {
      return returnObservable.pipe(mergeMap(this.completeCallback));
    } else {
      return null;
    }
  }
}

@Injectable()
export class PurchaseService {
  transactApiLoadedState: Promise<TransactApi>;
  renderer: Renderer2;
  maxRetryAttempts = 3;
  queryParams: Array<{
    name: string;
    value: string;
  }>;

  constructor(
    rendererFactory: RendererFactory2,
    private http: HttpClient,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private analytics: AnalyticsService
  ) {
    debug('PurchaseService constructor');
    this.renderer = rendererFactory.createRenderer(null, null);
    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = [];
      for (const param in queryParams) {
        if (param.startsWith('utm_')) {
          this.queryParams.push({
            name: param,
            value: queryParams[param],
          });
        }
      }
      if (this.queryParams.length > 0 && typeof window.transactApi !== 'undefined') {
        window.transactApi.setAddtlParams(this.queryParams);
      }
    });
  }

  getPurchases(afterParam: any) {
    let params: HttpParams = new HttpParams();

    params = params.set('after', afterParam);

    return lastValueFrom(
      this.http.get<Payment>('/api/user/purchases', { params }).pipe(
        catchError((err) => {
          console.error('could not get latest purchases');
          return from([[]]);
        })
      )
    );
  }

  loadTransact() {
    if (typeof window.transactApi !== 'undefined') {
      return Promise.resolve(window.transactApi);
    }

    if (this.transactApiLoadedState === undefined) {
      let scriptElement: HTMLScriptElement;
      this.transactApiLoadedState = new Promise<TransactApi>((resolve, reject) => {
        let retryCount = 0;
        const intervalRef = setInterval(() => {
          if (retryCount > this.maxRetryAttempts) {
            clearInterval(intervalRef);
            reject('Could not load transact API');
          }
          if (scriptElement) {
            this.renderer.removeChild(this.document.body, scriptElement);
          }
          scriptElement = this.renderer.createElement('script');
          scriptElement.type = 'text/javascript';
          scriptElement.src = `${environment.transactBaseUrl}/assets/js/transact_v2.js`;
          scriptElement.onload = () => {
            if (window.transactApi === undefined) {
              retryCount++;
            } else {
              window.transactApi.setFrontendUrl(`${environment.transactBaseUrl}/purchase`);
              window.transactApi.setAddtlParams(this.queryParams);
              clearInterval(intervalRef);
              resolve(window.transactApi);
            }
          };

          scriptElement.onerror = (error: any) => {
            console.error("Couldn't load script " + scriptElement.src);
            retryCount++;
          };

          this.renderer.appendChild(this.document.body, scriptElement);
        }, 5000);
      });
    }

    return this.transactApiLoadedState;
  }

  checkCompletedSubscriptionPurchase(): Promise<
    | false
    | {
        token: string;
      }
  > {
    const currURL = new URL(this.document.location.href);
    if (currURL.searchParams.get(SUBSCRIPTION_PURCHASE_ATTR)) {
      return this.loadTransact().then((transactApi) => {
        if (!transactApi.purchaseComplete()) {
          return false;
        }
        const completedPurchaseToken = transactApi.retrieveToken();
        return { token: completedPurchaseToken };
      });
    }
    return Promise.resolve(false);
  }

  preparePurchase<T = string>(
    prePurchaseToken: string,
    purchaseConfig: PurchaseConfig<T>
  ): Promise<PurchaseMicroService<T>> {
    if (typeof window.transactApi === 'undefined') {
      return this.loadTransact().then(
        (transactApiResult) =>
          new PurchaseMicroService<T>(
            prePurchaseToken,
            purchaseConfig,
            transactApiResult,
            this.analytics
          )
      );
    } else {
      return Promise.resolve(
        new PurchaseMicroService<T>(
          prePurchaseToken,
          purchaseConfig,
          window.transactApi,
          this.analytics
        )
      );
    }
  }
}
