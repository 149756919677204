import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PostdCommonModule } from '../../../../common/common.module';
import { SharedDirectivesModule } from '../../../../common/directives.module';
import { SharedPipesModule } from '../../../../common/shared-pipes.module';
import { StarRatingModule } from '../../forms/star-rating/star-rating.module';
import { ThumbnailComponent } from './thumbnail.component';

@NgModule({
  declarations: [ThumbnailComponent],
  imports: [
    PostdCommonModule,
    RouterModule,
    SharedPipesModule,
    StarRatingModule,
    SharedDirectivesModule,
  ],
  exports: [ThumbnailComponent],
})
export class ThumbnailModule {}
