import * as Debug from 'debug';
const debug = Debug('shared:NotificationsService');

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { CacheService } from './cache_service';
import { Notification } from '../types/notification';
import { UserService } from './user_service';
import { environment } from '@env/environment';
import * as _ from 'lodash';

@Injectable()
export class NotificationsService {
  constructor(private http: HttpClient) {
    debug('NotificationsService constructor');
  }

  getUserNotifications() {
    return this.http.get<Notification[]>('/api/user/notifications' , {})
      .toPromise();
  }

  deleteNotification(notifyId: string) {
    return this.http.delete<Notification>(`/api/user/notification/${notifyId}/remove` , {})
      .toPromise();
  }

}
