export const mapping = {
    actionadventure      : 'action/adventure',
    animalspets          : 'animals/pets',
    anime                : 'anime',
    artphotography       : 'art & photography',
    automotiverepairs    : 'automotive repairs',
    biographiesmemoirs   : 'biographies & memoirs',
    businessfinance      : 'business/finance',
    businesstobusiness   : 'business-to-business',
    caughtlive           : 'caught live',
    christiangospel      : 'christian & gospel',
    comicsgraphicnovels  : 'comics & graphic novels',
    computerscience      : 'computer science',
    cookbooksfoodwine    : 'cookbooks, food, wine',
    cookingfood          : 'cooking/food',
    craftshobbies        : 'crafts & hobbies',
    crimejustice         : 'crime & justice',
    danceelectronic      : 'dance/electronic',
    filmreviews          : 'film reviews',
    fitnessdiet          : 'fitness & diet',
    foreignpolicy        : 'foreign policy',
    gardeningfarming     : 'gardening & farming',
    governmentpolitics   : 'government/politics',
    hiphoprap            : 'hip-hop/rap',
    homeeconomics        : 'home economics',
    homerepairs          : 'home repairs',
    howto                : 'how to',
    instructioneducation : 'instruction & education',
    languagearts         : 'language arts',
    literaturefiction    : 'literature  & fiction',
    musicvideos          : 'music videos',
    newage               : 'new age',
    nonfiction           : 'non-fiction',
    opinionscommentary   : 'opinions/commentary',
    politicsgovernment   : 'politics/government',
    productreviews       : 'product reviews',
    rb                   : 'r & b',
    realitytv            : 'reality tv',
    sciencefiction       : 'science fiction',
    selfhelp             : 'self-help',
    sciencetechnology    : 'science & technology',
    socialstudies        : 'social studies',
    ideassuggestions     : 'ideas/suggestions',
    talkshows            : 'talk shows',
    testpreparation      : 'test preparation',
    tvfilm               : 'tv & film',
    videoartists         : 'video artists',
    worldgeography       : 'world geography',
    mecha                : 'mecha',
    sliceoflife          : 'slice of life'
};
