import { Injectable, Inject, PLATFORM_ID, OnDestroy, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { catchError, filter, retry, takeUntil } from 'rxjs/operators';

import 'url-search-params-polyfill';

export enum AnalyticsCustomDimensions {
  postPrice = 'postPrice',
}

export enum AnalyticsCustomEvents {
  TransactPopupOpen = 'TransactPopupOpen',
  TransactModalOpen = 'TransactModalOpen',
  TransactModalClosed = 'TransactModalClosed',
  NewPostMediaUploaded = 'NewPostMediaUploaded',
  NewPostTitleEntered = 'NewPostTitleEntered',
  NewPostContentEntered = 'NewPostContentEntered',
  PurchaseComplete = 'PurchaseComplete',
}

// ad_personalization
// ad_storage
// ad_user_data
// analytics_storage
// functionality_storage
// personalization_storage
// security_storage
export type ConsentTypes =
  | 'ad_personalization'
  | 'ad_storage'
  | 'ad_user_data'
  | 'analytics_storage'
  | 'functionality_storage'
  | 'personalization_storage'
  | 'security_storage';

export type ConsentPayload = Partial<{
  [key in ConsentTypes]: 'granted' | 'denied';
}>;

export type CustomDimensionsSet = {
  [key in AnalyticsCustomDimensions]: string | undefined;
};

export class DataLayerCustomDimensionsService {
  private _currentSet: CustomDimensionsSet;

  constructor(router: Router) {
    this._generateEmptyDimensionsSet();
  }

  set dimensions(someDimensions: Partial<CustomDimensionsSet>) {
    Object.keys(AnalyticsCustomDimensions)
      .map((key) => AnalyticsCustomDimensions[key])
      .forEach((key) => {
        this._currentSet[key] = someDimensions[key] || undefined;
      });
  }

  trigger() {
    (window as any).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      [AnalyticsCustomDimensions.postPrice]: '0',
    };
  }
}

@Injectable()
export class AnalyticsService implements OnDestroy {
  dataLayerCustomDimensions: DataLayerCustomDimensionsService;
  private sessionAnalyticsTags: Record<string, string> = {};
  private dispose = new Subject<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.dataLayerCustomDimensions = new DataLayerCustomDimensionsService(router);

    if (isPlatformBrowser(this.platformId)) {
      this.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
      });
    }

    // Update utm parameters for later use
    router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.dispose)
      )
      .subscribe((e) => {
        const first_question = e.url.indexOf('?');
        if (first_question === -1) {
          // no paramaters
          return;
        }
        const path_parms = e.url.slice(first_question + 1);
        if (!path_parms || !path_parms.length) {
          // no paramaters to process.
          return;
        }
        const params = new URLSearchParams(path_parms);
        const utmParams: string[] = [];
        params.forEach((val, key) => {
          if (key.startsWith('utm_')) {
            utmParams.push(key);
          }
        });

        if (utmParams.length > 0) {
          this.sessionAnalyticsTags = {};
          utmParams.forEach((key) => {
            this.sessionAnalyticsTags[key] = params.get(key);
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.dispose.next(void 0);
  }

  getSessionAnalyticsTags() {
    return this.sessionAnalyticsTags;
    // if(Object.values(this.sessionAnalyticsTags).length > 0) {
    //   return this.sessionAnalyticsTags;
    // } else {
    //   return undefined;
    // }
  }

  gtag(..._args: any[]) {
    if (isPlatformBrowser(this.platformId) && (window as any).dataLayer) {
      // eslint-disable-next-line prefer-rest-params
      (window as any).dataLayer.push(arguments);
    }
  }

  updateConsent(update: ConsentPayload) {
    if (isPlatformBrowser(this.platformId)) {
      this.gtag('consent', 'update', update);
    }
  }

  pageView(overridePath?: string | null) {
    if (isPlatformBrowser(this.platformId)) {
      this.sendTagsToBackend();
      this.zone.runOutsideAngular(() => {
        (window as any).dataLayer.push({
          event: 'virtualPageview',
          virtualPageURL: overridePath || this.router.url,
          virtualPageTitle: overridePath || this.router.url,
        });
      });
    }
  }

  fireEvent(eventName: AnalyticsCustomEvents) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        (window as any).dataLayer.push({
          event: eventName,
        });
      });
    }
  }

  updateTags(someDimensions: Partial<CustomDimensionsSet>) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataLayerCustomDimensions.dimensions = someDimensions;
      this.dataLayerCustomDimensions.trigger();
    }
  }

  sendTagsToBackend() {
    console.log('sendTagsToBackend fired', this.sessionAnalyticsTags);
    const url = '/api/util/utm/tags';

    if (!this.sessionAnalyticsTags || Object.keys(this.sessionAnalyticsTags).length < 1) {
      // don't send if empty
      return;
    }

    this.http
      .post(url, this.sessionAnalyticsTags)
      .pipe(
        retry(2), // retry a failed request up to 2 times
        catchError((err: any, caught: Observable<Object>) => {
          console.error('Error put utm', err);
          // return caught;
          return null;
        })
      )
      .subscribe();
  }
}
