// Pull the perplay-media (MediaComponent) from Common to
// reuse in the embed app without dragging in all of the common elements

import { NgModule } from '@angular/core';

import { CurrencyPriceDirective } from '@shared/directives/currency-price.directive';
import { ImageSetDirective } from '@shared/directives/img-set.directive';
import { ModalDirective } from '@shared/directives/modal/modal.directive';
import { ThumbnailImageDirective } from '@shared/directives/post-thumbnail.directive';

@NgModule({
  declarations: [
    ModalDirective,
    CurrencyPriceDirective,
    ImageSetDirective,
    ThumbnailImageDirective,
  ],
  imports: [],
  exports: [ModalDirective, CurrencyPriceDirective, ImageSetDirective, ThumbnailImageDirective],
})
export class SharedDirectivesModule {}
