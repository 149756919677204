import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Post } from '@shared/types/post';
import { ThumbnailResolutions } from '@shared/types/thumbnail_resolutions';

interface BreakpointMap {
  [key: number]: number;
}

@Directive({
  selector: '[postdImgSet]',
})
export class ImageSetDirective implements OnInit {
  @Input() targetWidth: number | BreakpointMap = 300;
  @Input() postdImgSet: ThumbnailResolutions;
  @Input() set deferLoading(value: boolean) {
    if (this._deferLoading && !value) {
      this.loadImages();
    }
    this._deferLoading = value;
  }
  get deferLoading(): boolean {
    return this._deferLoading;
  }

  protected _deferLoading = false;

  constructor(protected el: ElementRef, @Inject(PLATFORM_ID) protected platformId: Object) {}

  ngOnInit() {
    if (!this.deferLoading) {
      this.loadImages();
    }
  }

  loadImages() {
    if (!this.postdImgSet || Object.keys(this.postdImgSet).length === 0) {
      return;
    }

    if (!this.el.nativeElement || !('src' in this.el.nativeElement)) {
      console.warn('image directive used on non-image element, ignoring');
      return;
    }

    const element = this.el.nativeElement as HTMLImageElement;

    const defaultImage = this.populateSrcSet(element, this.postdImgSet);
    if (!element.src) {
      element.src = defaultImage;
    }
    return defaultImage;
  }

  private populateSrcSet(element: HTMLImageElement, thumbnails: ThumbnailResolutions) {
    let defaultImage = '';
    const srcSet = [];
    const sizes = [];
    const availableResolutions = Object.keys(thumbnails)
      .map((res) => parseInt(res, 10))
      .sort((a, b) => a - b);
    let targetWidth = this.targetWidth;

    // If the target width is a breakpoint map, we need to convert it to a sizes attribute on the image
    if (typeof targetWidth !== 'number') {
      const breakpoints = Object.keys(targetWidth)
        .map((res) => parseInt(res, 10))
        .sort((a, b) => a - b);

      let minWidth = Number.MAX_SAFE_INTEGER;
      for (const breakpoint of breakpoints) {
        if (breakpoint !== 0) {
          sizes.push(`(max-width: ${breakpoint}px) ${targetWidth[breakpoint]}px`);
          minWidth = Math.min(minWidth, targetWidth[breakpoint]);
        }
      }

      if (targetWidth[0] !== undefined) {
        sizes.push(`${targetWidth[0]}px`);
      }

      // Let the srcSet code remove the resolutions we don't need
      targetWidth = minWidth;
    }

    let chosenResolution = 0;
    while (availableResolutions.length > 0 && chosenResolution < targetWidth) {
      chosenResolution = availableResolutions.shift();
    }
    if (chosenResolution > 0) {
      defaultImage = thumbnails[chosenResolution];
      srcSet.push(defaultImage);
      for (const resolution of availableResolutions) {
        srcSet.push(`${thumbnails[resolution]} ${(resolution / chosenResolution).toFixed(1)}x`);
      }
    }

    element.srcset = srcSet.join(', ');

    if (sizes.length > 0) {
      element.sizes = sizes.join(', ');
    }

    return defaultImage;
  }
}
