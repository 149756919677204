<div
  class="perplay-media"
  [hidden]="!mediaItem"
  [ngClass]="{ disabled: disable, active: userActive, stopped: !videoPlaying }"
>
  <div class="controls">
    <ng-content></ng-content>
    <div *ngIf="!disable" class="error">
      {{ videoUrl ? '' : 'Error playing content' }}
    </div>
  </div>
  <div class="video-container" [ngClass]="{ embedded: embedded }" *ngIf="post">
    <button class="unmute-button" *ngIf="autoMuted" (click)="unmute()">
      <fa-icon [icon]="['fas', 'volume-mute']"></fa-icon>
      <span>Tap to unmute</span>
    </button>
    <div #videoElementContainer></div>
  </div>
  <div
    class="poster"
    *ngIf="disable && post?.thumbnailImage"
    [style.backgroundImage]="'url(' + post.thumbnailImage + '?t=' + post.updated + ')'"
  ></div>
</div>
<canvas *ngIf="hasThumbnailCapture" #thumbnailCanvas class="thumbnail-saver"></canvas>
<button
  class="button primary capture-thumb"
  (click)="videoPlaying && captureVideoThumbnail()"
  *ngIf="post?.premiumMediaType === 'video' && hasThumbnailCapture"
  postdTooltip
  [tooltipEnabled]="!videoPlaying"
  [disabled]="videoThumbnailCaptured || !videoPlaying"
  boundingBoxSelector="postd-post-media"
  tooltipTitle="Play the video above to capture a thumbnail"
>
  <span *ngIf="!videoThumbnailCaptured">Capture thumbnail at current time</span>
  <span *ngIf="videoThumbnailCaptured">Captured</span>
</button>
<ng-template #qualitySelector>
  <div
    class="vjs-quality-selector vjs-menu-button vjs-menu-button-popup vjs-control vjs-button"
    aria-live="polite"
    aria-disabled="false"
    title="Quality selector"
  >
    <fa-icon [icon]="['far', 'tv-retro']"></fa-icon>
    <span class="vjs-control-text">Quality selector</span>
    <ul class="list"></ul>
  </div>
</ng-template>
<ng-template #videoElement>
  <video
    id="video_postId"
    #mainVideoPlayer
    poster="{{ post?.thumbnailImage ? post.thumbnailImage + '?t=' + post.updated : '' }}"
    class="video-js"
    [ngClass]="{
      'vjs-big-play-centered': !autoplay,
      'auto-paused': autoPaused,
      embedded: embedded
    }"
    playsinline="true"
    preload="none"
  >
    Your browser does not support the video tag.
  </video>
</ng-template>
