import {
  Component,
  Inject,
  OnInit,
  AfterViewChecked,
  Input,
  HostListener,
  TemplateRef,
  Renderer2,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  NgZone,
} from '@angular/core';
import { Router, NavigationStart, ActivationEnd } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { Subscription, interval } from 'rxjs';

import { UserService } from '@shared/services/user_service';
import { PlayQueueService, QueueItem } from '@shared/services/playqueue_service';
import { PostService } from '@shared/services/post_service';
import { PurchaseMicroService } from '@shared/services/purchase_service';
import { Post } from '@shared/types/post';

import * as _ from 'lodash';
import { AnalyticsService } from '@shared/services/analytics_service';

const expiredPostCountdownTime = 2000;
let waitingForResizeAfterDeviceRotation = false;

@Component({
  selector: 'postd-global-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class GlobalPlayerComponent implements OnInit, AfterViewChecked {
  private countdownTimer: Subscription;

  @Input() snapContainer: TemplateRef<any>;
  @Input() snapForPostId: string;
  @ViewChild('navigator', { read: ElementRef }) navigator: ElementRef;

  currQueue: (QueueItem & { complete?: boolean })[];
  purchaseService: PurchaseMicroService<Post>;

  loggedIn: boolean;
  selectedPostQueueItem: QueueItem;
  selectedPostPlaying: Post;
  nextPostInQueue: QueueItem;
  nextPostOptions: QueueItem;
  suggestedNextPosts: Array<Post>;
  previousPostIndex: number;
  showingPreview: boolean;
  checkNavigatorScroll: number;
  playbackFinished = false;

  hideNavigator = false;
  fillWindow: boolean;
  navigationDragging: boolean;
  navigationDragTarget: number;
  navigationDragOffset: number;

  countdownTime: number;

  playerSize: { x: number; y: number };

  windowInfo: {
    width: number;
    height: number;
    aspectRatio?: number;
    orientation: boolean;
  };

  snapInfo: {
    container: ElementRef<any>;
    location?: {
      top: number;
      left: number;
      width: number;
      height: number;
    };
    postId?: string;
  };

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  onResize(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        this.windowInfo &&
        (waitingForResizeAfterDeviceRotation ||
          this.windowInfo.width !== window.innerWidth ||
          this.windowInfo.orientation !== window.innerWidth / window.innerHeight > 1)
      ) {
        // After the orientation change there is a resize event. wait for that event so we get the correct height
        // In ios, the window size stays the same until the resize completes.
        if (event.type === 'orientationchange') {
          waitingForResizeAfterDeviceRotation = true;
        } else {
          waitingForResizeAfterDeviceRotation = false;
          // Wait for embed component
          setTimeout(() => {
            this.snapPlayerToElement();
          }, 100);
        }
      }
    }
  }

  @HostListener('window:mouseup', ['$event'])
  windowMouseUp(event) {
    this.dragEnd(event);
  }

  constructor(
    private playQueueService: PlayQueueService,
    private postService: PostService,
    private userService: UserService,
    private router: Router,
    private renderer: Renderer2,
    private host: ElementRef,
    private zone: NgZone,
    private analyticsService: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.snapInfo = null;
        if (this.playbackFinished) {
          this.selectedPostPlaying = undefined;
        }
      }
      if (event instanceof ActivationEnd) {
        this.unsnapPlayerFromElement();
      }
    });
    this.renderer.addClass(this.host.nativeElement, 'float');
    this.nextPostInQueue = null;
    this.previousPostIndex = null;
    this.checkNavigatorScroll = null;

    // Don't show this floating player in any iframe context.
    if (isPlatformBrowser(this.platformId)) {
      if (window.parent) {
        this.hideNavigator = true;
      }
    }
  }

  ngOnInit() {
    this.playQueueService.currPlaying$.subscribe((currPlaying) => {
      this.suggestedNextPosts = [];
      this.playbackFinished = false;
      this.cancelSkipCountDown();
      if (!currPlaying || !currPlaying.queueItem) {
        this.selectedPostPlaying = undefined;
        return;
      }
      this.selectedPostQueueItem = currPlaying.queueItem;
      this.renderer.removeClass(this.host.nativeElement, 'playing');

      if (currPlaying.playing) {
        this.updateNavigator();
        this.postService.getPostInfo(this.selectedPostQueueItem.postId).subscribe((result) => {
          this.renderer.addClass(this.host.nativeElement, 'playing');

          this.selectedPostPlaying = result;
          this.analyticsService.updateTags({ postPrice: result.price.toString() });
          this.showingPreview = currPlaying.queueItem.preview;
          if (result.purchased === undefined) {
            this.postService.checkPostPurchased(result.id).then((purchased) => {
              this.selectedPostPlaying.purchased = purchased;
              if (!purchased) {
                if (!this.showingPreview) {
                  this.startSkipCountDown();
                }
                this.postService
                  .preparePurchasePost(this.selectedPostPlaying)
                  .subscribe((purchaseService) => {
                    this.purchaseService = purchaseService;
                  });
              }
            });
          } else if (result.purchased === false) {
            this.postService
              .preparePurchasePost(this.selectedPostPlaying)
              .subscribe((purchaseService) => {
                this.purchaseService = purchaseService;
              });
          }

          this.getNextInQueue();
        });

        // Get information for the next posts so we can show their thumbnails
        if (currPlaying.queueItem.nextPostIds && currPlaying.queueItem.nextPostIds.length > 0) {
          const postsToFetch = [...currPlaying.queueItem.nextPostIds];
          // Append the next post in the queue if exists so we can also display it on the video
          if (this.nextPostInQueue && !this.nextPostInQueue.post) {
            postsToFetch.push(this.nextPostInQueue.postId);
          }
          this.postService.getAllPostInfo(currPlaying.queueItem.nextPostIds).subscribe((result) => {
            if (this.nextPostInQueue && this.nextPostInQueue.post) {
              result.push(this.nextPostInQueue.post);
            }

            this.suggestedNextPosts = result.slice(0, 4); // Limit to 4 result to prevent breaking the UI.
          });
        }

        this.snapPlayerToElement();
      } else {
        this.getNextInQueue();
      }
    });

    this.playQueueService.currQueue$.subscribe((queue) => {
      if (!queue || queue.length === 0) {
        this.currQueue = [];
      } else {
        this.currQueue = queue;

        this.postService
          .getAllPostInfo(queue.map((queueItem) => queueItem.postId))
          .subscribe((result) => {
            this.currQueue = result.map((post, index) =>
              Object.assign({}, this.currQueue[index], {
                post,
              })
            );

            result.forEach((post, index) => {
              if (!post) {
                this.playQueueService.removeFromPlayQueue(index);
              }
            });
            this.getNextInQueue();
          });
      }
    });

    this.playQueueService.videoPlayerSnap$.subscribe((playerInfo) => {
      if (!this.selectedPostPlaying) {
        this.renderer.removeClass(this.host.nativeElement, 'playing');
      }
      if (playerInfo && playerInfo.snapElementRef && playerInfo.snapElementRef.nativeElement) {
        this.snapInfo = {
          container: playerInfo.snapElementRef,
          postId: playerInfo.postId,
        };

        this.snapPlayerToElement(playerInfo.fillWindow);
      }
    });

    this.userService.curUser$.subscribe((user) => {
      if (user && user.id) {
        this.loggedIn = true;
      } else if (user && !user.id) {
        this.loggedIn = false;
      }
    });
  }

  getNextInQueue() {
    let nextQueueItem;
    if (nextQueueItem && this.selectedPostPlaying) {
      console.warn('Received video stopped next post trigger while video is playing');
    }
    if (this.selectedPostPlaying) {
      this.analyticsService.updateTags({ postPrice: this.selectedPostPlaying.price.toString() });
      nextQueueItem = {
        postId: this.selectedPostPlaying.id,
        preview: this.showingPreview,
      };
    } else if (this.selectedPostQueueItem) {
      nextQueueItem = this.selectedPostQueueItem;
    }

    if (nextQueueItem && this.currQueue && this.currQueue.length) {
      let complete = true;
      let i: number;
      this.currQueue.forEach((queueItem, index) => {
        if (
          queueItem.postId === nextQueueItem.postId &&
          queueItem.preview === nextQueueItem.preview
        ) {
          complete = false;
          i = index;
        }
        queueItem.complete = complete;
      });

      if (this.selectedPostPlaying) {
        nextQueueItem = this.currQueue[i + 1] ? this.currQueue[i + 1] : null;
      }
      if (nextQueueItem !== null) {
        this.postService.getPostInfo(nextQueueItem.postId).subscribe((result) => {
          nextQueueItem.post = result;
          this.nextPostInQueue = nextQueueItem;
        });
      } else {
        this.nextPostInQueue = undefined;
      }
      this.previousPostIndex = i > 0 ? i - 1 : null;
    }
  }

  previousPost(previousPostIndex: number) {
    if (previousPostIndex !== null) {
      this.playQueueService.playNext(previousPostIndex);
    }
  }

  snapPlayerToElement(fillWindow?: boolean) {
    if (fillWindow !== undefined) {
      this.fillWindow = fillWindow;
    }
    if (
      this.snapInfo &&
      this.selectedPostQueueItem &&
      this.snapInfo.postId === this.selectedPostQueueItem.postId
    ) {
      if (isPlatformBrowser(this.platformId)) {
        let aspectRatio: number;
        const snapElement: HTMLElement = this.snapInfo.container.nativeElement;

        // If post is selected and has a width and height, calculate the aspect ratio
        if (
          this.selectedPostQueueItem &&
          this.selectedPostQueueItem.post &&
          this.selectedPostQueueItem.post.premiumMedia &&
          this.selectedPostQueueItem.post.premiumMedia.length >= 1
        ) {
          const media = this.selectedPostQueueItem.post.premiumMedia[0];
          if ('height' in media && media.height && media.width) {
            aspectRatio = media.height / media.width;
          }
        }

        // Don't do anything if the information matches. If different, re-set the video container size.
        let boundingRect: { width: number; height: number; top: number; left: number };
        if (snapElement.clientWidth) {
          const tmpBounding = snapElement.getBoundingClientRect();
          const wOffset = (tmpBounding.width - snapElement.clientWidth) / 2;
          const hOffset = (tmpBounding.height - snapElement.clientHeight) / 2;
          boundingRect = {
            top: tmpBounding.top + hOffset,
            left: tmpBounding.left + wOffset,
            width: snapElement.clientWidth,
            height: snapElement.clientHeight,
          };
        } else {
          boundingRect = snapElement.getBoundingClientRect();
        }

        this.playerSize = null;
        if (this.fillWindow) {
          this.playerSize = { x: boundingRect.width, y: boundingRect.height };
        }

        if (
          !this.windowInfo ||
          !(
            this.windowInfo.width === window.innerWidth &&
            this.windowInfo.height === window.innerHeight &&
            this.windowInfo.orientation === window.innerWidth / window.innerHeight > 1 &&
            this.windowInfo.aspectRatio === aspectRatio
          )
        ) {
          this.windowInfo = {
            width: window.innerWidth,
            height: window.innerHeight,
            orientation: window.innerWidth / window.innerHeight > 1,
            aspectRatio,
          };
          // On portrait cellphones, resize the video screen to match the aspect ratio
          if (
            !this.fillWindow &&
            window.innerWidth < 800 &&
            window.innerWidth < window.innerHeight
          ) {
            if (aspectRatio > 0.9 && aspectRatio < 1.1) {
              this.playerSize = { x: boundingRect.width, y: boundingRect.width };
              this.renderer.setStyle(snapElement, 'paddingTop', '100%');
            } else if (aspectRatio > 1.1) {
              this.playerSize = { x: boundingRect.width, y: boundingRect.width * 1.6 };
              this.renderer.setStyle(snapElement, 'paddingTop', '160%');
            }
          }
        }

        const docScroll = this.document.scrollingElement || this.document.documentElement;
        const playerLocation = {
          top: boundingRect.top + docScroll.scrollTop,
          left: boundingRect.left,
          width: boundingRect.width,
          height: boundingRect.height,
        };

        this.renderer.setStyle(this.host.nativeElement, 'top', playerLocation.top + 'px');
        this.renderer.setStyle(this.host.nativeElement, 'left', playerLocation.left + 'px');
        this.renderer.setStyle(this.host.nativeElement, 'width', playerLocation.width + 'px');
        this.renderer.setStyle(this.host.nativeElement, 'height', playerLocation.height + 'px');
        this.renderer.removeClass(this.host.nativeElement, 'float');
        this.renderer.addClass(this.host.nativeElement, 'snap');
      }
    }
  }

  unsnapPlayerFromElement() {
    delete this.windowInfo;
    this.renderer.removeStyle(this.host.nativeElement, 'top');
    this.renderer.removeStyle(this.host.nativeElement, 'left');
    this.renderer.removeStyle(this.host.nativeElement, 'width');
    this.renderer.removeStyle(this.host.nativeElement, 'height');
    this.renderer.removeClass(this.host.nativeElement, 'snap');
    this.renderer.addClass(this.host.nativeElement, 'float');
  }

  purchase() {
    const self = this;

    if (this.purchaseService) {
      this.purchaseService.purchase().subscribe(
        (post) => {
          if (this.selectedPostQueueItem.preview) {
            this.playQueueService.replaceLeadMediaWithPremium(post.id);
          } else {
            this.playQueueService.playNext(post, false);
          }
        },
        (err) => {
          console.warn(err);
          if (err.hasOwnProperty('popupClosed') && err.popupClosed === true && err.event) {
            // FIXME (karl) // don't alert but show something usefull to user
            alert('Purchase failed.');
          }
        }
      );
    }
  }

  playPremium() {
    this.playQueueService.replaceLeadMediaWithPremium(this.selectedPostQueueItem.postId);
  }

  exitPreview() {
    this.playQueueService.stop();
    this.playQueueService.removeFromPlayQueue(this.selectedPostPlaying);
  }

  videoError(error) {
    console.log('video error', error);
  }

  finishPlayback() {
    this.playbackFinished = true;
  }

  getPlayingType(post: Post, preview: boolean) {
    if (!post) {
      return '';
    }
    if (preview) {
      return post.leadMediaType;
    } else {
      if (preview === undefined) {
        console.warn('Preview is undefined');
      }
      return post.premiumMediaType;
    }
  }

  postSelected(queueItem: QueueItem) {
    if (!this.navigationDragging) {
      this.playQueueService.playNext(queueItem.post, queueItem.preview);
    }
  }

  ngAfterViewChecked() {
    if (this.navigator && this.navigator.nativeElement) {
      if (
        this.checkNavigatorScroll !== null &&
        this.checkNavigatorScroll !== this.navigator.nativeElement.scrollLeft
      ) {
        this.navigator.nativeElement.scrollLeft = this.checkNavigatorScroll;
        this.checkNavigatorScroll = null;
      }

      this.zone.runOutsideAngular(() => {
        // Add these spammy events in here to prevent app from bogging down
        // Passive false allows us to preventDefault which is critical
        this.navigator.nativeElement.addEventListener(
          'wheel',
          ($event: WheelEvent) => this.scrollNavigation($event),
          { passive: false }
        );
        this.navigator.nativeElement.addEventListener(
          'mousemove',
          ($event: WheelEvent) => this.dragNavigation($event),
          { passive: false }
        );
      });
    }
  }

  cancelPlayback() {
    this.selectedPostPlaying = null;
    this.getNextInQueue();
  }

  removePost(queueItem: QueueItem) {
    this.playQueueService.removeFromPlayQueue(queueItem.post, queueItem.preview);
  }

  navMouseDown(event: MouseEvent) {
    if (this.navigator && this.navigator.nativeElement) {
      this.navigationDragOffset = event.pageX;
      this.navigationDragTarget = this.navigator.nativeElement.scrollLeft;
      // Set dragging to false, because this might be a click
      this.navigationDragging = false;
    }
  }
  scrollNavigation(event: WheelEvent) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.deltaY) {
      this.navigator.nativeElement.scrollLeft += (event.deltaY || 0) + (event.deltaX || 0);
    }
  }

  dragNavigation(event: MouseEvent) {
    event.preventDefault();
    if (this.navigationDragOffset === undefined) {
      this.navigationDragging = false;
    }
    if (
      !this.navigationDragging &&
      this.navigationDragOffset !== undefined &&
      Math.abs(this.navigationDragOffset - event.pageX) > 3
    ) {
      // Set dragging to true if the user drags at least three pixels
      this.navigationDragging = true;
    }
    if (this.navigationDragging && this.navigator && this.navigator.nativeElement) {
      this.navigator.nativeElement.scrollLeft =
        this.navigationDragTarget + this.navigationDragOffset - event.pageX;
    }
  }

  dragEnd(event: MouseEvent) {
    // The click event on the children elements fires after the mouse up event, so the dragging state
    // has to remain true to prevent them from firing. It won't affect the next set of mouse events.
    this.navigationDragOffset = undefined;
  }

  private updateNavigator() {
    if (this.currQueue && this.selectedPostQueueItem) {
      const queueItem = this.selectedPostQueueItem;
      const currIndex = this.currQueue.findIndex(
        (item) => item.postId === queueItem.postId && item.preview === queueItem.preview
      );
      this.checkNavigatorScroll = currIndex * 300;
    }
  }

  private startSkipCountDown() {
    // this.playQueueService.playNext();
    // // Disable the in-player purchasing FOR NOW due to UI difficulties.
    this.countdownTime = expiredPostCountdownTime;
    this.countdownTimer = interval(1000).subscribe((time) => {
      this.countdownTime--;
      if (this.countdownTime <= 0) {
        this.playQueueService.playNext();
        this.cancelSkipCountDown();
      }
    });
  }

  private cancelSkipCountDown() {
    if (this.countdownTimer) {
      this.countdownTimer.unsubscribe();
      delete this.countdownTimer;
    }
  }
}
