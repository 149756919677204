import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { Post } from '@shared/types/post';

import { CategoriesService } from '@shared/services/categories_service';
import { PostService } from '@shared/services/post_service';

import * as _ from 'lodash';
import { Subject, debounceTime, take } from 'rxjs';
export interface DisplayOptions {
  hideDescription?: boolean;
  hidePrice?: boolean;
  showCategories?: boolean;
  showChannel?: boolean;
  showViewsDate?: boolean;
  showRating?: boolean;
  overlayInfo?: boolean;
  disablePreview?: boolean;
  hidePublishDate?: boolean;
  disableLink?: boolean;
}

class PostFocusManager {
  userTouched = false;
  currentPlaying: ThumbnailComponent | null = null;
  registeredComponents = new Map<ThumbnailComponent, { yPos: number; xPos: number }>();
  componentHierarchy: Array<ThumbnailComponent> = [];

  private requestUpdateHirarchy = new Subject<void>();
  private ready = false;

  constructor() {
    this.requestUpdateHirarchy.pipe(debounceTime(250)).subscribe(() => {
      this.updateHierarchy();
    });
  }

  setup(doc: Document) {
    if (this.ready) {
      return;
    }
    // We have to mute the video when autoplaying on page load
    doc.addEventListener(
      'touchend',
      () => {
        this.userTouched = true;
        this.updateHierarchy();
      },
      { once: true }
    );
    this.ready = true;
  }

  setPlaying(component: ThumbnailComponent) {
    if (postFocusManager.currentPlaying === component) {
      return;
    }

    if (postFocusManager.currentPlaying) {
      postFocusManager.currentPlaying.stopPreview();
    }
    postFocusManager.currentPlaying = component;

    component.playPreview();
  }

  clear(component) {
    this.registeredComponents.delete(component);
    if (this.currentPlaying === component) {
      this.currentPlaying = null;
    }
    this.requestUpdateHirarchy.next();
  }

  update(component: ThumbnailComponent, yPos: number, xPos: number) {
    let existing = this.registeredComponents.get(component);
    if (!existing) {
      existing = { yPos, xPos };
      this.registeredComponents.set(component, existing);
    }
    existing.yPos = yPos;

    this.requestUpdateHirarchy.next();
  }

  updateHierarchy() {
    if (!this.ready) {
      return;
    }

    this.componentHierarchy = Array.from(this.registeredComponents.entries())
      .map(([component, { yPos, xPos }]) => {
        let order = yPos + xPos / 1000;
        if (order < 0) {
          order = Math.abs(order) + 10000; // Videos above the fold we don't want to autoplay
        }
        return { component, order };
      })
      .filter(({ component }) => component.previewEnable)
      .sort((a, b) => a.order - b.order)
      .map(({ component }) => component);

    if (this.componentHierarchy.length === 0) {
      return;
    }
    const toPlay = this.componentHierarchy[0];

    if (!toPlay || toPlay === this.currentPlaying) {
      return;
    }

    if (this.currentPlaying) {
      this.currentPlaying.stopPreview();
    }

    const nextPostToPlay = this.componentHierarchy[1];

    if (!this.userTouched) {
      toPlay.playPreview(true);
    } else {
      this.currentPlaying = toPlay;
      toPlay.playPreview();
      // We have to delay the next video init, if we init two videos at the same time videojs gets confused
      setTimeout(() => {
        nextPostToPlay?.initVideo(false);
      }, 1000);
    }
    toPlay.focus.emit();
  }
}

const postFocusManager = new PostFocusManager();

@Component({
  selector: 'postd-post-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent implements OnInit, AfterViewInit, OnDestroy {
  _post: Post;
  durationDisplay: string;
  selectedDisplayOptions: DisplayOptions;
  removable: boolean;

  mediaItem: any;
  mediaType: string;
  videoUrl: string = null;
  videoEnabled = false;
  player: any = '';
  previewEnable = false;
  link: string[] = [];
  deferLoading = true;
  hasThumbnail = false;
  isPlaying = false;
  isMuted = false;

  isMobile = false;

  @Input() set post(value: Post) {
    // copy object so we get methods
    this._post = new Post(value);
    if (!this.selectedDisplayOptions.disableLink) {
      this.link = ['/post', value.id, value.urlName];
    }
    this.hasThumbnail =
      (this._post.thumbnails && Object.keys(this._post.thumbnails).length > 0) ||
      !!this._post.thumbnailImage;
  }
  @Input() set displayOptions(options: any) {
    this.selectedDisplayOptions = _.defaults(options as DisplayOptions, this.defaultDisplayOptions);
    if (this.selectedDisplayOptions.disableLink) {
      this.link = [];
    }
  }
  @Input() fullPreview = false;

  @Output() removeAction = new EventEmitter();
  @Output() focus = new EventEmitter();

  defaultDisplayOptions: DisplayOptions = {
    hideDescription: false,
    hidePrice: false,
    showCategories: false,
    showChannel: true,
    showViewsDate: true,
    showRating: false,
    overlayInfo: true,
    disablePreview: false,
    hidePublishDate: false,
    disableLink: false,
  };

  private onScroll(): void {
    const windowHeight = window.innerHeight;
    const boundingBox = this.elRef.nativeElement.getBoundingClientRect();
    const thisPositionTop = boundingBox.top;

    if (!this._post.channelInfo || !this._post.channelInfo.id) {
      if (isVisible()) {
        this.zone.run(() => {
          this.deferLoading = false;
          if (!this._post.channelInfo || !this._post.channelInfo.id) {
            this.loadChannel();
          }
        });
      }
    }

    if (this.isMobile) {
      postFocusManager.update(this, thisPositionTop, boundingBox.left);
    }

    function isVisible() {
      return thisPositionTop > 0 && thisPositionTop < windowHeight + 250;
    }
  }

  constructor(
    private catService: CategoriesService,
    private elRef: ElementRef,
    private router: Router,
    private postService: PostService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private _document
  ) {
    postFocusManager.setup(this._document);
    this.selectedDisplayOptions = this.defaultDisplayOptions;
  }

  ngOnInit() {
    this.isMobile = this._document.body.clientWidth < 768 && navigator.maxTouchPoints > 0;

    if (this.removeAction.observers.length > 0) {
      this.removable = true;
    }

    this.previewEnable =
      !this.selectedDisplayOptions.disablePreview &&
      !_.isEmpty(this._post.leadMedia) &&
      this._post.leadMedia[this._post.leadMedia.length - 1]['statusCode'] === 'OK'
        ? true
        : false;

    if (isPlatformBrowser(this.platformId)) {
      this.onScroll();
      this.zone.runOutsideAngular(() => {
        this._document.addEventListener(
          'scroll',
          (e) => {
            this.onScroll();
          },
          { passive: true, capture: true }
        );
        window.addEventListener(
          'resize',
          (e) => {
            this.onScroll();
          },
          { passive: true }
        );
      });
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && this.isMobile) {
      postFocusManager.update(this, 0, 0);
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      postFocusManager.clear(this);
    }
  }

  loadChannel() {
    this.postService.loadChannelForPost(this._post).subscribe();
  }

  removePost() {
    this.removeAction.emit(this._post);
  }

  normalizePostName(name: string) {
    if (!_.isEmpty(name)) {
      return this.postService.urlifyName(name);
    }
  }

  getCategories(tags: string[]): string[] {
    const categoryTags: string[] = [];
    _.forEach(tags, (t) => {
      const parts = t.split('-');
      if (parts.length === 2) {
        if (parts[0] === 'category') {
          categoryTags.push(this.catService.categoryToUrl(parts[1]));
        }
      }
    });

    return categoryTags;
  }

  startPreviewVideo() {
    this.initVideo();
  }

  /** Use this function to make sure other videos are paused when playing a new one */
  templatePlayPreview() {
    postFocusManager.setPlaying(this);
  }

  playPreview(muted = false) {
    if (!this.fullPreview) {
      muted = true;
    }
    if (!this.videoEnabled && this._post.leadMediaType === 'video' && this.previewEnable) {
      this.initVideo(true, muted);
    } else if (this.videoEnabled && !_.isEmpty(this.player)) {
      if (this.player.muted() && !muted) {
        // Delay unmuting, there has to be some time between the first touch action unmuting
        // Otherwise chrome can block the unmute event
        setTimeout(() => {
          this.player.muted(false);
          this.isMuted = false;
        }, 500);
      } else {
        this.player.muted(muted);
        this.isMuted = muted;
      }

      if (!this.isPlaying) {
        this.player.play();
      }
    }
    this.isPlaying = true;
  }

  stopPreview() {
    if (!_.isEmpty(this.player) && this.videoEnabled) {
      this.player.pause();
    }
    this.isPlaying = false;
  }

  initVideo(autoplay = true, muted = false) {
    if (this.videoEnabled) {
      return;
    }
    if (!this.fullPreview) {
      muted = true;
    }

    this.videoEnabled = true;

    const el: HTMLElement = this.elRef.nativeElement.querySelector(`#video_${this._post.id}`);
    const videoJsConfig = {
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        vhs: {
          // debug: true,
          overrideNative: true,
        },
      },
      autoplay,
      preload: 'auto',
      muted,
      // loop: true, don't loop cause the page starts to lag once multiple videos are playing
      controls: this.fullPreview,
      aspectRatio: this._post.premiumMediaType === 'audio' ? '1:1' : '16:9',
    };
    this.isMuted = muted;

    this.mediaItem = this.postService.getMediaItem(this._post, true);

    this.videoUrl = null;
    if (this.mediaItem && this.mediaItem.transcoded && this.mediaItem.transcoded.playlistUrl) {
      this.videoUrl = this.mediaItem.transcoded.playlistUrl;
    }
    if (this.mediaItem && this.mediaItem.liveUrl) {
      this.videoUrl = this.mediaItem.liveUrl;
    }

    if (this.videoUrl) {
      this.player = videojs(el, videoJsConfig);
      this.player.on('play', () => {
        // Prevent player from playing with autoplay if the user has moved to another video
        if (postFocusManager.currentPlaying !== this) {
          this.player.pause();
        }
      });

      videojs.Vhs.xhr.beforeRequest = (options) => {
        if (options.uri.startsWith('/api')) {
          options.uri = this._document.location.origin + this.videoUrl;
        }
        return options;
      };

      this.player.poster(this.postService.getPostThumbnail(this._post));
      this.player.src({
        src: this.videoUrl,
        type: 'application/x-mpegURL',
        withCredentials: true,
      });
    }
  }

  redirectToPost() {
    this.router.navigate(['/post', this._post.id, this.normalizePostName(this._post.indexedName)]);
  }

  onMediaLoadError(event: Event) {
    console.log(event);
  }
}
