declare let twttr: any;
import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';

interface TrackingOptions {
  tw_sale_amount: number;
  tw_order_quantity: number;
}

@Injectable()
export class TrackingService {
  renderer: Renderer2;
  trackingObservable: Observable<any>;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.loadTrackingScripts();
  }

  trackPid(pid: string, options: TrackingOptions) {
    if (typeof twttr !== 'undefined') {
      twttr.conversion.trackPid(pid, options);
    } else {
      this.loadTrackingScripts().subscribe((loadedTwttr) => {
        loadedTwttr.conversion.trackPid(pid, options);
      });
    }
  }

  private loadTrackingScripts() {
    if (typeof twttr === 'undefined' && !this.trackingObservable) {
      this.trackingObservable = new Observable((observer) => {
        // Twitter clickthrough tracking code
        const scriptElement = this.renderer.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = '//platform.twitter.com/oct.js';
        scriptElement.onload = () => {
          setTimeout(() => {
            if (typeof twttr !== 'undefined') {
              observer.next(twttr);
              observer.complete();
            } else {
              console.log('Twitter tracking failed to initialize');
            }
          });
        };

        scriptElement.onerror = (error: any) => {
          console.error("Couldn't load script " + scriptElement.src);
          observer.error(false);
          this.renderer.removeChild(this._document.body, scriptElement);
          this.trackingObservable = null;
        };
        this.renderer.appendChild(this._document.body, scriptElement);
      });
      return this.trackingObservable;
    } else if (typeof twttr === 'undefined') {
      return this.trackingObservable;
    } else {
      return of(twttr);
    }
  }
}
