import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationFormatPipe implements PipeTransform {
  transform(seconds: number) {
    if (!seconds) {
      return '';
    } else {
      let result = '';
      let hours = 0;
      if (seconds > 3600) {
        hours = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
      }

      const minutes = Math.floor(seconds / 60);
      seconds = Math.floor(seconds % 60);

      if (hours > 0) {
        result = `${hours}:`;
      }

      const minutesPad = minutes < 10 && hours > 0 ? '0' + minutes : minutes.toString();

      result = result + `${minutesPad}:${seconds.toString().padStart(2, '0')}`;

      return result;
    }
  }
}
