import { ChannelSubscriptionPlan } from './../types/channel-subscription-plan';
import * as Debug from 'debug';
const debug = Debug('shared:ChannelService');

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, from, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, filter, map, share, switchMap, take } from 'rxjs/operators';

import { CacheService } from './cache_service';
import { Channel } from '../types/channel';
import { UserService } from './user_service';
import { Playlist } from '../types/playlist';

import * as _ from 'lodash';
import { Payment } from '@shared/types/payment';
import { DOCUMENT } from '@angular/common';

export const SUBSCRIPTION_PURCHASE_ATTR = 'subPurchase';

interface ChannelMap {
  [key: string]: boolean;
}
const bannedChannelNames = ['new channel', 'fuck', 'shit'];

@Injectable()
export class ChannelService {
  channelsFollowed: ChannelMap;
  channelsFollowedList: Channel[];
  channelInfoCalls: {
    [key: string]: Observable<Channel>;
  };

  // Observable navItem source
  private _channelsFollowed = new BehaviorSubject<Channel[]>(undefined);
  // Observable navItem stream
  curSubscriptionList$ = this._channelsFollowed.asObservable();

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    private userService: UserService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    debug('ChannelService constructor');

    let loading = false;
    this.channelInfoCalls = {};

    this.userService.curUser$.subscribe((user) => {
      // If user is logged in
      if (user && user.id) {
        if (!this.channelsFollowed && !loading) {
          loading = true;
          this.http
            .get<Channel[]>('/api/user/channels/following', {})
            .toPromise()
            .then(
              (response) => {
                loading = false;
                this.channelsFollowed = _.reduce(
                  response,
                  (result, channel: any) => {
                    result[channel.channelId] = true;
                    return result;
                  },
                  {}
                );

                this.updateFollowedChannels(this.channelsFollowed);
              },
              (err) => {
                if (err.status === 403) {
                  // Not authorized, no biggie
                  return {};
                } else {
                  console.error('Could not get followed channels list', err);
                }
              }
            );
        }
      }
    });
  }

  createNewUserChannel(newName: string) {
    const newChannelNameLc = newName.toLowerCase();

    for (let i = 0; i < bannedChannelNames.length; i++) {
      const name = bannedChannelNames[i];
      if (newChannelNameLc.indexOf(name) >= 0) {
        return throwError({
          error: 'INVALID_NAME',
          name,
        });
      }
    }

    return this.channelSearch(newName, true).pipe(
      switchMap((channels) => {
        if (channels.length > 0) {
          return throwError({
            error: 'NOT_UNIQUE',
          });
        } else {
          return this.http.get<Channel>('/api/user/channel/new?name=' + newName);
        }
      })
    );
  }

  updateUserChannel(id: string, updatePayload: any) {
    const result = this.http.post<Channel>('/api/channel/' + id + '/profile', updatePayload);
    result.pipe(take(1)).subscribe((changedChannel) => {
      this.cacheService.setChannelInfo(id, changedChannel);

      const channelsFollowed = this._channelsFollowed.value;
      if (channelsFollowed && channelsFollowed.length > 0) {
        const followedChannelsIndex = channelsFollowed.findIndex(
          (curr) => curr.id === changedChannel.id
        );
        if (followedChannelsIndex !== -1) {
          channelsFollowed.splice(followedChannelsIndex, 1, changedChannel);
          this._channelsFollowed.next(channelsFollowed);
        }
      }
    });
    return result;
  }

  getUserChannels(userId?: number, onlyTrashed = false): Observable<Channel[]> {
    const url =
      (userId ? '/api/user/' + userId + '/channel/list' : '/api/user/channel/list') +
      (onlyTrashed ? '?trashed=1' : '');

    return this.http.get<Channel[]>(url, {}).pipe(
      catchError((err) => {
        if (err.status === 403) {
          // Not logged in, whatevs
          return of([] as Channel[]);
        } else {
          throw err;
        }
      })
    );
  }

  getUserTrashedChannels(userId?: number) {
    return this.getUserChannels(userId, true);
  }

  channelSearch(name: string, exact = false) {
    const query = exact ? `"${name}"` : name;

    return this.http
      .get<{ channels: Channel[] }>(
        `/api/search/channels/simple/query?q=${encodeURIComponent(query)}`
      )
      .pipe(map((res) => res.channels));
  }

  getChannelInfo(chanId: string, refreshData = false): Observable<Channel> {
    const self = this;
    debug('getChannelInfo chanId', chanId);
    debug('getChannelInfo channelInfoCalls', Object.keys(this.channelInfoCalls));
    if (!self.channelInfoCalls[chanId]) {
      self.channelInfoCalls[chanId] = new Observable<Channel>((observer) => {
        let cachedChannel: Channel = null;
        if (!refreshData) {
          cachedChannel = self.cacheService.getChannelInfo(chanId);
        }
        if (cachedChannel && !refreshData) {
          debug('getChannelInfo return cached');
          observer.next(cachedChannel);
          delete self.channelInfoCalls[chanId];
          observer.complete();
        } else {
          const url = '/api/channel/' + chanId + '/profile';
          debug('getChannelInfo starting new call', url);
          const channelGetObservable = this.http.get<Channel>(url, {});

          channelGetObservable
            .pipe(
              catchError((err) =>
                from([
                  {
                    id: '-1',
                    name: chanId,
                    userId: -1,
                    description: '',
                    featuredPosts: [],
                    indexedName: chanId,
                    followers: 0,
                    numPosts: 0,
                    numSubs: 0,
                    ratingAvg: 0,
                    updated: 0,
                    created: 0,
                    latestPostId: '',
                  } as Channel,
                ])
              )
            )
            .subscribe((channelInfo) => {
              debug('getChannelInfo channelInfo', channelInfo);
              self.cacheService.setChannelInfo(chanId, channelInfo);
              // delete maker before sending.
              delete self.channelInfoCalls[chanId];
              observer.next(channelInfo);
              debug('getChannelInfo delete', chanId);
              observer.complete();
            });
        }
      }).pipe(share());
    } else {
      debug('getChannelInfo in progress call', chanId);
    }

    return this.channelInfoCalls[chanId];
  }

  updateShortUrl(channelId: string, desiredShortUrl: string) {
    const url = `/api/channel/${channelId}/short-url/${desiredShortUrl}`;
    return this.http.post(url, {}).toPromise();
  }

  getByShortUrl(shortUrl: string) {
    const url = `/api/search/short-url/channel/${shortUrl}`;
    return this.http.get<{ channel: Channel }>(url).pipe(
      map((result) => {
        this.cacheService.setChannelInfo(result.channel.id, result.channel);

        return result.channel;
      })
    );
  }

  getChannelPlaylists(chanId: string): Promise<Playlist[]> {
    return this.http.get<Playlist[]>('/api/channel/' + chanId + '/playlists', {}).toPromise();
  }

  addChannelPlaylist(chanId: string): Promise<Playlist> {
    return this.http.put<Playlist>('/api/channel/' + chanId + '/playlist/new', {}).toPromise();
  }

  getImageUploadUrl(chanId: string, type: string): string {
    // type = icon or banner
    if (type !== 'icon' && type !== 'banner') {
      throw Error(' image type must be icon or banner');
    }
    return '/api/channel/' + chanId + '/profile/image/' + type;
  }

  isSubscribedToChannel(channelId: string) {
    if (!this.channelsFollowed) {
      return false;
    }
    return this.channelsFollowed[channelId];
  }

  followChannel(chanId: string) {
    const self = this;
    return this.http
      .post<any>('/api/channel/' + chanId + '/follow', {})
      .toPromise()
      .then(
        () => {
          self.channelsFollowed[chanId] = true;
          self.updateFollowedChannels(self.channelsFollowed);
        },
        (err) => {
          if (err.status === 409) {
            self.channelsFollowed[chanId] = true;
            self.updateFollowedChannels(self.channelsFollowed);
          } else {
            throw err;
          }
        }
      );
  }

  unFollowChannel(chanId: string) {
    const self = this;

    return this.http
      .delete<any>('/api/channel/' + chanId + '/follow', {})
      .toPromise()
      .then(
        () => {
          self.channelsFollowed[chanId] = false;
          self.updateFollowedChannels(self.channelsFollowed);
        },
        (err) => {
          if (err.status === 404) {
            self.channelsFollowed[chanId] = false;
            self.updateFollowedChannels(self.channelsFollowed);
          } else {
            throw err;
          }
        }
      );
  }

  deleteChannel(channelId: string): Observable<any> {
    return this.http.delete<Channel>(`/api/channel/${channelId}/delete`, {});
  }

  private updateFollowedChannels(channelMap) {
    const self = this;
    const channelList = _.chain(this.channelsFollowed)
      .omitBy((val) => !val)
      .keys()
      .value();

    return new Promise<Channel[]>((resolve, reject) => {
      forkJoin(
        _.map(channelList, (subscriptionId) => this.getChannelInfo(subscriptionId))
      ).subscribe(
        (results: Channel[]) => {
          self.channelsFollowedList = results;
          self._channelsFollowed.next(this.channelsFollowedList);
        },
        (err) => {
          console.error('failed to load channel info');
        }
      );
    });
  }

  public getSubscriptionPlans(channelId: number | string): Promise<ChannelSubscriptionPlan[]> {
    return this.http
      .get('/api/channel/' + channelId + '/subscription/plans', {})
      .toPromise()
      .then(
        (plans: any) => plans,
        (err) => {
          throw err;
        }
      );
  }

  public putPrice(channelId: string, priceInCents: string, period: string, active: number) {
    if (period === 'YEAR') {
      period = 'YEARLY';
    }

    return this.http
      .put('/api/channel/' + channelId + '/subscription/plan', {
        price: priceInCents,
        period,
        active,
      })
      .toPromise();
  }

  public removePrice(channelId: string, period: string) {
    if (period === 'YEAR') {
      period = 'YEARLY';
    }

    return this.http
      .request('delete', '/api/channel/' + channelId + '/subscription/plan', { body: { period } })
      .toPromise();
  }

  public getSubscriptionStatus(channelId: string): Promise<any> {
    const url = '/api/channel/' + channelId + '/subscription/status';
    return this.http.get(url).toPromise();
  }

  public getSubscriptionPlansSettings(): Promise<object> {
    const url = '/api/util/settings/channel/subscription/min_price';
    return this.http.get(url).toPromise();
  }

  public getViewPaidContentStatus(channelId: number | string): Promise<any> {
    return this.http.get(`/api/channel/${channelId}/can-view-paid-content`).toPromise();
  }

  public getSubscriptionToken(channelId: string, period: string): Promise<string> {
    if (period === 'YEAR') {
      period = 'YEARLY';
    }

    const returnUrl =
      this.doc.location.href + encodeURIComponent(`?${SUBSCRIPTION_PURCHASE_ATTR}=true`);
    return lastValueFrom(
      this.http.get(`/api/channel/${channelId}/subscription/token/${period}?url=${returnUrl}`)
    ).then(
      (resp: any) => resp.token,
      (err) => {
        debug('getStreamSubscriptionToken error', err);
        throw err;
      }
    );
  }

  public validateSubscription(token: string, channelId: string) {
    const params = { token };
    const url = '/api/channel/' + channelId + '/subscription/validate';

    return lastValueFrom(this.http.post(url, params))
      .then(
        (response) => response,
        (err) => err
      )
      .catch((err) => {
        console.error('Error ', err);
      });
  }

  public getChannels(queryObject: {
    q?: string;
    tags?: string;
    fromDate?: string;
    fromOffset?: any;
    ratingMin?: any;
    size?: any;
    sort?: any;
  }): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set('q', queryObject.q ? queryObject.q : '')
      .set('tags', queryObject.tags ? queryObject.tags : '')
      .set('fromDate', queryObject.fromDate ? queryObject.fromDate : '')
      .set('ratingMin', queryObject.ratingMin ? queryObject.ratingMin : '')
      .set('size', queryObject.size ? queryObject.size : '')
      .set('sort', queryObject.sort ? queryObject.sort : '')
      .set('fromOffset', queryObject.sort ? queryObject.sort : '')
      .set('getDetails', '1');

    return this.http.get<any>('/api/search/channels/simple/query', { params }).toPromise();
  }

  public getSales(channelId: string | number): Promise<Payment[]> {
    return this.http.get<Payment[]>(`/api/channel/${channelId}/sales`, {}).toPromise();
  }
}
