import * as Debug from 'debug';
const debug = Debug('shared:LocationService');
import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class LocationService {
  constructor(
      private location: Location,
      @Inject('ORIGIN_URL') protected hostUrl: string
    ) {
    debug('LocationService constructor');
  }

  public getHost(): string {
    if (this.hostUrl) {
      return this.hostUrl;
    } else {
      return null;
    }
  }

  public getCurrExternalUrl(): string {
    // console.log(self.location.path());
    debug('getCurrExternalUrl hostUrl', this.hostUrl);
    debug('getCurrExternalUrl location', this.location);
    if (this.hostUrl) {
      return this.hostUrl + this.location.path();
    } else {
      return null;
    }
  }
}
