<div
  class="thumbnail-container type-{{ _post.premiumMediaType }}"
  [ngClass]="{ 'preview-enabled': previewEnable }"
>
  <div class="thumbnail">
    <a [routerLink]="link" title="{{ _post.name }}">
      <img
        *ngIf="hasThumbnail && !deferLoading"
        [postdThumbnailImage]="_post"
        [targetWidth]="{ '720': 200, '1400': 300, '0': 600 }"
        [deferLoading]="deferLoading"
        alt="{{ _post.name }}"
      />
      <span
        class="media-type"
        *ngIf="!hasThumbnail || deferLoading"
        [ngSwitch]="_post.premiumMediaType"
      >
        <span *ngSwitchCase="'video'"><fa-icon [icon]="['fas', 'film']"></fa-icon></span>
        <span *ngSwitchCase="'text'"><fa-icon [icon]="['far', 'newspaper']"></fa-icon></span>
        <span *ngSwitchCase="'audio'"><fa-icon [icon]="['fas', 'headphones']"></fa-icon></span>
      </span>
    </a>
    <label
      class="price"
      *ngIf="selectedDisplayOptions.overlayInfo && !selectedDisplayOptions.hidePrice"
      >{{ _post?.price === 0 ? 'FREE' : (_post?.price / 100 | currency : 'USD' : 'symbol') }}</label
    >
    <!--<label class="duration" *ngIf="selectedDisplayOptions.overlayInfo && _post?.premiumMedia[_post?.premiumMedia.length-1]?.duration">{{_post?.premiumMedia[_post?.premiumMedia.length-1]?.duration | duration}}</label>-->

    <div
      class="preview-container"
      *ngIf="previewEnable"
      (mouseenter)="templatePlayPreview()"
      (mouseleave)="stopPreview()"
    >
      <div *ngIf="!videoEnabled" class="preview-activate" [ngSwitch]="_post.leadMediaType">
        <button (click)="startPreviewVideo()">
          <!-- <span *ngSwitchCase="'video'">Watch Preview</span> -->
          <span *ngSwitchCase="'audio'">Listen to Preview</span>
          <span *ngSwitchDefault>View Preview</span>
        </button>
      </div>
      <video
        id="video_{{ _post.id }}"
        [ngClass]="{ 'video-bg': player }"
        (click)="redirectToPost()"
        class="preview-video video-js vjs-big-play-centered"
        playsinline
      >
        Your browser does not support the video tag.
      </video>
      <button></button>
      <button
        class="unmute-button"
        *ngIf="isPlaying && isMuted && fullPreview"
        (click)="($event.stopPropagation); playPreview(false)"
      >
        <fa-icon [icon]="['fas', 'volume-mute']"></fa-icon>
        <span>Tap to unmute</span>
      </button>
    </div>

    <a class="remove-button" *ngIf="removable" (click)="removePost()"
      ><fa-icon [icon]="['fas', 'times']"></fa-icon
    ></a>
  </div>
  <div class="description" *ngIf="!selectedDisplayOptions.hideDescription">
    <h3>
      <a [routerLink]="link">
        <span [ngSwitch]="_post.premiumMediaType">
          <span *ngSwitchCase="'video'"><fa-icon [icon]="['fas', 'film']"></fa-icon></span>
          <span *ngSwitchCase="'text'"><fa-icon [icon]="['far', 'newspaper']"></fa-icon></span>
          <span *ngSwitchCase="'audio'"><fa-icon [icon]="['fas', 'headphones']"></fa-icon></span>
        </span>
        <span class="post-title">{{ _post._name || _post.name }}</span>
      </a>
    </h3>
    <h4
      *ngIf="
        selectedDisplayOptions.showCategories && _post.tags && getCategories(_post.tags).length > 0
      "
    >
      <a *ngFor="let tag of getCategories(_post.tags)" [routerLink]="['/category', tag]">{{
        tag
      }}</a>
    </h4>
    <p *ngIf="selectedDisplayOptions.showChannel">
      <a [routerLink]="[_post.getChannelUrlPath()]" class="author">{{ _post.channelInfo?.name }}</a>
    </p>
    <p *ngIf="selectedDisplayOptions.showRating">Rating: {{ _post.rating }}</p>
    <p class="extended description" *ngIf="_post.firstLeadWords">{{ _post.firstLeadWords }}</p>
    <p class="extended rating" *ngIf="_post.rating">
      <postd-star-rating
        [rate]="_post.rating"
        [readOnly]="true"
        [ngClass]="'rating-stars'"
      ></postd-star-rating>
    </p>
    <p class="description" *ngIf="selectedDisplayOptions.showViewsDate">
      <span
        class="extended duration"
        *ngIf="
          _post && _post.premiumMedia && _post.premiumMedia[_post.premiumMedia.length - 1]?.duration
        "
        >{{ _post.premiumMedia[_post.premiumMedia.length - 1]?.duration | duration }} |
      </span>
      <!--{{ _post.purchaseCount || _post.viewCount }} views -->
      <span *ngIf="!selectedDisplayOptions.hidePublishDate">{{
        _post.updated | relativeTime
      }}</span>
    </p>
  </div>
</div>
