import { isPlatformBrowser, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { VERSION } from '@env/version';
import * as Sentry from '@sentry/angular-ivy';
import * as StackTrace from 'stacktrace-js';
import { LoggingService } from './logging_service';
import { SentryErrorHandler } from '@sentry/angular-ivy';

if (!environment.test) {
  Sentry.init({
    dsn: 'https://c4feacf09048484cb402b60bdc18cda6@sentry.io/1243679',
    maxBreadcrumbs: 50,
    debug: !environment.production,

    // release version is for example  gitHash-production
    release: VERSION.hash + '-' + environment.name,
    tracePropagationTargets: [
      'localhost',
      'https://postd.io/api',
      'https://perplay.xsact.com/api',
      'https://postd.xsact.com/api',
    ],
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      new Sentry.Replay(),
    ],
    // To set a uniform sample rate
    tracesSampleRate: 0.2,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

@Injectable()
export class PerplayErrorHandler implements ErrorHandler {
  sentryErrorHandler: SentryErrorHandler;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: LocationStrategy,
    private loggingService: LoggingService
  ) {
    this.sentryErrorHandler = Sentry.createErrorHandler({
      showDialog: true,
    });
  }

  handleError(err) {
    if (isPlatformBrowser(this.platformId)) {
      this.sentryErrorHandler.handleError(err);
      this.handleBrowserError(err);
    } else {
      console.error('ERROR occured:', err);
    }
  }

  handleBrowserError(err) {
    if (environment.test) {
      return true;
    }

    /// our own custom error handling below

    const additionalInfoObject: any = {};

    const url = this.location instanceof PathLocationStrategy ? this.location.path() : '';
    if (err.rejection) {
      err = err.rejection;
    }
    if (err.name === 'HttpErrorResponse') {
      if (err.status < 100 || err.status >= 500) {
        this.loggingService.sendError(err.message, url, 0, '');
      } else {
        console.warn(err.message);
        this.loggingService.sendError(err.message, url, 0, '');
      }
    } else if (err.stack) {
      this.loggingService.sendError(err.message, url, 0, err.stack, additionalInfoObject);
      console.error('Error Logged: ', err);
    } else {
      try {
        // get the stack trace, lets grab the last 10 stacks only
        if (err instanceof TypeError) {
          StackTrace.fromError(err).then((stackframes) => {
            let lineNumber = -1;
            if (stackframes.length > 0) {
              lineNumber = additionalInfoObject.lineNumber = stackframes[0].lineNumber;
              additionalInfoObject.columnNumber = stackframes[0].columnNumber;
            }

            const stackString = stackframes
              .splice(0, 20)
              .map((sf) => sf.toString())
              .join('\n');

            // log on the server
            this.loggingService.sendError(
              err.message,
              url,
              lineNumber,
              stackString,
              additionalInfoObject
            );
            console.error('Error Logged: ', err);
          });
        }
      } catch (traceError) {
        console.error('Could not log error, invalid stacktrace: ', traceError);
      }
    }
  }
}
