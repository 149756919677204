import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faCloud } from '@fortawesome/pro-solid-svg-icons/faCloud';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faFlag } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faFilm } from '@fortawesome/pro-solid-svg-icons/faFilm';
import { faHeadphones } from '@fortawesome/pro-solid-svg-icons/faHeadphones';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faIndent } from '@fortawesome/pro-solid-svg-icons/faIndent';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faFlagCheckered } from '@fortawesome/pro-solid-svg-icons/faFlagCheckered';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faStepBackward } from '@fortawesome/pro-solid-svg-icons/faStepBackward';
import { faStepForward } from '@fortawesome/pro-solid-svg-icons/faStepForward';
import { faEraser } from '@fortawesome/pro-solid-svg-icons/faEraser';
import { faWindowClose } from '@fortawesome/pro-solid-svg-icons/faWindowClose';
import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons/faMoneyBill';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faMap } from '@fortawesome/pro-solid-svg-icons/faMap';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy';
import { faVolumeMute } from '@fortawesome/pro-solid-svg-icons/faVolumeMute';
import { faAngleDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons/faAngleDoubleRight';

import { faNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faTvRetro } from '@fortawesome/pro-regular-svg-icons/faTvRetro';
import { faStar as faStarOutline } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faComment as faCommentOutline } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faCircle as faCircleOutline } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faArrowAltRight } from '@fortawesome/pro-regular-svg-icons/faArrowAltRight';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faObjectGroup } from '@fortawesome/pro-regular-svg-icons/faObjectGroup';
import { faWindowClose as faWindowCloseOutline } from '@fortawesome/pro-regular-svg-icons/faWindowClose';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons/faShareAlt';
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [CommonModule, FontAwesomeModule],
})
export class PostdCommonModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSearch,
      faCog,
      faTrashAlt,
      faCloud,
      faCloudUpload,
      faCheck,
      faTimes,
      faPlus,
      faFlag,
      faExclamationTriangle,
      faPencil,
      faCircleNotch,
      faSort,
      faSortDown,
      faSortUp,
      faInfoCircle,
      faQuestionCircle,
      faCheckCircle,
      faTimesCircle,
      faExclamationCircle,
      faBars,
      faUser,
      faFilm,
      faHeadphones,
      faUpload,
      faDownload,
      faList,
      faListOl,
      faCaretDown,
      faCaretUp,
      faAngleDown,
      faAngleRight,
      faChevronDown,
      faChevronLeft,
      faEye,
      faComment,
      faStar,
      faStarHalf,
      faExternalLink,
      faArrowUp,
      faArrowDown,
      faIndent,
      faInfo,
      faCircle,
      faFlagCheckered,
      faNewspaper,
      faBell,
      faLink,
      faTvRetro,
      faStarOutline,
      faCommentOutline,
      faCircleOutline,
      faPlay,
      faListAlt,
      faStepBackward,
      faStepForward,
      faShare,
      faArrowAltRight,
      faClock,
      faEraser,
      faWindowClose,
      faLayerGroup,
      faMoneyBill,
      faCalendarAlt,
      faDollarSign,
      faMap,
      faTrophy,
      faWindowCloseOutline,
      faObjectGroup,
      faFileDownload,
      faVolumeMute,
      faAngleDoubleLeft,
      faAngleDoubleRight,
      faCode,
      faShareAlt
    );
  }

  public static forRoot(): ModuleWithProviders<PostdCommonModule> {
    return { ngModule: PostdCommonModule, providers: [] };
  }
}
