import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../../common/common.module';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../../../../common/shared-pipes.module';

import { PostCardComponent } from './post-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    PostCardComponent
  ],
  imports: [ PostdCommonModule, SharedPipesModule, RouterModule, FontAwesomeModule ],
  exports: [ PostCardComponent ]
})

export class PostCardModule {}
