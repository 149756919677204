import * as Debug from 'debug';
const debug = Debug('shared:UserService');
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, merge, shareReplay, filter, map } from 'rxjs';
import * as _ from 'lodash';
import * as Sentry from '@sentry/angular-ivy';

import { User } from '../types/user';
import { Review } from '@shared/types/review';
import { Comment } from '@shared/types/comment';
import { CacheService } from '../services/cache_service';
import { environment } from '@env/environment';
import { setAuthToken } from '../interceptors/api.interceptor';
import { Payment } from '@shared/types/payment';

interface UserPreference {
  [key: string]: boolean;
}

interface LeftNavOptions {
  open: boolean;
}

@Injectable()
export class UserService {
  redirect_uri: string;
  curUserPromise: Promise<User>;
  curUser: User; // use the convention undefined is not initialized yet,  User.id == 0 is not logged in.
  leftNavState: LeftNavOptions;

  // Observable navItem source
  private curUserSource = new BehaviorSubject<User>(undefined);
  private leftNavStateSource = new BehaviorSubject<LeftNavOptions>(undefined);
  // Observable navItem stream
  curUser$ = this.curUserSource.asObservable();
  loggedIn = this.curUser$.pipe(
    filter((user) => !!user),
    map((user) => !!user.id),
    shareReplay(1)
  );
  leftNavState$ = this.leftNavStateSource.asObservable();

  userFetchCache: { [key: number]: Observable<User> } = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private cacheService: CacheService
  ) {
    this.redirect_uri =
      typeof window !== 'undefined'
        ? (window as any).location.origin + (window as any).location.pathname
        : '/';

    this.getProfile();
    this.leftNavState = {
      open: false,
    };

    this.curUser$.subscribe((u) => {
      if (this.isLoggedIn()) {
        this.removeUrlAuthParameters();
      }
    });

    // console.log(activeRoute);
  }

  updateLeftNav(options: LeftNavOptions) {
    _.assign(this.leftNavState, options);
    this.leftNavStateSource.next(this.leftNavState);
  }

  getProfile(forceRefresh?: boolean): Promise<User> {
    const self = this;

    if (this.isLoggedIn() && !forceRefresh) {
      return Promise.resolve(this.curUser);
    } else if (!this.curUserPromise || forceRefresh) {
      this.curUserPromise = this.getProfileOnly().then(
        (curUser) => curUser,
        () =>
          self.runOAuth().then(
            (response) => {
              const authorization = response.headers.get('Postd-Auth');
              setAuthToken(authorization, false);

              return self.getProfileOnly();
              // eslint-disable-next-line @typescript-eslint/no-shadow
            },
            (err) => {
              if (err.status === 403) {
                console.warn('getProfile: Not Authenticated');
                self.curUser = {
                  id: null,
                } as User;
                self.curUserSource.next(self.curUser);
                Sentry.setUser({
                  email: self.curUser.email1,
                  id: self.curUser.id,
                });
                return self.curUser;
              }

              console.error('Error:', err);
              return null;
            }
          )
      );
    }

    return this.curUserPromise;
  }

  updateUser(newUserInfo, localOnly?: boolean) {
    const self = this;
    const payload = _.assign(_.clone(this.curUser), newUserInfo);

    if (!localOnly) {
      return this.http
        .post<User>('/api/user/profile', payload)
        .toPromise()
        .then(
          (response) => {
            self.curUser = response;
            self.curUserSource.next(self.curUser);
            return self.curUser as User;
          },
          (err) => {
            throw err;
          }
        );
    } else {
      _.assign(this.curUser, newUserInfo);
      this.curUserSource.next(self.curUser);
    }
  }

  getUser(userId: number, ignoreCache = false): Observable<User> {
    if (!this.userFetchCache[userId] || ignoreCache) {
      this.userFetchCache[userId] = this.http
        .get<User>(`/api/user/${userId}/profile`)
        .pipe(shareReplay(1));
    }
    return this.userFetchCache[userId];
  }

  getUserComments(userId: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(`/api/user/${userId}/posts/comments`, {});
  }

  getUserReviews(userId: number): Observable<Review[]> {
    return this.http.get<Review[]>(`/api/user/${userId}/posts/ratings`, {});
  }

  getProfileImageUploadUrl() {
    return '/api/user/profile/image';
  }

  isLoggedIn(): boolean {
    if (this.curUser === undefined) {
      return undefined;
    }
    return this.curUser.id !== null;
  }

  login(register = false): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http
      .get<any>('/api/oauth/state', httpOptions)
      .toPromise()
      .then((response: HttpResponse<any>) => {
        console.log('got Oauth state', response);
        const authorization = response.headers.get('Postd-Auth');
        console.log('state auth', authorization);
        setAuthToken(authorization, false);
        const transact_url = environment.transactBaseUrl + '/oauth2';
        let registerParam = 0;
        if (register) {
          registerParam = 1;
        }
        const params: HttpParams = new HttpParams()
          .set('redirect_uri', this.redirect_uri)
          .set('response_type', 'code')
          .set('client_id', response.body['client_id'])
          .set('publisher_id', environment.transactGroupId.toString())
          .set('state', response.body['state'])
          .set('register', registerParam.toString())
          .set('scope', 'email,name');

        debug('params', params);
        debug('URL: ' + params.toString());
        document.location.assign(transact_url + '?' + params.toString());
      })
      .catch((err) => {
        console.error('Error ', err);
      });
  }

  logout(): Promise<any> {
    const self = this;
    return new Promise((res, rej) => {
      setTimeout(() => {
        this.http
          .get('/api/user/logout')
          .toPromise()
          .then(
            () => {
              self.curUser = {
                id: null,
              } as User;
              self.cacheService.clearUserCache();
              self.curUserSource.next(self.curUser);
              res(self.curUser);
            },
            (err) => {
              console.error(err);
              rej(err);
            }
          );
      }, 3000);
    });
    // return this.http.get('/api/user/logout')
    //   .toPromise().then(() => {
    //     self.curUser = <User>{
    //       id: null
    //     };
    //     self.cacheService.clearUserCache();
    //     self.curUserSource.next(self.curUser);
    //   }, (err) => {
    //     console.error(err);
    //   });
  }

  setSetting({ metricUnits, emailNotifications, notifyPostComments }: UserPreference) {
    const operations: Observable<any>[] = [];
    const baseUrl = '/api/user/profile/preferences/';

    if (typeof metricUnits !== 'undefined') {
      operations.push(this.http.put(baseUrl + `metricUnits/${metricUnits ? 1 : 0}`, {}));
    }
    if (typeof emailNotifications !== 'undefined') {
      operations.push(
        this.http.put(baseUrl + `emailNotifications/${emailNotifications ? 1 : 0}`, {})
      );
    }
    if (typeof notifyPostComments !== 'undefined') {
      operations.push(
        this.http.put(baseUrl + `notifyPostComments/${notifyPostComments ? 1 : 0}`, {})
      );
    }

    return merge(...operations);
  }

  runOAuth(): Promise<any> {
    if (this.isLoggedIn()) {
      return Promise.resolve(null);
    }
    const code = this.getParameterByName('code');
    const state = this.getParameterByName('state');
    this.removeUrlAuthParameters();

    if (!code || code.length < 10) {
      // not set
      return Promise.reject({ status: 403 });
    }
    if (!state || state.length < 10) {
      // not set
      return Promise.reject({ status: 403 });
    }

    const data = {
      state,
      code,
      redirect_uri:
        typeof window !== 'undefined' ? window.location.origin + window.location.pathname : '',
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>('/api/oauth/code/token', data, httpOptions).toPromise();
  }

  getEarnings(pageSize = 100, offset = 0, range?: { from: Date; to: Date }): Observable<Payment[]> {
    let url = `/api/user/sales?fromOffset=${offset}&limit=${pageSize}`;
    if (range) {
      url += `&fromDate=${range.from.toISOString()}&toDate=${range.to.toISOString()}`;
    }
    return this.http.get<Payment[]>(url);
  }

  userSelfDeleteStart() {
    return this.http.delete('/api/user/profile/delete/start');
  }

  userSelfDeleteConfirm(token: string) {
    return this.http.put(`/api/user/profile/delete/confirm/${token}`, {});
  }

  private getProfileOnly() {
    const self = this;

    return this.http
      .get<User>('/api/user/profile')
      .toPromise()
      .then(
        (response) => {
          self.curUser = response;
          self.curUserSource.next(self.curUser);
          Sentry.configureScope((scope) => {
            scope.setUser({
              email: self.curUser.email1,
              id: self.curUser.id + '',
            });
          });

          if (
            document &&
            document.location &&
            (window as any).hj &&
            document.location.hostname.endsWith('postd.io')
          ) {
            console.log('hotjar login event');

            (window as any).hj('identify', self.curUser.id, {
              displayName: self.curUser.displayName,
              email: self.curUser.email1,
              'Signed up': new Date(self.curUser.created).toISOString(),
              // Add your own custom attributes here. Some EXAMPLES:
              // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
              // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
              // 'Total purchases': 15, // Send numbers without quotes.
              // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
              // 'Last refund date': null, // Send null when no value exists for a user.
            });
          }

          return self.curUser as User;
        },
        (err) => {
          Sentry.configureScope((scope) => scope.setUser({}));
          throw err;
        }
      );
  }

  private getParameterByName(name, url?) {
    if (!url) {
      url = typeof window !== 'undefined' ? window.location.href : '/';
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private removeUrlAuthParameters() {
    // code and state
    if (typeof window !== 'undefined') {
      let changeRoute = false;
      const searchParams = window.location.search;
      if (searchParams && searchParams.length > 0) {
        const parmsArr = searchParams.replace('?', '').split('&');
        for (let i = parmsArr.length - 1; i >= 0; i--) {
          if (parmsArr[i].startsWith('code=') || parmsArr[i].startsWith('state=')) {
            parmsArr.splice(i, 1);
            changeRoute = true;
          }
        }
        if (changeRoute) {
          let newUrl = parmsArr.join('&');
          newUrl = newUrl === '' ? '' : '?' + newUrl;
          newUrl = window.location.pathname + newUrl;
          this.router.navigateByUrl(newUrl);
        }
      }
    }
  }
}
