import * as Debug from 'debug';
const debug = Debug('perplay:ChannelResolver');

import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { ChannelService } from '../services/channel_service';
import { Channel } from '../types/channel';


@Injectable()
export class ChannelResolver  {
    constructor(private channelService: ChannelService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const channelShortId = route.paramMap.get('channelShortId');
    const channelId = route.paramMap.get('channelId');
    const getChannel = channelShortId ? this.channelService.getByShortUrl(channelShortId) :
      this.channelService.getChannelInfo(channelId, true);

    debug('ChannelResolver channelId', channelId);
    debug('ChannelResolver channelShortId', channelShortId);

    return getChannel.pipe(
      catchError((err) => {
        console.error('could not get channel ' + channelId);
        return EMPTY as Observable<Channel>;
      }),
      map(channel => {
        debug('ChannelResolver channel', channel);
        if (channel.shortURLPath && !channelShortId) {
          debug('ChannelResolver navigate to short', channel.shortURLPath);
          this.router.navigate(['/c', channel.shortURLPath]);
          return null;
        } else {
          return channel;
        }
      })
    );
  }

}

