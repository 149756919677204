import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  getAuthToken,
  getCachedTokenByKey,
  getPurchaseTokenForUrl,
} from '@shared/interceptors/api.interceptor';
import { LivestreamService } from '@shared/services/livestream.service';
import { PostService } from '@shared/services/post_service';
import { Media } from '@shared/types/media';
import { Post } from '@shared/types/post';
import * as Debug from 'debug';
import * as lscache from 'lscache';
import { timer } from 'rxjs';
import { take, timeout } from 'rxjs/operators';
const debug = Debug('perplay:MediaComponent');

const videoUserEvents = [
  'mousedown',
  'click',
  'focus',
  'keydown',
  'keypressed',
  'touchstart',
  'touchmove',
];

class VideoSnapper {
  canvasElementRef: HTMLCanvasElement;
  videoElementRef: HTMLVideoElement;

  constructor(canvasRef: ElementRef, videoElementRef: ElementRef, private renderer: Renderer2) {
    this.canvasElementRef = canvasRef.nativeElement;
    this.videoElementRef = videoElementRef.nativeElement;
  }

  /**
   * Capture screen as canvas
   *
   * @param options = width of screen, height of screen, time to seek
   * @param handle function with canvas element in param
   */
  captureAsCanvas(options, handle: (imgData: File) => void) {
    let prevPos: number;
    let removeVideoElementSeekListener: () => void;

    // Create canvas and call handle function
    const callback = () => {
      // Create canvas
      if (options.width && !options.height) {
        options.height =
          options.width * (this.videoElementRef.videoHeight / this.videoElementRef.videoWidth);
      }
      const width = options.width || this.videoElementRef.videoWidth;
      const height = options.height || this.videoElementRef.videoHeight;
      this.renderer.setAttribute(this.canvasElementRef, 'width', width);
      this.renderer.setAttribute(this.canvasElementRef, 'height', height);
      // Get context and draw screen on it
      this.canvasElementRef
        .getContext('2d')
        .drawImage(
          this.videoElementRef,
          0,
          0,
          this.videoElementRef.videoWidth,
          this.videoElementRef.videoHeight,
          0,
          0,
          width,
          height
        );
      // Seek video back if we have previous position
      if (prevPos) {
        // Unbind seeked event - against loop
        removeVideoElementSeekListener();
        // Seek video to previous position
        this.videoElementRef.currentTime = prevPos;
        prevPos = null;
      }
      // Call handle function (because of event)
      const fileBlob = this.canvasElementRef.toBlob((blob) => {
        // const fileObj = new File([blob], 'thumbnail' + Date.now().toString() + '.png', { type: 'image/png' });
        const fileObj = new File([blob], 'thumbnail.png', { type: 'image/png' });
        // this.canvasElementRef.getContext('2d').clearRect(0,0,
        //     this.canvasElementRef.width, this.canvasElementRef.height);
        handle(fileObj);
      }, 'image/png');
    };

    // If we have time in options
    if (options.time && !isNaN(parseInt(options.time, 10))) {
      // Save previous (current) video position
      prevPos = this.videoElementRef.currentTime;
      // Seek to any other time
      this.videoElementRef.currentTime = options.time;
      // Wait for seeked event
      removeVideoElementSeekListener = this.renderer.listen(
        this.videoElementRef,
        'seeked',
        callback
      );

      return;
    } else {
      callback();
    }
  }
}

@Component({
  selector: 'postd-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @Input() post: Post;
  @Input() disable: boolean;
  @Input() mode: string;
  @Input() autoplay: boolean;
  _playerSize: { x: number; y: number }; // 'x:y' or 'fill'
  @Input()
  get playerSize() {
    return this._playerSize;
  }
  set playerSize(size) {
    this._playerSize = size;
    if (this.templateView && size) {
      const el: HTMLElement = this.document.querySelector('.video-container > .video-js');
      const vEl: HTMLVideoElement = el.querySelector('video');
      if (el) {
        this.renderer.setStyle(el, 'height', size.y + 'px');
        this.renderer.setStyle(el, 'width', size.x + 'px');
      }
      if (vEl) {
        this.renderer.setStyle(vEl, 'height', size.y + 'px');
        this.renderer.setStyle(vEl, 'width', size.x + 'px');
      }
    }
  }
  @Output() thumbnailFileCaptured = new EventEmitter();
  @Output() error = new EventEmitter();
  @Output() playbackFinished = new EventEmitter();

  @ViewChild('videoElement') videoElement: TemplateRef<any>;
  @ViewChild('videoElementContainer', { read: ViewContainerRef })
  videoElementContainer: ViewContainerRef;
  @ViewChild('thumbnailCanvas') thumbnailCanvas: ElementRef;
  @ViewChild('mainVideoPlayer') set mainVideoPlayer(element: ElementRef) {
    if (element) {
      debug('main video player element and native element', element, element.nativeElement);
      if (this.removeVideoPlayingListener) {
        this.removeVideoPlayingListener();
      }
      element.nativeElement.crossOrigin = 'Anonymous';
      if (this.thumbnailCanvas) {
        this.videoSnapper = new VideoSnapper(this.thumbnailCanvas, element, this.renderer);
      }
      this.removeVideoPlayingListener = this.renderer.listen(
        element.nativeElement,
        'playing',
        () => {
          debug('VideoPlayingListener reached about to set videoplaying to true');
        }
      );
    }
  }
  @ViewChild('qualitySelector') qualitySelector: TemplateRef<any>;

  templateView: any;
  hasThumbnailCapture: boolean;

  autoMuted = false;
  videoJsPlayer: any;
  initRequired: boolean;
  videoJsInitialized: boolean;
  mediaItem: Media;
  mediaType: string;
  videoUrl: string = null;
  videoQualityEvents = {
    events: ['click', 'touchstart'],
  };
  forceHdQualityLevel: any = this.getUserQualityValue() || 'hd';
  qualityList: any = {
    sd: [],
    hd: [],
  };
  qualityAddDebounce: any;
  videoPlaying: boolean;
  embedded: boolean;
  videoThumbnailCaptured = false;

  // These are not redundant as they serve different purposes. See comments.
  /**
   * True only if user has just sent a interactive event.
   * Used to detect whether the video was paused by the browser (due to the autoplay with audio)
   */
  userActive: boolean;
  /**
   * True only if user has not interacted with the video element in a while.
   * Used to hide the control bar after a while, and re-display the control bar on the first tap on mobile (but not pause)
   */
  userInactive: boolean;

  userEventListeners: Array<{ eventName: string; listener: (e: Event) => void }> = [];

  private removeVideoPlayingListener: () => void;
  private videoSnapper: VideoSnapper;

  constructor(
    private postService: PostService,
    private livestreamService: LivestreamService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.mediaItem = null;
    this.initRequired = true;
    this.embedded = window.location !== window.parent.location;
  }

  ngOnInit() {
    // Safari is unable to capture images from HLS streams
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    this.hasThumbnailCapture =
      this.thumbnailFileCaptured.observers.length > 0 &&
      typeof window['safari'] === 'undefined' &&
      !iOSSafari;
  }

  ngOnChanges(changes: SimpleChanges) {
    debug('media component changes', changes);
    if (changes.post && changes.post.previousValue && changes.post.previousValue.id) {
      if (this.videoJsInitialized) {
        this.removeVideo(changes.post.previousValue.id);
      }
    }
    if (changes.disable) {
      if (changes.disable.currentValue) {
        this.removeVideo();
      } else {
        // if (this.initRequired) {
        //   this.initialize();
        // }
      }
    }

    this.mediaType = this.mode === 'lead' ? this.post.leadMediaType : this.post.premiumMediaType;
  }

  ngAfterViewChecked() {
    // onChanges will not detect a change in post premiummedia like during transcoding
    if (this.initRequired) {
      const mediaItem = this.postService.getMediaItem(this.post, this.mode === 'lead');
      // debug('check post media item', mediaItem );
      if (mediaItem && 'transcoded' in mediaItem && mediaItem.transcoded) {
        if (
          mediaItem.transcoded.state === 'COMPLETED' ||
          mediaItem.transcoded.state === 'WARNING'
        ) {
          setTimeout(() => this.initialize());
        }
      } else if (mediaItem && 'liveUrl' in mediaItem) {
        setTimeout(() => this.initialize());
      }
    }
  }

  ngOnDestroy() {
    this.removeVideo();
  }

  removeVideo(postId: string = this.post.id) {
    debug('removeVideo', this.post, postId, this.mediaItem, this.videoJsInitialized);
    if (postId && this.mediaItem && 'key' in this.mediaItem) {
      if (this.videoJsInitialized) {
        const idToRemove =
          'video_' + (this.mediaItem.key.indexOf('lead') > 0 ? 'lead_' : '') + postId;
        videojs(idToRemove).dispose();
        this.mediaItem = null;
        this.videoJsInitialized = false;
        this.initRequired = true;
        // Remove both the quality selector and the video tempaltes from the container otherwise angular gets confused
        this.videoElementContainer.remove();
        this.videoElementContainer.remove();
      }
    }
  }

  captureVideoThumbnail() {
    if (this.videoSnapper) {
      this.videoThumbnailCaptured = true;
      this.videoSnapper.captureAsCanvas({ width: 500 }, (dataUrl) => {
        this.thumbnailFileCaptured.emit(dataUrl);
        timer(1000)
          .pipe(take(1))
          .subscribe(() => {
            this.videoThumbnailCaptured = false;
          });
      });
    }
  }

  unmute() {
    this.videoJsPlayer.muted(false);
    this.autoMuted = false;
  }

  private initVideo() {
    // Add elements at index 0 otherwise angular gets confused when elements go missing
    this.templateView = this.videoElementContainer.createEmbeddedView(this.videoElement, this, 0);

    const el: HTMLVideoElement = this.templateView.rootNodes.find(
      (node) => node.tagName === 'VIDEO'
    );
    el.crossOrigin = 'use-credentials';

    // change el id to post ID
    el.id = 'video_' + (this.mode === 'lead' ? 'lead_' : '') + this.post.id;

    while (this.userEventListeners.length > 0) {
      const eventToRemove = this.userEventListeners.pop();
      el.parentElement.removeEventListener(eventToRemove.eventName, eventToRemove.listener);
    }

    const videoJsConfig = {
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        vhs: {
          // debug: true,
          overrideNative: true,
        },
      },
      autoplay: false,
      controls: !this.disable,
      qualityLevels: {},
      aspectRatio: this.post.premiumMediaType === 'audio' ? '1:1' : '16:9',
    };
    console.log('init video config', videoJsConfig.html5);
    let paymentToken = getCachedTokenByKey(this.post.id);
    console.log('initVideo payment ', paymentToken);

    if (this.playerSize) {
      this.renderer.setStyle(el, 'height', this.playerSize.y + 'px');
      this.renderer.setStyle(el, 'width', this.playerSize.x + 'px');
      delete videoJsConfig.aspectRatio;
    }

    this.mediaType = this.mode === 'lead' ? this.post.leadMediaType : this.post.premiumMediaType;

    this.mediaItem = this.postService.getMediaItem(this.post, this.mode === 'lead');
    debug('media component mediaItem', this.mediaItem);

    this.videoUrl = null;
    if (this.mediaItem && 'transcoded' in this.mediaItem && this.mediaItem.transcoded.playlistUrl) {
      this.videoUrl = this.mediaItem.transcoded.playlistUrl + '?t=' + paymentToken;
    }
    if (this.mediaItem && 'liveUrl' in this.mediaItem && this.mediaItem.liveUrl) {
      this.videoUrl = this.mediaItem.liveUrl + '?t=' + paymentToken;
    }

    if (this.videoUrl) {
      console.log('initVideo videoUrl ', this.videoUrl);
      const player = videojs(el, videoJsConfig, () => {
        let inactiveTimeout: any;
        // If the user interacts with the video controls, we need to set the user is active flag so
        // we can know when a pause is user initiated
        videoUserEvents.forEach((eventName) => {
          const newListener = (e) => {
            this.userActive = true;
            // console.log(eventName, 'bubbled');

            if (typeof inactiveTimeout !== 'undefined') {
              clearTimeout(inactiveTimeout);
            }
            inactiveTimeout = setTimeout(() => {
              this.userActive = false;
            }, 1000);
          };
          this.userEventListeners.push({
            eventName,
            listener: newListener,
          });

          const parentElement = el.parentElement || player.el();
          parentElement.addEventListener(eventName, newListener);
          // Need to put events on the slider specifically because the slider events don't bubble
          const controlBar = this.elRef.nativeElement.querySelector('.vjs-control-bar');
          controlBar
            .querySelectorAll('.vjs-slider')
            .forEach((e) => e.addEventListener(eventName, newListener));
        });
      });

      this.videoJsPlayer = player;

      if (!player.qualityLevels) {
        console.warn('No Quality levels plugin');
      } else if (this.post.premiumMediaType !== 'audio') {
        this.initQualityControl(player);
      }

      player.reloadSourceOnError({
        errorInterval: 10,
      });

      player.ready(() => {
        console.log('player ready', player);

        if (this.autoplay) {
          player.play();
        }
      });
      player.on('fullscreenchange', () => {
        this.userActive = true;

        setTimeout(() => {
          this.userActive = false;
        }, 1000);
      });
      player.on('useractive', () => {
        this.userInactive = false;
      });
      player.on('userinactive', () => {
        if (this.videoPlaying) {
          this.userInactive = true;
        }
      });
      player.on('play', () => {
        this.videoPlaying = true;
        this.userInactive = true;
      });
      player.on('playing', () => {
        this.videoPlaying = true;
        this.userInactive = true;
      });
      player.on('pause', (event) => {
        const self = this;
        setTimeout(() => {
          if (
            this.document.activeElement !== el &&
            // !this.document.activeElement.closest('.video-js') &&
            !self.userActive &&
            !player.seeking() &&
            !player.scrubbing()
          ) {
            if (self.videoPlaying) {
              self.autoMuted = true;
              player.muted(true);
              player.play();
            }
          } else {
            self.videoPlaying = false;
          }
        }, 50);
      });
      player.on('ended', () => {
        this.videoPlaying = false;
        this.playbackFinished.emit(true);
      });
      player.on('error', (err) => {
        console.error('player error', err);
        this.error.emit(err);
      });

      player.on('stalled', (event) => {
        console.error('player stalled', event);
      });
      let waitingTimeout = null;
      let waitTime = 10000;

      player.on('waiting', (event) => {
        console.log('player waiting', event);

        if (waitingTimeout) {
          clearTimeout(waitingTimeout);
        }

        waitingTimeout = setTimeout(() => {
          console.error('waiting timeout');
          waitTime += 3000;
          const pos = player.currentTime();
          console.log('currentTime', pos);

          player.pause();

          // set timeout after the pause event triggers
          setTimeout(() => {
            if (player.paused()) {
              player.play();
            }
          }, 200);
        }, waitTime);
      });
      player.on('playing', (event) => {
        console.log('player playing', event);
        if (waitingTimeout) {
          clearTimeout(waitingTimeout);
        }
      });

      player.on('volumechange', () => {
        if (!player.muted()) {
          if (this.autoMuted) {
            this.autoMuted = false;
          }
        }
      });

      player.on('touchstart', (event: MouseEvent) => {
        // console.log('touched');
        if (event.target['tagName'] === 'VIDEO') {
          if (player.paused()) {
            player.play();
          } else if (!this.userInactive) {
            player.pause();
          }
        }
      });

      videojs.Vhs.xhr.beforeRequest = (options) => {
        if (options.uri.startsWith('/api')) {
          options.uri = this.document.location.origin + this.videoUrl;
        }

        const auth = getAuthToken();
        if (auth) {
          options.headers = options.headers || {};
          options.headers['Postd-Auth'] = auth;
        }

        paymentToken = getPurchaseTokenForUrl(options.uri);
        if (paymentToken) {
          options.headers = options.headers || {};
          options.headers['Transact-Payment'] = paymentToken;
        }

        // zero timeout can hang forever
        if (options.timeout < 1) {
          console.log('videojs low timeout: ', options);
          options.timeout = 30000;
        }

        if (options.timeout > 60000) {
          console.log('videojs high timeout: ', options);
          options.timeout = 60000;
        }

        return options;
      };

      this.livestreamService
        .tryUntilAvailable(this.videoUrl, 15)
        .pipe(take(1))
        .subscribe(
          () => {
            // FIXME,  this is for cache busting but in #521 we see a CORS issue.
            // player.poster(this.post.thumbnailImage);
            const player_src = {
              src: this.videoUrl,
              type: 'application/x-mpegURL',
              withCredentials: true,
            };
            player.src(player_src);
          },
          () => {
            throw Error('Could not load video stream');
          }
        );
    }
  }

  initialize() {
    debug('initialize', this.disable, this.post, this.initRequired);
    if (!this.disable && this.post && this.initRequired && this.videoElementContainer) {
      this.videoJsInitialized = true;
      this.initRequired = false;
      this.initVideo();
    }
  }

  private initQualityControl(player: any) {
    const qualityLevels = player.qualityLevels();
    let qualitySelectorEl: HTMLElement;

    qualityLevels.on('addqualitylevel', (event: any) => {
      if (!qualitySelectorEl) {
        qualitySelectorEl = this.addQualityControl();
      }

      const qualitySelectorList = qualitySelectorEl.querySelector('.list');

      qualitySelectorList.insertAdjacentHTML(
        'beforeend',
        `<li><a href="#" data-quality="${event.qualityLevel.height}">${event.qualityLevel.height}p</a></li>`
      );

      const qualityLevel = event.qualityLevel;
      this.qualityList[qualityLevel.height >= 720 ? 'hd' : 'sd'].push(qualityLevel.height);

      // Setting the default quality locks the player into that quality and breaks the downgrading for slow connections.
      // Possibly figure out a better solution in the future.
      // clearTimeout(this.qualityAddDebounce);
      // this.qualityAddDebounce = setTimeout(() => {
      //   this.setDefaultQuality(qualityLevels);
      // }, 300);
    });

    qualityLevels.on('change', (event: any) => {
      if (!qualitySelectorEl) {
        return;
      }
      const qualitySelectorList = qualitySelectorEl.querySelectorAll('.list li');

      for (let i = 0; i < qualitySelectorList.length; i++) {
        if (i === event.selectedIndex) {
          qualitySelectorList[i].querySelector('a').classList.add('selected');
        } else {
          qualitySelectorList[i].querySelector('a').classList.remove('selected');
        }
      }
    });

    player.on('loadedmetadata', () => {
      if (!qualitySelectorEl) {
        return;
      }
      const qualitySelectorLevels = qualitySelectorEl.querySelectorAll('.list li a');

      let ignoreNextClick = false;
      this.videoQualityEvents.events.forEach((evt) => {
        qualitySelectorEl.addEventListener(evt, () => {
          if (evt === 'touchstart') {
            ignoreNextClick = true;
          }
          if (evt === 'click' && ignoreNextClick) {
            return;
          }

          if (qualitySelectorEl.classList.contains('show')) {
            qualitySelectorEl.classList.remove('show');
          } else {
            qualitySelectorEl.classList.add('show');
          }
        });

        qualitySelectorLevels.forEach((link: any, i: number) => {
          link.addEventListener(evt, (event: Event) => {
            event.preventDefault();

            if (evt === 'touchstart') {
              ignoreNextClick = true;
            }
            if (evt === 'click' && ignoreNextClick) {
              return;
            }

            const qualityValue = event.target['attributes']['data-quality'].value;
            const quality = qualityValue >= '720' ? 'hd' : 'sd';
            this.setUserVideoQualityLevel(quality);

            for (let j = 0; j < qualityLevels.length; j++) {
              if (j === i) {
                qualityLevels[j].enabled = true;
                qualitySelectorLevels[j].classList.add('selected');
              } else {
                qualityLevels[j].enabled = false;
                qualitySelectorLevels[j].classList.remove('selected');
              }
            }
          });
        });
      });
    });

    // function setDefaultQuality(playerQualityLevels: any) {
    //   for (let i = 0; i < playerQualityLevels.length; i++) {
    //     const quality = playerQualityLevels[i];
    //     if (this.forceHdQualityLevel === 'hd') {
    //       if (Math.max.apply(Math, this.qualityList.hd) === quality.height) {
    //         quality.enabled = true;
    //       } else {
    //         quality.enabled = false;
    //       }
    //     } else {
    //       if (Math.max.apply(Math, this.qualityList.sd) === quality.height) {
    //         quality.enabled = true;
    //       } else {
    //         quality.enabled = false;
    //       }
    //     }
    //   }
    // }
  }

  private addQualityControl() {
    const qualitySelectorEView = this.videoElementContainer.createEmbeddedView(
      this.qualitySelector,
      this
    );
    const controlBar = this.elRef.nativeElement.querySelector('.vjs-control-bar');

    const fullScreenBtn = controlBar.querySelector('.vjs-fullscreen-control');

    qualitySelectorEView.rootNodes.forEach((node) => {
      this.renderer.insertBefore(controlBar, node, fullScreenBtn);
    });

    return controlBar.querySelector('.vjs-quality-selector');
  }

  private setUserVideoQualityLevel(level: string) {
    lscache.set('userVideoQualityLevel', level);
  }

  private getUserQualityValue() {
    return lscache.get('userVideoQualityLevel');
  }
}
