import * as Debug from 'debug';
const debug = Debug('shared:ClassificationCategoryService');

import { Injectable } from '@angular/core';
import { classificationsCategories } from '../types/classifications-categories';

import * as _ from 'lodash';

@Injectable()
export class ClassificationCategoryService {
  get(item: string): string[] {
    if (this.getKeys().indexOf(item) >= 0) {
      const results = _.union(
        _.map(classificationsCategories, (record) => record[item])
      );
      return results.sort();
    } else {
      return [];
    }
  }

  getBy(filter: any) {
    if (_.isEmpty(filter.type) || filter.type === 'all') {
      delete filter['type'];
    }

    if (_.isEmpty(filter.classification) || filter.classification === 'all') {
      delete filter['classification'];
    }

    if (!_.isEmpty(filter.category)) {
      filter['category'] = _.replace(filter.category, '-', '');
    }

    return _.filter(classificationsCategories, filter);
  }

  getAll() {
    return classificationsCategories;
  }

  getCounts(filter: any): number {
    const records = this.getBy(filter);
    return records.length;
  }

  getFrom(items: any[], field: string) {
    const fields: string[] = [];
    _.forEach(items, (item) => {
      const itemParts = item.split('-');
      if (itemParts.length > 1) {
        if (itemParts[0] === field) {
          fields.push(itemParts[1]);
        }
      }
    });
    return fields;
  }

  private getKeys(): string[] {
    debug('keys', _.keys(classificationsCategories[0]));
    return _.keys(classificationsCategories[0]);
  }
}
