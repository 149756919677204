<div [class]="'br br-' + theme" [class.br-readonly]="readOnly" [class.br-disabled]="disabled">
  <div class="br-units" (mouseleave)="reset()">

    <div class="br-unit" *ngFor="let unit of contexts" (click)="unit.click($event)" (mouseenter)="unit.enter()">
      <span [hidden]="!unit.active && !unit.selected"><fa-icon [icon]="['fas', 'star']"></fa-icon></span>
      <span [hidden]="unit.active || unit.selected || unit.fraction"><fa-icon [icon]="['far', 'star']"></fa-icon></span>

      <fa-layers [hidden]="!unit.fraction" class="fa-fw">
        <fa-icon [icon]="['fas', 'star-half']" [fixedWidth]="true"></fa-icon>
        <fa-icon [icon]="['far', 'star']" [fixedWidth]="true"></fa-icon>
      </fa-layers>
    </div>
  </div>
</div>
