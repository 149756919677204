import { SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';

import { Address } from './address';
import { Media } from './media';
import { Channel } from './channel';
import { ThumbnailResolutions } from './thumbnail_resolutions';

import { environment } from '@env/environment';

interface ThumbnailMeta {
  channels: 3;
  format: string;
  hasAlpha: 0 | 1;
  hasProfile: 0 | 1;
  height: number;
  isProgressive: 0 | 1;
  size: number;
  space: string;
  width: number;
}

export enum PostState {
  PUBLIC = 'PUBLIC',
  HIDDEN = 'HIDDEN',
  TAKEN_DOWN = 'TAKEN_DOWN',
  GEOFENCE_COUNTRY = 'GEOFENCE_COUNTRY',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  TRASH = 'TRASH',
}

export enum CreateType {
  Livestream = 'livestream',
  Video = 'video',
  Audio = 'audio',
  Article = 'article',
}

export const DOWNLOAD_TAG = 'download';

export class Post {
  public id: string;
  public channel: Channel; // NOTE only on search backend calls that return Enriched Post
  public channelId: string;
  public userId: number;
  public state: PostState = PostState.HIDDEN;
  public updated: number;
  public created: number;
  public published: number;
  public price: number;
  public price_dl?: number;
  public get name() {
    return this._name;
  }
  public set name(newName: string) {
    this._name = newName;
    this.urlName = this.urlifyName(this.name);
    this.urlPath = 'post/' + this.id + '/' + this.urlName;
  }
  public indexedName: string;
  public urlName: string;
  public urlPath: string;
  public shortURLPath: string;
  public tags: string[];
  public leadContent: SafeHtml;
  public leadWrdCnt = 0; // word count of lead content
  public _premiumContent: SafeHtml;
  public premWrdCnt = 0; // word count of premium content
  public status: number;
  public thumbnailImage: string;
  public thumbnails: ThumbnailResolutions;
  public firstLeadWords: string;
  public firstPremWords: string;
  public viewCount: number; // how many times the content has been fetched
  public viewing: number | undefined; // how many users are currently watching this stream. Livestream only.
  public purchaseCount: number; // how many times the content has been paid for
  public minAge: number;
  public minCostPrice: number;
  public enComments: boolean;
  public channelName: string;
  public userDisplayName: string;
  public abuseCount: number;
  public language: string;

  public commentsCount: number;
  public likesCount: number;
  public featured: number;

  public address: Address;
  public latitude: number;
  public longitude: number;
  public countries: string[];
  public thumbnailMeta: ThumbnailMeta;

  ratingAvg: number;
  rating1star: number;
  rating2star: number;
  rating3star: number;
  rating4star: number;
  rating5star: number;
  rating: number;
  ratings: number[];
  ratingsCount: number;

  leadMediaType: string;
  premiumMediaType: string;
  purchased: boolean;

  private _name: string;
  private _channelInfo: Channel;
  private _leadMedia?: Media[];
  private _premiumMedia?: Media[];
  private _firstPremWords?: string;

  get channelInfo(): Channel {
    if (this._channelInfo) {
      return this._channelInfo;
    } else {
      return {
        name: '',
      } as Channel;
    }
  }
  set channelInfo(channelInfo) {
    this._channelInfo = channelInfo;
  }
  get fullExternalURL() {
    if (this.shortURLPath) {
      return environment.postdBaseUrl + '/p/' + this.shortURLPath;
    }
    return environment.postdBaseUrl + '/post/' + this.id + '/' + this.urlifyName(this.name);
  }
  get leadMedia() {
    return this._leadMedia;
  }
  set leadMedia(media: Media[]) {
    this._leadMedia = media;
    this.leadMediaType = media && media.length > 0 ? this.getMediaType(media[0]) : 'text';
  }

  get premiumContent() {
    return this._premiumContent;
  }
  set premiumContent(premiumContent: SafeHtml) {
    this.purchased = this.purchased || premiumContent !== undefined;
    this._premiumContent = premiumContent;
  }

  get premiumMedia() {
    return this._premiumMedia;
  }
  set premiumMedia(media: Media[]) {
    // for testing
    // if(this.id === '5765983402721280_0_1504450531929') {
    //   if(media && media[0] && media[0].transcoded && media[0].transcoded.playlistUrl) {
    //     media[0].transcoded.playlistUrl = 'https://s3.amazonaws.com/perplay-media-transcoded/test/audio/test_playlist.m3u8';
    //   }
    //   this._premiumMedia = media;
    //   this.premiumMediaType = 'audio';
    //   return;
    // }
    // end for testing

    this._premiumMedia = media && media.length > 0 ? media : [];
    this.premiumMediaType =
      media && media.length > 0 ? this.getMediaType(media[media.length - 1]) : 'text';
  }

  constructor(postData: any) {
    this.id = postData.id || '';
    this.channelId = postData.channelId || '';
    this.channel = postData.channel || null;
    this.userId = postData.userId || undefined;
    this.name = postData.name || '';

    // can happen hitting back button
    if (!this.name && postData._name) {
      this.name = postData._name;
    }
    this.shortURLPath = postData.shortURLPath || '';
    this.state = postData.state || 'HIDDEN';
    this.updated = postData.updated || 0;
    this.created = postData.created || 0;
    this.published = postData.published || 0;
    // this.purchased; = undefined means not loaded
    if (
      !postData.countries ||
      (postData.countries && postData.countries.indexOf && postData.countries.indexOf('ALL') > -1)
    ) {
      this.countries = [];
    } else {
      this.countries = postData.countries;
    }

    this.tags = postData.tags;
    this.indexedName = postData.indexedName;
    this.minAge = postData.minAge;
    this.minCostPrice = postData.minCostPrice;
    this.price = postData.price;
    this.price_dl = postData.price_dl;
    this.leadContent = postData.leadContent;
    // Don't do anything if premium content is not set because purchased gets set to false.
    if ('premiumContent' in postData) {
      this.premiumContent = postData.premiumContent;
    }
    this.leadWrdCnt = postData.leadWrdCnt;
    this.premWrdCnt = postData.premWrdCnt;
    this.status = postData.status;
    this.thumbnailImage = postData.thumbnailImage;
    this.thumbnailMeta = postData.thumbnailMeta;
    this.thumbnails = postData.thumbnails;
    this.viewCount = postData.viewCount;
    this.viewing = postData.viewing;
    this.purchaseCount = postData.purchaseCount;
    this.firstLeadWords = postData.firstLeadWords;
    this.firstPremWords = postData.firstPremWords;
    this.enComments = typeof postData.enComments !== 'undefined' ? postData.enComments : true;
    this.commentsCount = typeof postData.commentsCount !== 'undefined' ? postData.commentsCount : 0;
    this.likesCount = typeof postData.likesCount !== 'undefined' ? postData.likesCount : 0;
    this.featured = postData.featured;
    this.channelName = postData.channelName;
    this.userDisplayName = postData.userDisplayName;
    this.abuseCount = postData.abuseCount;
    this.language = postData.language;

    this.ratingAvg = postData.ratingAvg;
    this.rating1star = postData.rating1star;
    this.rating2star = postData.rating2star;
    this.rating3star = postData.rating3star;
    this.rating4star = postData.rating4star;
    this.rating5star = postData.rating5star;
    this.ratings = [
      0,
      postData.rating1star || 0,
      postData.rating2star || 0,
      postData.rating3star || 0,
      postData.rating4star || 0,
      postData.rating5star || 0,
    ];
    this.ratingsCount = _.sum(this.ratings);
    this.rating =
      _.reduce(this.ratings, (result, value, index) => result + value * index, 0) /
      this.ratingsCount;

    this.address = postData.address;
    if (postData.address) {
      this.latitude = postData.address.latitude;
      this.longitude = postData.address.longitude;
    }
    this.leadMediaType = this.premiumMediaType = 'text';
    this.leadMedia = postData.leadMedia || postData._leadMedia;
    this.premiumMedia = postData.premiumMedia || postData._premiumMedia;
    // console.log('post constructor', postData, this);
  }

  getRating(postData: any): Post {
    const resultData = postData;
    resultData.ratings = [
      0,
      postData.rating1star || 0,
      postData.rating2star || 0,
      postData.rating3star || 0,
      postData.rating4star || 0,
      postData.rating5star || 0,
    ];
    resultData.ratingsCount = _.sum(resultData.ratings);
    resultData.rating =
      _.reduce(resultData.ratings, (result, value, index: number) => result + value * index, 0) /
      resultData.ratingsCount;
    return resultData;
  }

  public getChannelUrlPath(): string {
    if (this.channel && this.channel.shortURLPath) {
      return '/c/' + this.channel.shortURLPath;
    }

    return '/channel/' + this.channelId;
  }

  private urlifyName(name: string) {
    let str = name.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  }

  private getMediaType(media: any) {
    if (media && media.mime && typeof media.mime === 'string') {
      if (media.type === 'video/x-ms-asf') {
        // .wma audio file - uses video mime type
        return 'audio';
      }
      if (media.mime.indexOf('audio') !== -1) {
        return 'audio';
      } else if (media.mime.indexOf('video') !== -1) {
        return 'video';
      }
    } else if (media.liveUrl) {
      return 'livestream';
    }
    return 'text';
  }
}
