export const meta = {
    defaultTitle: 'postd.io',
    // description limited to 300 characters
    description:
        'Postd provides a marketplace for all forms of digital media, without ' +
        'relying on advertising revenue. By using the Transact web-based debit card, ' +
        'contributors can earn income from their creations while their audience enjoys ' +
        'greatly improved viewing, listening, and reading experiences.',
    keywords: 'postd.io digital media publish Transact',
    buildInfo: {
      version: '0.0.0',
      buildDate: new Date().toISOString()
    }
};
