import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../common/common.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SharedPipesModule } from '../../../common/shared-pipes.module';
import { MediaModule } from '../posts/media/media.module';
import { PostCardModule } from '../posts/post-card/post-card.module';
import { ThumbnailModule } from './../posts/thumbnail/thumbnail.module';

import { GlobalPlayerComponent } from './player.component';

@NgModule({
  declarations: [
    GlobalPlayerComponent
  ],
  imports: [
    FontAwesomeModule,
    PostdCommonModule,
    FormsModule,
    RouterModule,
    SharedPipesModule,
    MediaModule,
    PostCardModule,
    ThumbnailModule
  ],
  exports: [ GlobalPlayerComponent ]
})

export class GlobalPlayerModule {}
