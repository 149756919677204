import { Directive, Input, HostListener, OnDestroy, ElementRef, Inject, OnChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[postdTooltip]'
})
export class TooltipDirective implements OnDestroy, OnChanges {

  @Input() tooltipEnabled: boolean;
  @Input() tooltipTitle: string;
  @Input() boundingBoxSelector: string;

  boundingBox: Element;
  private id: string;

  constructor(private tooltipService: TooltipService, private element: ElementRef, @Inject(DOCUMENT) private document: Document) {
    this.id = Math.random().toString();
    if (this.tooltipEnabled === undefined) {
      this.tooltipEnabled = true;
    }
    if (this.tooltipTitle === undefined) {
      this.tooltipTitle = '';
    }
  }

  ngOnChanges(changes) {
    if (changes['boundingBoxSelector']) {
      if (this.boundingBoxSelector && this.document) {
        this.boundingBox = this.document.querySelector(this.boundingBoxSelector);
      }
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.tooltipEnabled) {
      this.tooltipService.push({
        id: this.id,
        ref: this.element,
        boundingBox: this.boundingBox,
        title: this.tooltipTitle
      });
    }
  }

  @HostListener('mouseleave')
  @HostListener('click')
  onMouseLeave(): void {
    this.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    this.tooltipService.remove(this.id);
  }

}
