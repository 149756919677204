<ng-container *ngIf="post">
  <div *ngIf="!directLink" class="thumbnail" (click)="gotoPost()" [ngClass]="{ audio: post.premiumMediaType === 'audio' }">
    <img *ngIf="thumbnailUrl" src="{{thumbnailUrl}}" alt="" (dragstart)="false" draggable="false">
    <span class="media-type" *ngIf="!thumbnailUrl" [ngSwitch]="post.premiumMediaType">
      <span *ngSwitchCase="'video'"><fa-icon [icon]="['fas', 'film']"></fa-icon></span>
      <span *ngSwitchCase="'text'"><fa-icon [icon]="['far', 'newspaper']"></fa-icon></span>
      <span *ngSwitchCase="'audio'"><fa-icon [icon]="['fas', 'headphones']"></fa-icon></span>
    </span>
  </div>
  <div *ngIf="directLink" class="thumbnail" [routerLink]="['/post', post.id] | urlifyName : post.indexedName">
    <img *ngIf="thumbnailUrl" src="{{thumbnailUrl}}" alt="" (dragstart)="false" draggable="false">
    <span class="media-type" *ngIf="!thumbnailUrl" [ngSwitch]="post.premiumMediaType">
      <span *ngSwitchCase="'video'"><fa-icon [icon]="['fas', 'film']"></fa-icon></span>
      <span *ngSwitchCase="'text'"><fa-icon [icon]="['far', 'newspaper']"></fa-icon></span>
      <span *ngSwitchCase="'audio'"><fa-icon [icon]="['fas', 'headphones']"></fa-icon></span>
    </span>
  </div>
  <div class="title">
    <a *ngIf="!directLink" (click)="gotoPost()" (dragstart)="false" draggable="false"
      title="{{post.name}} {{preview ? '(Preview)' : ''}}">{{post.name}} {{preview ? '(Preview)' : ''}}</a>
    <a *ngIf="directLink" [routerLink]="['/post', post.id] | urlifyName : post.indexedName" (dragstart)="false" draggable="false"
      title="{{post.name}} {{preview ? '(Preview)' : ''}}">{{post.name}} {{preview ? '(Preview)' : ''}}</a>
    <a [routerLink]="[ post.getChannelUrlPath() ]"
      draggable="false" class="author">{{post.channelInfo?.name}}</a>
  </div>
  <span *ngIf="post.premiumMedia" class="duration">{{post.premiumMedia[post.premiumMedia.length-1]?.duration | duration}}</span>
  <span class="remove" *ngIf="removable"><a (click)="triggerRemovePost()"><fa-icon [icon]="['fas', 'times']"></fa-icon></a></span>
</ng-container>
