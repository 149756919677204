import * as Debug from 'debug';
const debug = Debug('shared:PaymentsService');

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bank } from '../types/bank';
import { ChannelPayment } from '../types/payment';
import { User } from '../types/user';
import { UserService } from './user_service';

@Injectable()
export class PaymentsService {
  constructor(private http: HttpClient, private userService: UserService) {
    debug('PaymentsService constructor');
  }

  getPaymentsSummary(channelId: string, type: string, time: number): Observable<ChannelPayment> {
    return this.http.get<ChannelPayment>(
      `/api/channel/${channelId}/payments/summary/${type}/${time}`,
      {}
    );
  }

  uploadFile(file: File, type?: string): Observable<any> {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    console.log('uploadFile: ', file, formData);

    const options = {
      reportProgress: true,
    };

    const apiUrl: string =
      type === 'identityDoc' ? 'legal/identity_document' : 'legal/tax_document';

    return this.http.post<any>(`/api/user/profile/${apiUrl}`, formData, options);
  }

  updateLegalInfo(form: any): Observable<User> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<User>('/api/user/profile/legal/info', form).pipe(
      map((user) => {
        this.userService.updateUser(user);
        return user;
      })
    );
  }

  getDocsUploadUrl() {
    return '/api/user/profile/image';
  }

  addBankAccount(accountData: Bank): Observable<any> {
    return this.http.post<Bank>('/api/user/profile/bank/info', accountData);
  }

  getBankAccounts(): Observable<Bank[]> {
    return this.http.get<Bank[]>('/api/user/profile/bank/list', {});
  }

  deleteBankAccount(bankId: string): Observable<any> {
    return this.http.delete<Bank>(`/api/user/profile/bank/${bankId}`, {});
  }

  getFee(bankId: string): Observable<any> {
    return this.http.get<Bank>(`/api/user/profile/bank/${bankId}/transfer/fee`, {});
  }

  requestTransfer(transferData: {}): Observable<any> {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    return this.http.post<Bank>('/api/user/payout/request/bank', transferData, options);
  }

  getTransfers(): Observable<any> {
    return this.http.get<any>('/api/user/payouts/list', {});
  }

  getDocument(fileType: string) {
    return document.location.assign(`/api/user/profile/legal/${fileType}_document`);
  }
}
