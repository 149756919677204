import { Routes, ActivatedRouteSnapshot, Router } from '@angular/router';

import { LoginGuard } from '@shared/guards/login.guard';
import { ManageChannelGuard } from '@shared/guards/manage-channel.guard';
import { ManageTransfersGuard } from '@shared/guards/manage-earnings.guard';
import { CreateType } from '@shared/types/post';
import { PostService } from '@shared/services/post_service';
import { inject } from '@angular/core';

export const pathRootV2 = 'manage-v2/';
const pathRootV1 = 'manage/';

export const browserRoutes: Routes = [
  {
    path: `${pathRootV2}published/:postId`,
    loadChildren: () =>
      import('./frontend/post-publish/post-publish.module').then((m) => m.PostPublishModule),
  },
  // New Manager
  {
    path: `${pathRootV2}post/:postIdOrType`,
    loadChildren: () =>
      import('./frontend/manage-post-v2/manage-post.module').then((m) => m.ManagePostV2Module),
    canActivate: [
      LoginGuard,
      ManageChannelGuard,
      (route: ActivatedRouteSnapshot) => {
        switch (route.params.postIdOrType) {
          case CreateType.Livestream:
          case CreateType.Video:
          case CreateType.Audio:
          case CreateType.Article:
          case 'text':
            return true;
        }
        const postService = inject(PostService);
        const router = inject(Router);
        return postService.getPost(route.params.postId).then((post) => {
          const path = route.url.map((segment) => segment.path);
          path.splice(2, 0, post.premiumMediaType);
          console.log(route, post);
          router.navigate(path);
          return false;
        });
      },
    ],
    resolve: {
      createType: (route: ActivatedRouteSnapshot) => {
        switch (route.params.postIdOrType) {
          case CreateType.Livestream:
          case CreateType.Video:
          case CreateType.Audio:
          case CreateType.Article:
            return route.params.postIdOrType;
        }
        return null;
      },
    },
  },

  {
    path: `${pathRootV1}purchase-history`,
    loadChildren: () =>
      import('./frontend/manage-purchase-history/manage-purchase-history.module').then(
        (m) => m.ManagePurchaseHistoryModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: `${pathRootV1}earnings`,
    loadChildren: () =>
      import('./frontend/manage-earnings/manage-earnings.module').then(
        (m) => m.ManageEarningsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: `${pathRootV1}transfer`,
    loadChildren: () =>
      import('./frontend/manage-transfer/manage-transfer.module').then(
        (m) => m.ManageTransferModule
      ),
    canActivate: [ManageTransfersGuard],
  },
  {
    path: `${pathRootV1}channel-posts/:chanId`,
    loadChildren: () =>
      import('./frontend/manage-channel-posts/manage-channel-posts.module').then(
        (m) => m.ManageChannelPostsModule
      ),
    canActivate: [LoginGuard, ManageChannelGuard],
  },
  {
    path: `${pathRootV1}channel-posts`,
    loadChildren: () =>
      import('./frontend/manage-channel-posts/manage-channel-posts.module').then(
        (m) => m.ManageChannelPostsModule
      ),
    canActivate: [LoginGuard],
  },
];
