import * as Debug from 'debug';
const debug = Debug('shared:GeographicService');

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

export interface UserGeoData {
  country: string;
  ip?: string;
  countryData?: {
    name: {
      common: string;
      official: string;
    };
  };
}
@Injectable()
export class GeographicService {
  userGeoData: Observable<UserGeoData>;

  constructor(private http: HttpClient) {
    debug('GeographicService constructor');

    const url = '/api/geographic/current/location';
    this.userGeoData = this.http.get<UserGeoData>(url).pipe(startWith({ country: '' }));
  }

  getCountries(): Observable<any> {
    return this.http.get<any>('/api/geographic/countries/dict', {});
  }

  getStates(country: string): Observable<any> {
    return this.http.get<any>(`/api/geographic/country/${country}/states`, {});
  }

  public getCurrentLocation(): Promise<Response> {
    return this.http.get<any>('/api/geographic/current/location', {})
    .toPromise()
    .catch(err => {
      throw err;
    });
  }
}
