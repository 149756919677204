import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'urlifyName'
})
export class UrlifyNamePipe implements PipeTransform {
  transform(routeParams: string[], name?: string) {
    if (!name) {
      return routeParams;
    }
    let str = name.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to   = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length ; i < l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    routeParams.push(str);
    return routeParams;
  }
}
