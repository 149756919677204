import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './services/user_service';
import { LoginService } from './services/login_service';
import { LocationService } from './services/location_service';
import { CacheService } from './services/cache_service';
import { ContactService } from './services/contact_service';
import { PostService } from './services/post_service';
import { ChannelService } from './services/channel_service';
import { PlaylistService } from './services/playlist_service';
import { PlayQueueService } from './services/playqueue_service';
import { ClipboardService } from './services/clipboard_service';
import { NotificationsService } from './services/notifications_service';
import { PaymentsService } from './services/payments_service';
import { GeographicService } from './services/geographic_service';
import { ClassificationCategoryService } from './services/classification-category_service';
import { CategoriesService } from './services/categories_service';
import { PurchaseService } from './services/purchase_service';
import { TrackingService } from './services/tracking_service';
import { LoggingService } from './services/logging_service';
import { MediaService } from './services/media_service';
import { SelectedCategoryService } from './services/type-classification_service';
import { ChannelExistsGuard } from './guards/channel-exists.guard';
import { NotLoggedInGuard } from './guards/not-login.guard';
import { LoginGuard } from './guards/login.guard';
import { ManagePostGuard } from './guards/manage-post.guard';
import { ManageChannelGuard } from './guards/manage-channel.guard';
import { ManageTransfersGuard } from './guards/manage-earnings.guard';
import { MetaService } from './services/meta_service';
import { AnalyticsService } from './services/analytics_service';
import { LivestreamService } from './services/livestream.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [
    UserService,
    LoginService,
    LocationService,
    CacheService,
    PostService,
    ChannelService,
    PlaylistService,
    PlayQueueService,
    NotificationsService,
    PaymentsService,
    ClassificationCategoryService,
    CategoriesService,
    PurchaseService,
    TrackingService,
    GeographicService,
    ContactService,
    ClipboardService,
    LoggingService,
    MediaService,
    SelectedCategoryService,
    NotLoggedInGuard,
    LoginGuard,
    ChannelExistsGuard,
    ManagePostGuard,
    ManageTransfersGuard,
    ManageChannelGuard,
    MetaService,
    AnalyticsService,
    LivestreamService,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
