import * as Debug from 'debug';
const debug = Debug('shared:ChannelExistsGuard');

import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { ChannelService } from '@shared/services/channel_service';

@Injectable()
export class ChannelExistsGuard {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private channelService: ChannelService
  ) {}

  canActivateChild(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const channelId = routeSnapshot.params['channelId'];
    debug('ChannelExistsGuard channelId', channelId);

    if (channelId) {
      return new Observable<boolean>((observer) => {
        this.channelService.getChannelInfo(channelId).subscribe((c) => {
          if (c && c.id) {
            if (c.id === '-1') {
              // default channel not found ID
              console.log('ChannelExistsGuard channel not found', channelId);
              this.router.navigate(['/']);
              observer.next(false);
              return;
            }
            // ok
            debug('ChannelExistsGuard OK');
            observer.next(true);
          } else {
            console.log('ChannelExistsGuard channel does not exist', channelId);
            this.router.navigate(['/']);
            observer.next(false);
          }
        });
      });
    } else {
      // TODO we cn possibly check short URL here.

      return true;
    }
  }
}
