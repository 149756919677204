<div class="current-active" *ngIf="selectedPostPlaying" 
  [class.expired]="!showingPreview && selectedPostPlaying.purchased === false">
  <postd-post-card [post]="selectedPostPlaying" [preview]="selectedPostQueueItem.preview" class="audio-info"
    *ngIf="selectedPostQueueItem && getPlayingType(selectedPostPlaying, selectedPostQueueItem.preview) === 'audio'"></postd-post-card>
  <postd-media 
    class="type-{{getPlayingType(selectedPostPlaying, selectedPostQueueItem.preview)}}"
    [hidden]="!selectedPostPlaying || selectedPostPlaying.premiumMediaType === 'text'"
    [autoplay]="true"
    [playerSize]="playerSize"
    [disable]="!selectedPostPlaying.purchased && !selectedPostQueueItem.preview"
    [post]="selectedPostPlaying"
    [ngClass]="{ showingPreview: showingPreview }"
    [mode]="showingPreview ? 'lead' : ''"
    (error)="videoError(error)"
    (playbackFinished)="finishPlayback();"
  >
    <div class="post-info">
      <div>
        <a class="title" [routerLink]="['/post', selectedPostPlaying.id] | urlifyName : selectedPostPlaying.indexedName" 
          title="{{selectedPostPlaying.name}}">
          <fa-icon [icon]="['fas', 'external-link']"></fa-icon>
          {{selectedPostPlaying.name}}
        </a>
        <a class="title" [routerLink]="['/channel', selectedPostPlaying.channelId] | urlifyName : selectedPostPlaying.channelInfo?.name" class="author">
          {{selectedPostPlaying.channelInfo?.name}}
        </a>
      </div>
      <div class="close-button">
        <a (click)="cancelPlayback()"><fa-icon [icon]="['fas', 'times']"></fa-icon></a>
      </div>
    </div>

    <div *ngIf="showingPreview" class="preview-buttons type-{{selectedPostPlaying.premiumMediaType}}" [ngClass]="{ 'center-window': !selectedPostQueueItem.showNextVideo }">
      <h4 class="preview-header" [ngSwitch]="selectedPostPlaying.leadMediaType" (click)="!selectedPostPlaying.purchased && purchase()">
          <span *ngSwitchCase="'video'">Watching</span>
          <span *ngSwitchCase="'audio'">Listening to a</span>
          Preview
      </h4>
      <button class="purchase-button button gtm_purchase-button" (click)="purchase()" *ngIf="selectedPostPlaying.purchased === false">
        Purchase for {{selectedPostPlaying.price/100 | currency:'USD':'symbol' }}
      </button>
      <span 
        *ngIf="loggedIn === false && selectedPostPlaying.price <= 300"
        class="purchase-button__promo">Free for new users for a limited time!</span>
      <button class="purchased-button button" [ngSwitch]="selectedPostPlaying.premiumMediaType" (click)="playPremium()" *ngIf="selectedPostPlaying.purchased === true">
        Play full 
        <span *ngSwitchCase="'video'">video</span>
        <span *ngSwitchCase="'audio'">audio</span>
      </button>
    </div>

    <div class="video-end-menu" *ngIf="playbackFinished && selectedPostQueueItem.showNextVideo && suggestedNextPosts && suggestedNextPosts.length > 0">
      <h3 class="video-end-menu__suggestions-header">
        Watch another video
      </h3>
      <div class="video-end-menu__suggestions-list card-list">
        <div class="card" *ngFor="let post of suggestedNextPosts">
          <div class="card-content" *ngIf="post">
            <postd-post-thumbnail [post]="post" [displayOptions]="{ 'disablePreview': true }"></postd-post-thumbnail>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!showingPreview && selectedPostPlaying.purchased === false" class="expired-info">
      <p>Your purchase has expired.</p>
      <p>
        <a (click)="purchase()">Purchase this again for {{selectedPostPlaying.price/100 | currency:'USD':'symbol' }}</a>
        or skip to next video in {{countdownTime}} seconds...
      </p>
    </div>

  </postd-media>

  <div class="mobile-expired-info">
    <div class="close-button">
      <a (click)="cancelPlayback()"><fa-icon [icon]="['fas', 'times']"></fa-icon></a>
    </div>
    <p>Your purchase has expired.</p>
    <p>
      <a (click)="cancelPlayback()" [routerLink]="['/post', selectedPostPlaying.id] | urlifyName : selectedPostPlaying.indexedName">Purchase this again</a>
      or skip to next video in {{countdownTime}} seconds...
    </p>
  </div>
</div>

<div class="navigator" *ngIf="loggedIn && currQueue && currQueue.length > 0 && !hideNavigator" #navigator>
  <div class="navigator-container" (mousedown)="navMouseDown($event)" (mouseup)="dragEnd($event)">
    <!--<div class="next-post" *ngIf="!selectedPostPlaying && nextPost">
      <postd-post-card [post]="nextPost.post" [preview]="nextPost.preview" (postSelected)="postSelected(nextPost)"></postd-post-card>
    </div> Remove the "single post list" when the floating player is not active... just show the full list all the time -->
    <ul class="queue" *ngIf="true || selectedPostPlaying">
      <li *ngFor="let queueItem of currQueue; let i = index;" 
      [ngClass]="{ active: selectedPostPlaying && selectedPostPlaying.id === queueItem.postId, complete: selectedPostPlaying && queueItem.complete }">
        <postd-post-card [post]="queueItem.post" [preview]="queueItem.preview" (postSelected)="postSelected(queueItem)"></postd-post-card>
        <fa-layers *ngIf="!selectedPostPlaying || selectedPostPlaying.id !== queueItem.postId" class="remove-button" (click)="removePost(queueItem)">
          <fa-icon [icon]="['fas', 'circle']"></fa-icon>
          <fa-icon [icon]="['fas', 'times']" transform="shrink-4" style="color: black;"></fa-icon>
        </fa-layers>
      </li>
    </ul>
  </div>
</div>
