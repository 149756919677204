import { ThumbnailResolutions } from './thumbnail_resolutions';

export class Channel {
  public id: string;
  public userId: number;
  public name: string;
  public description = '';
  public featuredPosts: string[];
  public indexedName: string;
  public admins?: number[];
  public shortURLPath?: string;
  public followers: number;
  public numPosts = 0; // number of posts on channel
  public numSubs = 0; // number of subscribers
  public ratingAvg: number; // average post rating

  // Free post quota available.
  // If this number is greater than 0 you can make a free post
  // on this channel
  public freePostQuotAv: number;

  public bannerImage?: string;
  public bannerImageUrl?: string;
  public bannerImgThbs?: ThumbnailResolutions;

  public iconImage?: string;
  public iconImageUrl?: string;
  public iconImageThbs?: ThumbnailResolutions;

  public updated: number;
  public created: number;
  public latestPostId: string;
  public trashed?: number;
}
