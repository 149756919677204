

export class PurchaseTokenCacheEntry {
  public postId: string;  // set if purchased post
  public playlistId: string; // set if purchased playlist
  public purchase_token: string;
  public timestamp: number;  // when set, so we can evict stale
}


export class PurchaseTokenCacheMap {
  [key: string]: PurchaseTokenCacheEntry;
}

