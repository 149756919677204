import { Router, NavigationEnd } from '@angular/router';
import { AfterViewInit, Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import * as CookieConsent from 'vanilla-cookieconsent';

// services that are needed globally
import { AnalyticsService, ConsentPayload } from '@shared/services/analytics_service';
import { MetaService } from '@shared/services/meta_service';
import { isPlatformBrowser } from '@angular/common';

type ConsentCategories = 'necessary' | 'analytics' | 'ads';

@Component({
  selector: 'postd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./frontend/core/scss/main.scss', './app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
  // Don't remove meta service as this is the only place where it gets initialzed
  constructor(
    private analyticsService: AnalyticsService,
    router: Router,
    @Inject(PLATFORM_ID) private platformId,
    private meta: MetaService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        analyticsService.pageView();
      }
    });
  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Set timeout so the consent modal doesn't open before the angular app. Better UX with slight delay.
    setTimeout(() => {
      CookieConsent.run({
        onConsent: ({ cookie }) => {
          const { categories } = cookie;

          const consent = updateConsent(categories);
          this.analyticsService.updateConsent(consent);
        },

        onChange: ({ changedCategories, cookie }) => {
          const { categories } = cookie;

          const consent = updateConsent(categories);
          this.analyticsService.updateConsent(consent);
        },

        categories: {
          necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
          },
          analytics: {
            autoClear: {
              cookies: [
                {
                  name: /^_ga/, // regex: match all cookies starting with '_ga'
                },
                {
                  name: '_gid', // string: exact cookie name
                },
              ],
            },

            // services: {
            //   ga: {
            //     label: 'Google Analytics',
            //     onAccept: () => {},
            //     onReject: () => {},
            //   },
            // },
          },
          ads: {},
        },

        language: {
          default: 'en',
          translations: {
            en: {
              consentModal: {
                title: 'Cookie Consent',
                description:
                  'By clicking “Allow All”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing',
                acceptAllBtn: 'Allow all',
                acceptNecessaryBtn: 'Reject all',
                showPreferencesBtn: 'Manage Individual preferences',
                footer: `
                    <a href="/info/privacy-policy" target="_blank">Privacy Policy</a>
                `,
              },
              preferencesModal: {
                title: 'Manage cookie preferences',
                acceptAllBtn: 'Allow all',
                acceptNecessaryBtn: 'Reject all',
                savePreferencesBtn: 'Allow current selection',
                closeIconLabel: 'Close modal',
                serviceCounterLabel: 'Service|Services',
                sections: [
                  {
                    title: 'Your Privacy Choices',
                    description:
                      // eslint-disable-next-line max-len
                      `We use cookies to personalize content and to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.`,
                  },
                  {
                    title: 'Strictly necessary cookies (Always Active)',
                    description:
                      'These are required first party cookies to login to the site. Without these cookies, your request cannot be properly delivered.',
                    linkedCategory: 'necessary',
                  },
                  {
                    title: 'Performance and Analytics',
                    description:
                      // eslint-disable-next-line max-len
                      'These cookies are set to provide quantitative measures of website visitors. Information collected with these cookies is used in operations to measure website or software performance. With these cookies we are able to count visits and traffic sources to improve the performance of our site.',
                    linkedCategory: 'analytics',
                    cookieTable: {
                      caption: 'Cookie table',
                      headers: {
                        name: 'Cookie',
                        domain: 'Domain',
                      },
                      body: [
                        {
                          name: '_ga',
                          domain: window.location.hostname,
                        },
                        {
                          name: '_gid',
                          domain: window.location.hostname,
                        },
                      ],
                    },
                  },
                  {
                    title: 'Marketing Cookies',
                    description:
                      // eslint-disable-next-line max-len
                      'These cookies are set by our advertising partners. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers.',
                    linkedCategory: 'ads',
                  },
                ],
              },
            },
          },
        },
      });
    }, 2000);

    function updateConsent(categories: string[]) {
      const consent: ConsentPayload = {};
      if (categories.includes('necessary')) {
        consent.functionality_storage = 'granted';
        consent.security_storage = 'granted';
      }
      if (categories.includes('ads')) {
        consent.ad_personalization = 'granted';
        consent.ad_storage = 'granted';
        consent.ad_user_data = 'granted';
      }
      if (categories.includes('analytics')) {
        consent.functionality_storage = 'granted';
        consent.analytics_storage = 'granted';
        consent.personalization_storage = 'granted';
      }

      return consent;
    }
  }
}
