import { Injectable, ElementRef } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

export interface TooltipDef {
  id: string;
  ref: ElementRef;
  title: string;
  boundingBox?: Element;
}

@Injectable()
export class TooltipService {
  components: TooltipDef[] = [];

  private componentSource = new BehaviorSubject<TooltipDef[]>([]);
  componentList = this.componentSource.asObservable();

  push(tooltipInfo: TooltipDef) {
    this.remove(tooltipInfo.id);
    this.components.push(tooltipInfo);
    this.componentSource.next(this.components);
  }

  remove(id: string) {
    const idx = this.components.findIndex((t) => t.id === id);

    if (idx >= 0) {
      this.components.splice(idx, 1);
    }
    this.componentSource.next(this.components);
  }

}
