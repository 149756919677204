import { Subject } from 'rxjs';

import { getAuthToken } from '@shared/interceptors/api.interceptor';

export interface FileItem {
  file: File;
}

export const validMimeTypes: { [key: string]: Array<string | RegExp> } = {
  video: [
    /video\/.*/,
    '.mkv',
    '.avi',
    '.ogg',
    '.ogv',
    '.ogm',
    '.mp4',
    '.mov',
    '.mpg',
    '.mpeg',
    '.webm',
    '.wmv',
    'application/x-mpegURL',
  ],
  audio: [
    /audio\/.*/,
    '.wav',
    '.aif',
    '.aiff',
    '.cda',
    '.mp3',
    '.ogg',
    '.wma',
    '.aac',
    '.flac',
    '.mp4',
  ],
};

export interface FileUploadOptions {
  maxSize?: number;
  disableMultipart?: boolean;
  method?: 'POST' | 'PUT' | 'PATCH';
}

export class FileUploadHandler {
  /** Returns progress as a percentage from 0-100 */
  onProgress = new Subject<{ fileItem: FileItem; progress: number }>();
  onError = new Subject<{ fileItem: FileItem; response: unknown }>();
  onSuccess = new Subject<{ fileItem: FileItem; response: unknown }>();
  onErrorOrSuccess = new Subject<{ fileItem: FileItem; response: unknown }>();
  onComplete = new Subject<{ fileItem: FileItem; response: unknown }>();

  readonly options: Required<FileUploadOptions>;

  private queue: FileItem[] = [];
  private limitMimeTypes?: Array<string | RegExp> = undefined;

  constructor(options?: FileUploadOptions) {
    this.options = {
      maxSize: -1,
      disableMultipart: false,
      method: 'POST',
      ...(options || {}),
    };
  }

  async uploadOne(file: File, url: string) {
    console.log('request to upload', file, url);
    const fileItem = { file };

    const xhr = new XMLHttpRequest();
    const res = new Promise<{ fileItem: FileItem; response: any }>((resolve, reject) => {
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progressPct = Math.round((event.loaded / event.total) * 100);
          this.onProgress.next({ fileItem, progress: progressPct });

          console.log('upload progress:', progressPct);
        }
      });
      xhr.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progressPct = Math.round((event.loaded / event.total) * 100);
          this.onProgress.next({ fileItem, progress: progressPct });

          console.log('download progress:', progressPct);
        }
      });
      xhr.addEventListener('abort', () => {
        this.onError.next({ fileItem, response: this.parseResponse(xhr.response) });
      });
      xhr.addEventListener('error', () => {
        this.onError.next({ fileItem, response: this.parseResponse(xhr.response) });
      });
      xhr.addEventListener('loadend', () => {
        const success = xhr.readyState === 4 && xhr.status === 200;
        if (success) {
          this.onSuccess.next({ fileItem, response: this.parseResponse(xhr.response) });
        } else {
          this.onError.next({ fileItem, response: this.parseResponse(xhr.response) });
        }
        this.onErrorOrSuccess.next({ fileItem, response: this.parseResponse(xhr.response) });
        this.onComplete.next({ fileItem, response: this.parseResponse(xhr.response) });

        if (success) {
          resolve({ fileItem, response: this.parseResponse(xhr.response) });
        } else {
          reject(this.parseResponse(xhr.response));
        }
      });
      xhr.open(this.options.method, url, true);
      xhr.setRequestHeader('Postd-Auth', getAuthToken());

      if (this.options.disableMultipart) {
        xhr.send(file);
      } else {
        const formData = new FormData();
        formData.append('file', file);
        xhr.send(formData);
      }
    });

    return res;
    // const formData = new FormData();
    // formData.append('file', file);

    // try {
    //   this.queue = [{ file }];
    //   const response = await fetch(url, {
    //     method: this.options.method, // or 'PUT'
    //     headers: {
    //       'Postd-Auth': getAuthToken(),
    //     },
    //     body: file, // formData,
    //   });
    //   const result = await response.json();
    //   this.queue = [];
    //   return result;
    // } catch (e) {
    //   this.queue = [];
    //   return Promise.reject(e);
    // }
  }

  clearQueue() {
    // this.uploader.clearQueue();
  }

  hasItems() {
    return this.queue.length > 0;
  }

  setAllowedFileCategoryType(type: string | null) {
    if (validMimeTypes[type]) {
      this.setAllowedFileType(validMimeTypes[type]);
    } else {
      this.limitMimeTypes = undefined;
    }
  }

  setAllowedFileType(types: Array<string | RegExp> | null) {
    if (types) {
      this.limitMimeTypes = types;
    } else {
      this.limitMimeTypes = undefined;
    }
  }

  fileIsAllowed(file: File): boolean {
    if (!this.limitMimeTypes) {
      return true;
    }

    for (const test of this.limitMimeTypes) {
      if (typeof test === 'string') {
        const testTrim = test.trim();
        if (testTrim.startsWith('.')) {
          const testRegex = new RegExp(`\\${testTrim}$`);
          if (testRegex.test(file.name.toLowerCase())) {
            return true;
          }
        } else {
          if (file.type === testTrim) {
            return true;
          }
        }
      } else {
        if (test.test(file.type)) {
          return true;
        }
      }
    }

    return false;
  }

  parseResponse(rawResponse: any): unknown {
    try {
      return JSON.parse(rawResponse);
    } catch (_e) {
      return rawResponse;
    }
  }
}
