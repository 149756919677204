import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/core';

import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationStart,
} from '@angular/router';

import { localStorage } from '../services/storage_service';
import { DEFAULT_MAX_PRICE, PostService } from '../services/post_service';
import { Post } from '../types/post';

@Injectable()
export class PostResolver {
  constructor(private postService: PostService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Post> {
    const postShortId = route.paramMap.get('postShortId');
    const postId = route.paramMap.get('postId');
    const preserveQueryParams = { ...route.queryParams };
    const getPost = postShortId
      ? this.postService.getByShortUrl(postShortId).pipe(map((res) => res.post))
      : this.postService.getPostInfo(postId, true);
    return getPost.pipe(
      catchError((err) => {
        console.error('could not get post ' + postId);
        if (err.error && err.error.code) {
          return from([new Post({ state: err.error.code })]);
        } else {
          return from([new Post({ state: 'GEOFENCE_COUNTRY' })]);
        }
      }),
      map((post) => {
        if (post.shortURLPath && !postShortId) {
          this.router.navigate(['/p', post.shortURLPath], { queryParams: preserveQueryParams });
          return null;
        } else {
          return post;
        }
      })
    );
  }
}

@Injectable()
export class PostsNewestResolver {
  sessionCache: Post[];
  lastNavigationTrigger: NavigationStart['navigationTrigger'];
  constructor(
    private postService: PostService,
    @Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState,
    private router: Router
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.lastNavigationTrigger = event.navigationTrigger;
      }
    });
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const NEWEST_POSTS_KEY = makeStateKey<Post[]>(
      'newest-posts-' + (route.data?.preloadType || 'newest')
    );

    if (this.lastNavigationTrigger === 'popstate') {
      const savedPosts = localStorage.getItem('savedPosts.landing');
      if (savedPosts) {
        localStorage.removeItem('savedPosts.landing');
        return Promise.resolve(JSON.parse(savedPosts));
      }
    }

    if (this.transferState.hasKey(NEWEST_POSTS_KEY)) {
      const newestPosts = this.transferState.get<Post[]>(NEWEST_POSTS_KEY, null).map((postData) => {
        if (postData instanceof Post) {
          return postData;
        } else {
          return new Post(postData);
        }
      });
      this.transferState.remove(NEWEST_POSTS_KEY);
      this.sessionCache = newestPosts;
      return Promise.resolve(newestPosts);
    } else if (this.sessionCache) {
      return Promise.resolve(this.sessionCache);
    } else {
      return (
        route.data.preloadType === 'feed'
          ? this.postService.getFeedPosts({ maxPrice: DEFAULT_MAX_PRICE })
          : this.postService.getNewestPosts({ maxPrice: DEFAULT_MAX_PRICE })
      ).then(
        (posts) => {
          console.log('done loading newest', posts.length);
          if (isPlatformServer(this.platformId)) {
            const transferPosts = JSON.parse(JSON.stringify(posts));
            for (let i = 0; i < transferPosts.length; i++) {
              transferPosts[i].name = transferPosts[i]._name;
            }
            this.transferState.set(NEWEST_POSTS_KEY, transferPosts);
          }
          this.sessionCache = posts;
          return posts;
        },
        (err) => {
          console.error('could not get newest posts');
          return [];
        }
      );
    }
  }
}

@Injectable()
export class PostsFeaturedResolver {
  constructor(private postService: PostService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.postService.getFeaturedPosts();
  }
}
