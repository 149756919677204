import { LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { NoPreloading, Router, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { ApiTestInterceptor } from '@shared/interceptors/api-e2e.interceptor';
import { PerplayErrorHandler } from '@shared/services/error-handler_service';
import { LoggingService } from '@shared/services/logging_service';
import { sessionStorage } from '@shared/services/storage_service';
import { AppComponent } from './app.component';
import { browserRoutes } from './app.routes';
import { AppCommonModule } from './common/app.common.module';

import * as vex from 'vex-js';

// Disabling this as it breaks perfect scrollbar
// import 'default-passive-events';

@NgModule({
  imports: [
    AppCommonModule,
    RouterModule.forRoot(browserRoutes, {
      preloadingStrategy: NoPreloading,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    RouterModule.forChild([{ path: '**', redirectTo: '' }]),
  ],
  providers: [
    { provide: APP_ID, useValue: 'perplay-ui-univ' },
    {
      provide: 'sessionStorage',
      useFactory: getSessionStorage,
    },
    {
      provide: 'ORIGIN_URL',
      useFactory: getOriginUrl,
    },
    {
      provide: 'vex',
      useFactory: getVexInstance,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTestInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: PerplayErrorHandler,
      deps: [LocationStrategy, LoggingService],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getOriginUrl() {
  return location.origin;
}

export function getSessionStorage() {
  return sessionStorage;
}

export function getVexInstance() {
  return vex;
}
