import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Post } from '@shared/types/post';
import { ImageSetDirective } from './img-set.directive';

interface BreakpointMap {
  [key: number]: number;
}

@Directive({
  selector: '[postdThumbnailImage]',
})
export class ThumbnailImageDirective extends ImageSetDirective implements OnInit {
  @Input() targetWidth: number | BreakpointMap = 300;
  @Input() postdThumbnailImage: Post;
  @Input() set deferLoading(value: boolean) {
    if (this._deferLoading && !value) {
      this.loadImages();
    }
    this._deferLoading = value;
  }
  get deferLoading(): boolean {
    return this._deferLoading;
  }

  protected _deferLoading = false;

  constructor(protected el: ElementRef, @Inject(PLATFORM_ID) protected platformId: Object) {
    super(el, platformId);
  }

  ngOnInit() {
    if (!this.postdThumbnailImage) {
      console.warn(
        'post set not defined on init for image directive. post set must be set on init so srcset is set on page load.'
      );
      return;
    }

    const post = this.postdThumbnailImage;
    this.postdImgSet = post.thumbnails;
    super.ngOnInit();
  }

  loadImages() {
    const post = this.postdThumbnailImage;
    const defaultImage = super.loadImages();

    const element = this.el.nativeElement;
    if (!defaultImage && element instanceof HTMLImageElement) {
      let thumbnailUrl: string;

      if (post.thumbnailImage) {
        thumbnailUrl = post.thumbnailImage;
      } else if (post.thumbnails) {
        const maxResolution = Object.keys(post.thumbnails).reduce(
          (a, b) => Math.max(a, parseInt(b, 10)),
          0
        );

        thumbnailUrl = post.thumbnails[maxResolution];
      } else {
        if (post.channelInfo && post.channelInfo.iconImageUrl) {
          thumbnailUrl = post.channelInfo.iconImageUrl;
        } else {
          thumbnailUrl = '';
        }
      }
      element.src = thumbnailUrl;
      return thumbnailUrl;
    }

    return defaultImage;
  }
}
