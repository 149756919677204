import * as Debug from 'debug';
const debug = Debug('shared:TransactExceptionHandler');

import { ErrorHandler, Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class LoggingService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject('ORIGIN_URL') protected originUrl: string
  ) {}

  sendError(msg: string, url: string, lineNumber?: number, stack?: string, additionalInfo?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.sendBrowserError(msg, url, lineNumber, stack, additionalInfo);
    } else {
      const errData = {
        msg,
        url,
        stack: stack || '',
      };
      console.error('sendError:', JSON.stringify(errData, null, 2));
    }
  }

  sendBrowserError(
    msg: string,
    url: string,
    lineNumber?: number,
    stack?: string,
    additionalInfo?: any
  ) {
    debug('adding document location:');

    // Have to use XMLHttpRequest to prevent circular dependency
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/util/error/log', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    debug('adding err:', msg);

    if (additionalInfo && navigator) {
      additionalInfo.navigator = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        deviceMemory: navigator['deviceMemory'],
        cookieEnabled: navigator.cookieEnabled,
      };
      if (navigator['connection']) {
        const connection = navigator['connection'] as any;
        additionalInfo.navigator.connection = {
          type: connection.type,
          downlink: connection.downlink,
        };
      }
    }

    const requestBody = new FormData();
    requestBody.append('msg', msg);
    requestBody.append('url', this.originUrl + url);
    requestBody.append('line_number', (lineNumber || -1).toString());
    requestBody.append('stack', stack || '');
    requestBody.append('error', JSON.stringify(additionalInfo));
    xhr.send(requestBody);
  }
}
