import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Post } from '@shared/types/post';

import { PostService } from '@shared/services/post_service';

import * as _ from 'lodash';

/*
interface DisplayOptions {
}
*/

@Component({
  selector: 'postd-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements OnInit, OnChanges {
  @Input() post: Post;
  @Input() preview: boolean;
  @Output() postSelected = new EventEmitter();
  @Output() removePost = new EventEmitter();

  directLink: boolean;
  removable: boolean;
  thumbnailUrl: string;

  /*
  @Input() set displayOptions(options: any) {
    this.selectedDisplayOptions = _.defaults(<DisplayOptions> options, this.defaultDisplayOptions);
  }
  selectedDisplayOptions: DisplayOptions;

  defaultDisplayOptions: DisplayOptions = {};
  */

  constructor(
    private postService: PostService
  ) {}

  ngOnInit() {
    this.directLink = this.postSelected.observers.length === 0;
    this.removable = this.removePost.observers.length > 0;
  }

  ngOnChanges() {
    this.thumbnailUrl = this.postService.getPostThumbnail(this.post, 150);
  }

  gotoPost() {
    this.postSelected.emit({ post: this.post, preview: this.preview });
  }

  triggerRemovePost() {
    this.removePost.emit({ post: this.post });
  }
}
