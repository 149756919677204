import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../common/common.module';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports:      [ PostdCommonModule ],
  declarations: [ TooltipComponent, TooltipDirective ],
  exports:      [ TooltipComponent, TooltipDirective ],
  providers:    []
})
export class TooltipModule { }
