import * as Debug from 'debug';
const debug = Debug('shared:PlaylistService');

enum StorageType {
    'Session',
    'Local'
}

class StorageService {
    // If the user has disabled cookies, the set local storage calls fail and stop code execution, breaking a lot of things.
    // Never use sessionStorage or LocalStorage directly, use them through this utility.
    storage: Storage | null;
    transientStore: Record<string, string>;

    constructor(type: StorageType) {
        try {
            if (type === StorageType.Local) {
                this.storage = window.localStorage;
            } else {
                this.storage = window.sessionStorage;
            }
            // Have to test setting and removing an item to ensure we have full permissions to simplify the later code
            this.storage.setItem('capability', 'test');
            this.storage.removeItem('capability');
        } catch (e) {
            this.storage = null;
            this.transientStore = {};
            console.warn('Storage API not available, falling back to transient storage');
        }
    }

    get length() {
        if (this.storage) {
            return this.storage.length;
        } else {
            return Object.keys(this.transientStore).length;
        }
    }

    setItem(key: string, value: string) {
        if (this.storage) {
          try {
            this.storage.setItem(key, value);
          } catch (error) {
            console.error('error setting cache', error);
            this.clear();
          }

        } else {
            this.transientStore[key] = value;
        }
    }

    getItem(key: string) {
        if (this.storage) {
            return this.storage.getItem(key);
        } else {
            return this.transientStore[key];
        }
    }

    removeItem(key: string) {
        if (this.storage) {
            this.storage.removeItem(key);
        } else {
            delete this.transientStore[key];
        }
    }

    clear() {
        if (this.storage) {
          try {
            this.storage.clear();
          } catch (error) {
            console.error('error clear cache', error);
          }
        } else {
            this.transientStore = {};
        }
    }
}

export const localStorage = new StorageService(StorageType.Local);
export const sessionStorage = new StorageService(StorageType.Session);
