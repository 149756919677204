import { Pipe, PipeTransform } from '@angular/core';
import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';

@Pipe({
    name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  transform(date?: Date | number) {
    if (typeof date === 'undefined' || !date) {
      return '';
    }

    const now = new Date();
    if (differenceInDays(now, date) > 2) { // If 2 or more days ago, dispaly date
      return (new Date(date)).toLocaleDateString('default', { year: 'numeric', month: 'long', day: 'numeric' });
    } else {
      let result = formatDistance(date, now);
      if ((new Date(date)).valueOf() < now.valueOf()) {
        result += ' ago';
      } else {
        result += ' from now';
      }
      return result;
    }
  }
}
