import * as Debug from 'debug';
const debug = Debug('perplay:ContactService');

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable()
export class ContactService {

    constructor( private http: HttpClient ) {

    }

    sendMessage( message: any): Promise<Response> {
      const url = '/api/util/support/contact';
      return this.http.post<any>(url, message).toPromise();
    }

    private extractData(res: Response) {
        if (res.status !== 200) {
          throw new Error('Bad response status: ' + res.status);
        }
        const body = res.json();
        if (body) {
          // TODO see about caching user data
          return body;
        }

        return {};
      }
}
