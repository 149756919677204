import * as Debug from 'debug';
const debug = Debug('shared:CategoriesService');

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { mapping } from './../pipes/cat-cat-mapping';

import { ClassificationCategoryService } from './classification-category_service';

import * as _ from 'lodash';

@Injectable()
export class CategoriesService {

    private categories = new BehaviorSubject<string[]>(undefined);
    categories$ = this.categories.asObservable();

    private categorySubject = new BehaviorSubject<string>('all');
    category: string;

    constructor(
        private classificationCategoryService: ClassificationCategoryService
    ) {
        const videoCategories = _.map(this.classificationCategoryService.getBy({ type: 'video' }), 'category');
        const uniqueCategories = Object.keys(videoCategories.reduce((r, v) => {
          r[v] = true;
          return r;
        }, {})).sort();
        this.categories.next(uniqueCategories);
    }

    setCategories(categories: string[]) {
        this.categories.next(categories);
        debug('categories set', categories );
    }

    getCategories() {
        return this.categories;
    }

    categoryToUrl(category: string): string {
        return mapping[category] ? mapping[category] : category;
    }

    setCategory(category: string) {
      this.category = category;
      this.categorySubject.next(category);
    }

    getCategoryChanges() {
      return this.categorySubject.asObservable();
    }

    getCategory() {
      return this.category;
    }

}
