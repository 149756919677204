import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { ApiTestInterceptor } from '@shared/interceptors/api-e2e.interceptor';
import { ApiInterceptor } from '@shared/interceptors/api.interceptor';
import { ChannelResolver } from '@shared/resolvers/channel.resolver';
import {
  PostResolver,
  PostsFeaturedResolver,
  PostsNewestResolver,
} from '@shared/resolvers/post.resolver';
import { SharedModule } from '@shared/shared.module';
import { QuillModule } from 'ngx-quill';

import { AppComponent } from '../app.component';
import { GlobalPlayerModule } from '../frontend/core/player/player.module';
import { ScrollIntoViewModule } from '../frontend/core/scroll-into-view/scroll-into-view.module';
import { TooltipContainerModule } from '../frontend/core/tooltips/tooltip-container.module';
import { TooltipService } from '../frontend/core/tooltips/tooltip.service';
import { PostdCommonModule } from './common.module';
import { routes } from './common.routes';

@NgModule({
  declarations: [AppComponent],
  exports: [PostdCommonModule],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
    SharedModule.forRoot(),
    PostdCommonModule.forRoot(),
    HttpClientModule,
    TooltipContainerModule,
    GlobalPlayerModule,
    QuillModule.forRoot(),
    ScrollIntoViewModule,
  ],
  providers: [
    PostsNewestResolver,
    PostsFeaturedResolver,
    PostResolver,
    ChannelResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      /* Multi is important or you will delete all the other interceptors */
      multi: true,
    },
    TooltipService,
    provideClientHydration(),
  ],
})
export class AppCommonModule {}
