import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../common/common.module';
import { TooltipModule } from './tooltip.module';

import { TooltipContainerComponent } from './tooltip-container.component';

@NgModule({
  declarations: [ TooltipContainerComponent ],
  imports: [
    PostdCommonModule,
    TooltipModule
  ],
  exports: [ TooltipContainerComponent ]
})

export class TooltipContainerModule {}
