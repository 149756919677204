import { meta } from './meta';
const buildInfo = require('./buildInfo');
const proxy = require('../../proxies/proxy.conf.json');

export const environment = {
  apiServer: proxy['/api'].target,
  version: buildInfo.version,
  buildDate: buildInfo.buildDate,
  enableServiceWorker: false,
  name: 'staging',
  production: true,
  transactGroupId: 5203720654553088,
  transactBaseUrl: 'https://test.xsact.com',
  postdBaseUrl: 'https://postd.xsact.com',
  test: false,
  meta,
};
