import { Component, Inject, AfterViewInit, ViewChild, Input, HostListener, ElementRef, Renderer2, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'postd-tooltip-content',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AfterViewInit {
  position: {
    x: number;
    y: number;
    arrowPosition: number;
  };

  @Input() tooltipTitle: string;
  @Input() tooltipRef: ElementRef;
  @Input() tooltipBoundingBox: Element;
  @ViewChild('tooltip', { read: ElementRef }) tooltip: ElementRef;

  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) {
    this.position = { x: 0, y: 0, arrowPosition: 0 };
  }

  ngAfterViewInit(): void {
    this.updatePosition();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.updatePosition();
  }

  updatePosition() {
    if (this.tooltipRef) {
      const boundingBox: DOMRect = this.tooltipBoundingBox ? this.tooltipBoundingBox.getBoundingClientRect() : null;
      const targetPosition: DOMRect = this.tooltipRef.nativeElement.getBoundingClientRect();
      this.position = {
        x: targetPosition.left + this.tooltipRef.nativeElement.offsetWidth - this.tooltip.nativeElement.offsetWidth,
        y: targetPosition.top,
        arrowPosition: 0
      };
      if (boundingBox && boundingBox.left > this.position.x) {
        this.position.arrowPosition = boundingBox.left - this.position.x;
        this.position.x = boundingBox.left;
      }
      if (isPlatformBrowser(this.platformId)) {
        this.position.x += window.scrollX;
        this.position.y += window.scrollY;
      }
      this.renderer.setStyle(this.tooltip.nativeElement, 'top', this.position.y + 'px');
      this.renderer.setStyle(this.tooltip.nativeElement, 'left', this.position.x + 'px');
      this.renderer.setStyle(this.tooltip.nativeElement.querySelector('.arrow'), 'right', this.position.arrowPosition + 'px');
    }
  }

}
