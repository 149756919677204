import * as Debug from 'debug';
const debug = Debug('perplay:MediaService');

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Post } from '../types/post';
import { PostService } from './post_service';

import { getAuthToken } from '@shared/interceptors/api.interceptor';
import { firstValueFrom, lastValueFrom, takeUntil } from 'rxjs';
import { FileUploadHandler } from '../../app/frontend/core/forms/file-upload/file-upload.handler';

export enum THUMBNAIL_ERROR {
  WRONG_FILETYPE,
  UPLOAD_FAILED,
  INVALID_IMAGE,
  UNKNOWN,
}

@Injectable()
export class MediaService {
  mediaUploader: FileUploadHandler;
  thumbnailUploader: FileUploadHandler;
  previewUploader: FileUploadHandler;

  progressColor = '';
  uploadingPostMedia = false;
  uploadingText = '';
  uploadingProgress = 0;
  showProgress = false;
  postMedia: any;
  embeddedMedia: any;
  embeddedMediaItem: any;
  onCompletion: Function;

  currentPost: any;
  currentSection: string;
  currentType: string;

  keyExtractPattern = /http.*\/\/.*?\/(.*)\?.*/;

  constructor(private postService: PostService, private http: HttpClient) {
    this.mediaUploader = new FileUploadHandler({
      disableMultipart: true,
      method: 'PUT',
    });

    this.thumbnailUploader = new FileUploadHandler({
      maxSize: 3145728, // 3MiB
      disableMultipart: false,
      method: 'POST',
    });

    this.previewUploader = new FileUploadHandler({
      disableMultipart: true,
      method: 'PUT',
    });
    /*
      List of allowedFileTypes values:

      application
      image
      video
      audio
      pdf
      compress
      doc
      xls
      ppt
    */
  }

  getMediaUploadUrl(
    postId: string,
    fileName: string,
    fileType: string,
    contentSection: string
  ): Promise<any> {
    const url =
      '/api/post/' +
      postId +
      '/' +
      contentSection +
      '/media/upload_url?filename=' +
      encodeURIComponent(fileName) +
      '&ctype=' +
      encodeURIComponent(fileType);

    return this.http.get<any>(url, {}).toPromise();
  }

  getDownloadUrl(key: string): string {
    // GET /post/{Post_id}/media/download?key=123
    if (!key) {
      return '';
    }
    return (
      window.location.origin +
      '/api/post/' +
      this.getKeyParts(key).postId +
      '/media/download?key=' +
      key
    );
  }

  setThumbnail(thumb: any, postId: string): Promise<any> {
    // set thumbnail to auto generated item
    debug('setThumbnail', thumb);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const thumbUrl = thumb.download;
    let url: string = '/api/post/' + postId + '/thumbnail/image';
    url += '?url=' + thumbUrl;
    return lastValueFrom(
      this.http.post<any>(url, {
        headers,
      })
    );
  }

  uploadThumbnail(image: File, postId: string): Promise<Post> {
    this.thumbnailUploader.uploadOne(image, '/api/post/' + postId + '/thumbnail/image');
    return firstValueFrom(this.thumbnailUploader.onErrorOrSuccess)
      .then((result) => {
        this.thumbnailUploader.clearQueue();
        const post = result.response as Post;
        if (post.status !== 400) {
          return post;
        } else {
          debug('file error', post);
          return Promise.reject(THUMBNAIL_ERROR.WRONG_FILETYPE);
        }
      })
      .catch((result) => {
        this.thumbnailUploader.clearQueue();
        const responseObj = result.response as any;
        if (responseObj.code) {
          switch (responseObj.code) {
            case 'INVALID_EMPTY_IMAGE':
              return Promise.reject(THUMBNAIL_ERROR.INVALID_IMAGE);
            default:
              return Promise.reject(THUMBNAIL_ERROR.UPLOAD_FAILED);
          }
        } else {
          return Promise.reject(THUMBNAIL_ERROR.UNKNOWN);
        }
      });
  }

  private getKeyParts(key: string): any {
    if (!key) {
      return {};
    }
    const keyParts = key.split('/');
    const parts = { postId: '', fileName: '', type: '', section: '' };
    if (keyParts.length < 3) {
      console.log('in getKeyParts, invalid key: ' + key);
    } else {
      parts.postId = keyParts[0];
      parts.section = keyParts[1];
      const nameParts = keyParts[2].split('.');
      parts.type = nameParts[nameParts.length - 1];
      parts.fileName = this.stripName(keyParts[2].replace('.' + parts.type, ''));
    }
    return parts;
  }

  private stripName(nm: string) {
    const stripped = nm.replace(/[^a-z0-9]/gi, '');
    return stripped;
  }

  private extractData(res: Response) {
    if (res.status !== 200) {
      throw new Error('Bad response status: ' + res.status);
    }
    // debug('extractData response', res);
    const body = res.json();
    if (body) {
      // TODO see about caching user data
      return body;
    }

    return {};
  }

  private setCurrentPost(post: any) {
    this.currentPost = post;
    debug('setCurrentPost', post);
  }

  private fileNamesMatch(test: string, uploadedName: string): Boolean {
    let matches = true;
    if (test.length !== uploadedName.length) {
      return false;
    }
    for (let i = 0; i < test.length; i++) {
      if (' _-'.indexOf(test[i]) < 0) {
        if (test[i] !== uploadedName[i]) {
          matches = false;
          break;
        }
      }
    }
    return matches;
  }
}
