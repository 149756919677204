import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../../common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TooltipModule } from '../../../../frontend/core/tooltips/tooltip.module';
import { MediaComponent } from '../../../../frontend/core/posts/media/media.component';

@NgModule({
  declarations: [
    MediaComponent
  ],
  imports: [
    PostdCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  exports: [ MediaComponent ]
})

export class MediaModule {}
