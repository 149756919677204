import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class LivestreamService {
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {}

  activeStream?: {
    ws: WebSocket;
    postId: string;
    mediaRecorder: MediaRecorder;
  };

  dataHandler?: (event: any) => void;

  tryUntilAvailable(url: string, maxTimes: number): Observable<void> {
    if (maxTimes === 0) {
      return throwError(undefined);
    }

    return this.http.get(url, { observe: 'response', responseType: 'text' }).pipe(
      catchError(() => of({ ok: false } as HttpResponse<any>)),
      switchMap((resp) => {
        if (resp.ok) {
          return of(null);
        } else {
          return timer(2000).pipe(switchMap(() => this.tryUntilAvailable(url, maxTimes - 1)));
        }
      })
    );
  }

  mediaDevices = new Promise((resolve, reject) => {
    if (!navigator.mediaDevices) {
      alert('media devices not supported');
      reject(new Error('media devices not supported'));
      return;
    }

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      console.log('devices', devices);
      for (const i in devices) {
        if (devices[i]) {
          const device = devices[i];
          //      console.log('device', device);

          console.log(device.kind + ': ' + device.label + ' id = ' + device.deviceId);
        }
      }

      let video_sources = [];
      let audio_sources = [];

      video_sources = devices.filter((d) => d.kind === 'videoinput');
      audio_sources = devices.filter((d) => d.kind === 'audioinput');

      const ret_data = {
        video_sources,
        audio_sources,
      };
      console.log('returning', ret_data);
      resolve(ret_data);
    });
  });

  createLivestream(postId: string, retryTimes = 0) {
    if (retryTimes > 3) {
      return throwError('Too Many retries');
    }

    return this.http.post<any>(`/api/post/${postId}/live-stream/create`, {}).pipe(
      catchError((res) => {
        if (res.error?.code === 'ALREADY_EXISTS') {
          return this.deleteLivestream(postId).pipe(
            switchMap(() => this.createLivestream(postId, retryTimes + 1))
          );
        }
        return throwError(res);
      })
    );
  }

  connectLivestreamSocket(postId: string, videoEl: HTMLMediaElement) {
    return new Observable((subscriber) => {
      const mimeTypes = this.getSupportedMimeTypes();
      console.log('Mime types', mimeTypes);
      if (mimeTypes.length < 1) {
        throw Error('No output mime types available');
      }
      const options = {
        mimeType: mimeTypes[0],
        videoBitsPerSecond: 3000000,
      };
      console.log('recording options', options);

      // ws if http or wss if https
      const protocol = window.location.protocol.replace('http', 'ws');
      const ws = new WebSocket(
        `${protocol}//${window.location.host}/api/post-stream/stream?postId=${postId}`
      );

      ws.addEventListener('open', function open() {
        console.log('websocket open');
      });

      ws.addEventListener('close', () => {
        console.log('websocket closed');
        if (this.dataHandler) {
          this.activeStream?.mediaRecorder.removeEventListener('dataavailable', this.dataHandler);
          this.dataHandler = undefined;
        }
        this.stopRecording(true);
      });

      try {
        const mediaStream = (videoEl as any).captureStream(30); // 30 frames per second
        console.log('mediaStream', mediaStream);

        this.activeStream = {
          ws,
          postId,
          mediaRecorder: new MediaRecorder(mediaStream, options),
        };
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        subscriber.error('Exception while creating MediaRecorder');
        return;
      }

      console.log(
        'Created MediaRecorder',
        this.activeStream?.mediaRecorder,
        'with options',
        options
      );
      //    recordButton.textContent = 'Stop Recording';

      //    playButton.disabled = true;
      //    downloadButton.disabled = true;
      //   codecPreferences.disabled = true;
      if (this.activeStream) {
        this.activeStream.mediaRecorder.onstop = (event) => {
          console.log('Recorder stopped: ', event);
        };
      }

      // mediaRecorder.ondataavailable = handleDataAvailable;

      // Listen for the dataavailable event on our mediaRecorder instance

      this.dataHandler = (event) => {
        console.log('dataavailable', event);
        if (ws.readyState === ws.CONNECTING) {
          console.log('Websocket still connecting....');
        }

        if (event.data && event.data.size > 0 && ws.readyState === ws.OPEN) {
          //    recordedBlobs.push(event.data);
          // const msg = {
          //   kind: 'postStream',
          //   postId: 'post',
          //   'data:': event.data,
          //   'timestamp': 123

          // };
          // const blob = new Blob([JSON.stringify(msg, null, 2)], {type : 'application/json'});
          // ws.send(blob);

          // ws.send(JSON.stringify(msg));
          ws.send(event.data);
        }

        if (!firstData) {
          firstData = true;
          subscriber.next();
          subscriber.complete();
        }
      };

      let firstData = false;
      if (this.activeStream) {
        this.activeStream.mediaRecorder.addEventListener('dataavailable', this.dataHandler);

        const timeSlice = 1000; // every 1 second fire.
        this.activeStream.mediaRecorder.start(timeSlice);
        console.log('MediaRecorder started', this.activeStream.mediaRecorder);
      }
    });
  }

  handleData() {}

  stopRecording(socketClosed: boolean) {
    if (!this.activeStream) {
      console.log('no active stream to stop');
      return;
    }

    this.activeStream.mediaRecorder.stop();
    if (socketClosed) {
      this.activeStream = undefined;
    }
  }

  deleteLivestream(postId: string) {
    if (this.activeStream && this.activeStream.postId === postId) {
      this.activeStream.ws.close();
      // Closing the socket will close the mediarecorder
      this.activeStream = undefined;
    }

    return this.http
      .delete<any>(`/api/post/${postId}/live-stream/delete`)
      .pipe(catchError(() => of()));
  }

  getSupportedMimeTypes() {
    const possibleTypes = [
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=h264,opus',
      'video/mp4;codecs=h264,aac',
    ];
    return possibleTypes.filter((mimeType) => MediaRecorder.isTypeSupported(mimeType));
  }
}
