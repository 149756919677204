/* eslint-disable */
export const mockDB = {
  "config": {
    "newPostLength": 24
  },
  "users": [
    {
      "firstName": "Test0First",
      "lastName": "Test0Last",
      "email1": "e2etest0@sharklasers.com",
      "admin": 0,
      "accountClosed": 0,
      "banned": 0,
      "notifyFollow": 1,
      "notifyMyPosts": 1,
      "notifyMyComms": 1,
      "reputation": 0,
      "updated": 1521492115955,
      "created": 1521492115951,
      "id": 5746721642512384,
      "xsactUserId": 5746721642512384,
      "seller": 0,
      "displayName": "Test0First Test0Last"
    },
    {
      "firstName": "Super",
      "lastName": "Admin",
      "email1": "admin@xsact.com",
      "admin": 1,
      "accountClosed": 0,
      "banned": 0,
      "notifyFollow": 1,
      "notifyMyPosts": 1,
      "notifyMyComms": 1,
      "reputation": 0,
      "updated": 1523227836390,
      "created": 1517599974680,
      "id": 5648161435549696,
      "xsactUserId": 5648161435549696,
      "seller": 0,
      "displayName": "Super Admin"
    },
    {
      "firstName": "pub2",
      "lastName": "publisher2",
      "email1": "publisher2@xsact.com",
      "admin": 0,
      "accountClosed": 0,
      "banned": 0,
      "notifyFollow": 1,
      "notifyMyPosts": 1,
      "notifyMyComms": 1,
      "reputation": 0,
      "updated": 1523293659869,
      "created": 1517599816349,
      "id": 6453567359549440,
      "xsactUserId": 6453567359549440,
      "seller": 1,
      "groups": [
        5046192475996160
      ],
      "funds": {
        "PROD": 16166
      },
      "logoutDate": 1523227804359,
      "displayName": "pub2 publisher2"
    }
  ],
  "notifications": [
    {
      "userId": 5746721642512384,
      "channelId": "5758048710688768_0",
      "postId": "5758048710688768_0_1519831505418",
      "id": "5746721642512384_5758048710688768_0_1519831505418",
      "notifyType": 2,
      "updated": 1519831515055,
      "created": 1519831515055
    },
    {
      "userId": 5746721642512384,
      "channelId": "5758048710688768_0",
      "postId": "5758048710688768_0_1519677092763",
      "id": "5746721642512384_5758048710688768_0_1519677092763",
      "notifyType": 2,
      "updated": 1519677424414,
      "created": 1519677424414
    },
    {
      "userId": 5746721642512384,
      "channelId": "5758048710688768_0",
      "postId": "5758048710688768_0_1519586160522",
      "id": "5746721642512384_5758048710688768_0_1519586160522",
      "notifyType": 2,
      "updated": 1519586345423,
      "created": 1519586345423
    },
    {
      "userId": 5746721642512384,
      "channelId": "5758048710688768_0",
      "postId": "5758048710688768_0_1519583972665",
      "id": "5746721642512384_5758048710688768_0_1519583972665",
      "notifyType": 2,
      "updated": 1519584088349,
      "created": 1519584088349
    }
  ],
  "channel_followers" : [
    {
      "id": "5758048710688768_0_5746721642512384",
      "channelId": "5758048710688768_0",
      "userId": 5746721642512384,
      "notify": 3,
      "updated": 1511810547765,
      "created": 1511810547765
    },
    {
      "id": "5769457217568768_0_5746721642512384",
      "channelId": "5769457217568768_0",
      "userId": 5746721642512384,
      "notify": 3,
      "updated": 1511810474360,
      "created": 1511810474360
    }
  ],
  "channels": [
    {
      "name": "Test 1 Channel",
      "description": "",
      "followers": 0,
      "numPosts": 1,
      "numSubs": 0,
      "id": "5671267858382848_2",
      "userId": 5671267858382848,
      "updated": 1516921724634,
      "created": 1516910307582,
      "indexedName": "test 1 channel",
      "featuredPosts": [],
      "ratingAvg": 0
    },
    {
      "id": "5683213900972032_0",
      "userId": 5683213900972032,
      "name": "Ray's Channel",
      "updated": 1518826059543,
      "created": 1506574338141,
      "indexedName": "ray's channel",
      "bannerImage": "assets/mock/images/channel_banner.jpg",
      "iconImage": "assets/mock/images/channel_icon.jpg",
      "followers": 2,
      "featuredPosts": [
        "5683213900972032_0_1506574584878"
      ],
      "ratingAvg": 4,
      "numPosts": 2,
      "numSubs": 0,
      "description": "test blah",
      "bannerImageUrl": "assets/mock/images/channel_banner.jpg",
      "iconImageUrl": "assets/mock/images/channel_icon.jpg"
    },
    {
      "id": "5683213900972032_1",
      "userId": 5683213900972032,
      "name": "Testing Channel 2",
      "updated": 1517640344260,
      "created": 1510452363114,
      "indexedName": "e2e channel 2",
      "featuredPosts": [
        "5683213900972032_1_1515908858787"
      ],
      "numPosts": 1,
      "ratingAvg": 5,
      "numSubs": 0,
      "description": ""
    },
    {
      "id": "5718643555958784_0",
      "userId": 5718643555958784,
      "name": "Test 2 channel",
      "updated": 1518449604790,
      "created": 1510089006704,
      "indexedName": "test 2 channel",
      "featuredPosts": [],
      "followers": 2,
      "ratingAvg": 3,
      "numPosts": 2,
      "numSubs": 0,
      "description": ""
    },
    {
      "name": "Test 3 Channel",
      "description": "",
      "followers": 0,
      "numPosts": 1,
      "numSubs": 0,
      "id": "5739111698661376_2",
      "userId": 5739111698661376,
      "updated": 1519170579087,
      "created": 1517606211163,
      "indexedName": "test 3 channel",
      "featuredPosts": [],
      "ratingAvg": 0
    },
    {
      "name": "Test 4 Channel",
      "description": "",
      "followers": 0,
      "numPosts": 1,
      "numSubs": 0,
      "id": "5745572403216384_2",
      "userId": 5745572403216384,
      "updated": 1519170579069,
      "created": 1517512122894,
      "indexedName": "test 4 channel",
      "featuredPosts": [],
      "ratingAvg": 0
    },
    {
      "id": "5758048710688768_0",
      "userId": 5758048710688768,
      "name": "TestChannel",
      "updated": 1520903390071,
      "created": 1506526975593,
      "indexedName": "testchannel",
      "followers": 5,
      "ratingAvg": 3.909090909090909,
      "numPosts": 7,
      "numSubs": 0,
      "featuredPosts": [
        "5758048710688768_0_1519583972665"
      ],
      "latestPostId": "5758048710688768_0_1519831505418",
      "description": ""
    },
    {
      "id": "5758048710688768_1",
      "userId": 5758048710688768,
      "name": "Space",
      "updated": 1519973999502,
      "created": 1506526975609,
      "indexedName": "space",
      "followers": 4,
      "ratingAvg": 4.25,
      "numPosts": 5,
      "numSubs": 0,
      "featuredPosts": [
        "5758048710688768_1_1506527057085"
      ],
      "bannerImage": "channel/5758048710688768_1/profile/channel_banner.jpg",
      "latestPostId": "5758048710688768_1_1518207745476",
      "bannerImageUrl": "assets/mock/images/channel_banner.jpg",
      "description": ""
    },
    {
      "id": "5758048710688768_2",
      "userId": 5758048710688768,
      "name": "Bensound",
      "updated": 1518941886749,
      "created": 1509562646991,
      "indexedName": "bensound",
      "featuredPosts": [
        "5758048710688768_2_1514308349007"
      ],
      "numPosts": 6,
      "followers": 2,
      "bannerImage": "channel/5758048710688768_2/profile/banner.png",
      "ratingAvg": 5,
      "numSubs": 0,
      "bannerImageUrl": "/public-images/channel/5758048710688768_2/profile/banner.png",
      "description": ""
    },
    {
      "name": "Test1234 Channel",
      "description": "",
      "followers": 0,
      "numPosts": 3,
      "numSubs": 0,
      "id": "5758048710688768_6",
      "userId": 5758048710688768,
      "updated": 1519381534377,
      "created": 1517346118459,
      "indexedName": "test1234 channel",
      "featuredPosts": [],
      "ratingAvg": 5,
      "latestPostId": "5758048710688768_6_1517346151530"
    },
    {
      "name": "NatalieTest",
      "copyright": "",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "followers": 1,
      "numPosts": 3,
      "numSubs": 0,
      "id": "5758048710688768_7",
      "userId": 5758048710688768,
      "updated": 1519973672723,
      "created": 1519740131252,
      "indexedName": "natalietest",
      "featuredPosts": [
        "5758048710688768_7_1519740845385"
      ],
      "latestPostId": "5758048710688768_7_1519740396006",
      "iconImage": "channel/5758048710688768_7/profile/icon.jpg",
      "ratingAvg": 0,
      "iconImageUrl": "/public-images/channel/5758048710688768_7/profile/icon.jpg"
    },
    {
      "id": "5765983402721280_0",
      "userId": 5765983402721280,
      "name": "Karl1 Channel",
      "updated": 1519484746463,
      "created": 1507970619512,
      "indexedName": "karl1 channel",
      "iconImage": "channel/5765983402721280_0/profile/icon.jpg",
      "bannerImage": "channel/5765983402721280_0/profile/channel_banner.jpg",
      "featuredPosts": [
        "5765983402721280_0_1519159114795"
      ],
      "followers": 3,
      "ratingAvg": 0,
      "numPosts": 15,
      "numSubs": 0,
      "latestPostId": "5765983402721280_0_1519484621428",
      "bannerImageUrl": "assets/mock/images/channel_banner.jpg",
      "iconImageUrl": "/public-images/channel/5765983402721280_0/profile/icon.jpg",
      "description": ""
    },
    {
      "id": "5769457217568768_0",
      "userId": 5769457217568768,
      "name": "Popeye Public domain",
      "updated": 1515617800576,
      "created": 1507975920931,
      "indexedName": "popeye public domain",
      "bannerImage": "channel/5769457217568768_0/profile/channel_banner.jpg",
      "iconImage": "channel/5769457217568768_0/profile/channel_icon.jpg",
      "followers": 4,
      "ratingAvg": 2,
      "numPosts": 4,
      "numSubs": 0,
      "featuredPosts": [],
      "bannerImageUrl": "assets/mock/images/channel_banner.jpg",
      "iconImageUrl": "assets/mock/images/channel_icon.jpg",
      "description": ""
    },
    {
      "id": "5769457217568768_1",
      "userId": 5769457217568768,
      "name": "Popeye Public domain",
      "updated": 1515607541574,
      "created": 1510523892570,
      "indexedName": "popeye public domain",
      "featuredPosts": [],
      "followers": 2,
      "ratingAvg": 0,
      "numPosts": 2,
      "numSubs": 0,
      "description": ""
    },
    {
      "name": "Sport",
      "description": "",
      "followers": 1,
      "numPosts": 1,
      "numSubs": 0,
      "id": "5769457217568768_3",
      "userId": 5769457217568768,
      "updated": 1513943706894,
      "created": 1513942199873,
      "indexedName": "sport",
      "featuredPosts": [],
      "ratingAvg": 3
    },
    {
      "id": "5746721642512384_0",
      "userId": 5746721642512384,
      "name": "Interesting Channel",
      "updated": 1519859707643,
      "created": 1506531334956,
      "indexedName": "interesting channel",
      "followers": 6,
      "featuredPosts": [
        "5746721642512384_0_1519408668150"
      ],
      "ratingAvg": 4.5,
      "numPosts": 16,
      "numSubs": 0,
      "latestPostId": "5746721642512384_0_1519859056374",
      "description": ""
    },
    {
      "id": "5746721642512384_1",
      "userId": 5746721642512384,
      "name": "Richard's Main Channel",
      "updated": 1520882543512,
      "created": 1506531334971,
      "indexedName": "richard's main channel",
      "featuredPosts": [],
      "followers": 1,
      "ratingAvg": 0,
      "numPosts": 5,
      "numSubs": 0,
      "latestPostId": "5746721642512384_1_1520739198673",
      "description": ""
    }
  ],
  "playlists" : [
    {
      "id" : "5746721642512384_1523648568236",
      "userId" : 5746721642512384,
      "channelId": "5746721642512384_0",
      "name" : "Test Playlist",
      "postIds" : [ "5746721642512384_0_1506702555001", "5746721642512384_0_1511457114986"],
      "created" : 1523648568236,
      "updated" : 1523648568236,
      "description": "This is a sample playlist"
    }
  ],
  "followers" : [
    {
      "id" : "5671267858382848_2_5746721642512384",
      "channelId" : "5671267858382848_2",
      "userId" : 5746721642512384,
      "notify" : 1,
      "updated" : 1523648568236,
      "created" : 1523648568236
    }
  ],
  "posts": [
    {
      "channelId": "5671267858382848_2",
      "userId": 5671267858382848,
      "price": 1,
      "name": "Test post rph",
      "tags": [
        "classification-type",
        "category-video",
        "classification-movies",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 8,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1516921724661,
      "created": 1516921459161,
      "id": "5671267858382848_2_1516921459160",
      "indexedName": "test post rph",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 908766,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 596,
          "thumbnails": [
            {
              "size": 44337,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5671267858382848_2_1516921459160/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1516921544000,
              "key": "5671267858382848_2_1516921459160/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 951462,
          "transcoded": {
            "playlists": [
              {
                "name": "5671267858382848_2_1516921459160/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5671267858382848_2_1516921459160/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS0400k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5671267858382848_2_1516921459160/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 8,
            "jobId": "1516921539138-jr0oml",
            "playlistUrl": "/api/post/5671267858382848_2_1516921459160/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
          "formatLongName": "QuickTime / MOV",
          "key": "5671267858382848_2_1516921459160/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
          "bucketName": "perplay-media",
          "height": 336,
          "duration": 7.641,
          "sequencer": "005A6A62C1EA952303",
          "download": "/api/post/5671267858382848_2_1516921459160/media/download?key=5671267858382848_2_1516921459160/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4"
        }
      ],
      "premWrdCnt": 3,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "content here ",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5671267858382848_2_1516921459160/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5683213900972032_0",
      "userId": 5683213900972032,
      "price": 2,
      "name": "Ray Test Post",
      "tags": [
        "comedy",
        "cooking"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 2,
      "likesCount": 0,
      "viewCount": 1346,
      "purchaseCount": 48,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 1,
      "rating5star": 0,
      "ratingAvg": 4,
      "updated": 1518369029322,
      "created": 1506574584879,
      "id": "5683213900972032_0_1506574584878",
      "indexedName": "ray test post",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 13670859,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "height": 720,
          "transcoded": {
            "playlists": [
              {
                "name": "5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/HLS0400k",
                  "5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/HLS1000k",
                  "5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 112,
            "jobId": "1506574610097-esk5a2",
            "playlistUrl": "/api/post/5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/playlist.m3u8"
          },
          "duration": 111.409333,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5683213900972032_0_1506574584878/premium/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4",
          "mime": "video/mp4",
          "eTag": "f0d4a8c902f6523518cc0ce8a7a37431",
          "sequencer": "0059CC810960156863",
          "thumbnails": [
            {
              "size": 42966,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1506574629000,
              "key": "5683213900972032_0_1506574584878/premium/video/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "download": "/api/post/5683213900972032_0_1506574584878/media/download?key=5683213900972032_0_1506574584878/premium/-Hdvidz.in--Elon-Musks-Video-Game-Recommendations.mp4"
        }
      ],
      "premWrdCnt": 7,
      "abuseCount": 0,
      "firstLeadWords": "Test Description",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5683213900972032_0_1506574584878/thumbnail/image/thumbnail.png",
      "firstPremWords": "Elon musk, you are so cool ",
      "minAge": 0,
      "minCostPrice": 2
    },
    {
      "channelId": "5683213900972032_0",
      "userId": 5683213900972032,
      "price": 1,
      "name": "New Post Mon Oct 30 2017 05:09:54 GMT+0000 (UTC)",
      "tags": [],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 24,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1509340195674,
      "created": 1509340194419,
      "leadContent": "",
      "premiumContent": "",
      "id": "5683213900972032_0_1509340194417",
      "indexedName": "new post mon oct 30 2017 05:09:54 gmt+0000 (utc)",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "firstLeadWords": "",
      "minAge": 0,
      "minCostPrice": 1
    },
    {
      "channelId": "5683213900972032_1",
      "userId": 5683213900972032,
      "price": 1,
      "name": "Test Post Unpublished",
      "tags": [
        "classification-music",
        "category-danceelectronic",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 1,
      "likesCount": 0,
      "viewCount": 230,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 1,
      "ratingAvg": 5,
      "updated": 1518900736052,
      "created": 1515908858794,
      "premiumContent": "",
      "id": "5683213900972032_1_1515908858787",
      "indexedName": "test post unpublished",
      "state": "PUBLIC",
      "leadMedia": [
        {
          "size": 253768,
          "formatName": "mp3",
          "bitrate": 189091,
          "transcoded": {
            "playlists": [
              {
                "name": "5683213900972032_1_1515908858787/lead/audio/03.-Fallin--Deep-preview.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5683213900972032_1_1515908858787/lead/audio/03.-Fallin--Deep-preview.mp3/HLSAudio160k",
                  "5683213900972032_1_1515908858787/lead/audio/03.-Fallin--Deep-preview.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5683213900972032_1_1515908858787/lead/audio/03.-Fallin--Deep-preview.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 11,
            "jobId": "1518900728053-warhcn",
            "playlistUrl": "/api/post/5683213900972032_1_1515908858787/lead/audio/03.-Fallin--Deep-preview.mp3/playlist.m3u8"
          },
          "duration": 10.736327,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5683213900972032_1_1515908858787/lead/03.-Fallin--Deep-preview.mp3",
          "mime": "audio/mpeg",
          "eTag": "87dfc09720dbcdb2e0f26faffe705f53",
          "sequencer": "005A8895F6850537CA",
          "download": "/api/post/5683213900972032_1_1515908858787/media/download?key=5683213900972032_1_1515908858787/lead/03.-Fallin--Deep-preview.mp3"
        }
      ],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 8728024,
          "formatName": "mp3",
          "width": 1000,
          "height": 1000,
          "bitrate": 286122,
          "transcoded": {
            "playlists": [
              {
                "name": "5683213900972032_1_1515908858787/premium/audio/03---Nhato---Fallin--Deep.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5683213900972032_1_1515908858787/premium/audio/03---Nhato---Fallin--Deep.mp3/HLSAudio160k",
                  "5683213900972032_1_1515908858787/premium/audio/03---Nhato---Fallin--Deep.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5683213900972032_1_1515908858787/premium/audio/03---Nhato---Fallin--Deep.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 244,
            "jobId": "1515908907886-o77mhz",
            "playlistUrl": "/api/post/5683213900972032_1_1515908858787/premium/audio/03---Nhato---Fallin--Deep.mp3/playlist.m3u8"
          },
          "duration": 244.035918,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5683213900972032_1_1515908858787/premium/03---Nhato---Fallin--Deep.mp3",
          "eTag": "257973bbd92d991f859cccf5b8dd4c99",
          "sequencer": "005A5AEF2516C4F8AE",
          "mime": "audio/mpeg",
          "download": "/api/post/5683213900972032_1_1515908858787/media/download?key=5683213900972032_1_1515908858787/premium/03---Nhato---Fallin--Deep.mp3"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Test pos",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5683213900972032_1_1515908858787/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5718643555958784_0",
      "userId": 5718643555958784,
      "price": 1,
      "name": "test post",
      "tags": [
        "football"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 84,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 1,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 2,
      "updated": 1510265614962,
      "created": 1510265434714,
      "premiumContent": "",
      "id": "5718643555958784_0_1510265434713",
      "indexedName": "test post",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 1204995,
          "mime": "image/jpeg",
          "bucketName": "perplay-media",
          "key": "5718643555958784_0_1510265434713/premium/IMG-20170925-101445.jpg",
          "eTag": "9443bde20a00abd1661943ca6b5c7495",
          "sequencer": "005A04D2961E1E75D6",
          "download": "/api/post/5718643555958784_0_1510265434713/media/download?key=5718643555958784_0_1510265434713/premium/IMG-20170925-101445.jpg"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstLeadWords": "desc",
      "minCostPrice": 1
    },
    {
      "channelId": "5718643555958784_0",
      "userId": 5718643555958784,
      "price": 1,
      "name": "this is sparta",
      "tags": [
        "art",
        "football",
        "entertainment",
        "men"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 15,
      "purchaseCount": 3,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 1,
      "rating5star": 0,
      "ratingAvg": 4,
      "updated": 1510490857038,
      "created": 1510425360351,
      "premiumContent": "",
      "id": "5718643555958784_0_1510425360350",
      "indexedName": "this is sparta",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 88227,
          "mime": "image/jpeg",
          "bucketName": "perplay-media",
          "key": "5718643555958784_0_1510425360350/premium/0-neu-d4-9293236465d076e1ddf898b00875276b.jpg",
          "eTag": "438540f8d1200e587ac52914a73cb192",
          "sequencer": "005A07432DD2EE6507",
          "download": "/api/post/5718643555958784_0_1510425360350/media/download?key=5718643555958784_0_1510425360350/premium/0-neu-d4-9293236465d076e1ddf898b00875276b.jpg"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstLeadWords": "desc scriptiondsa dsadsads",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5718643555958784_0_1510425360350/thumbnail/image/thumbnail.jpg",
      "minCostPrice": 1
    },
    {
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "price": 1,
      "name": "Day at work",
      "tags": [
        "comedy"
      ],
      "featured": 4,
      "publicPost": true,
      "commentsCount": 14,
      "likesCount": 0,
      "viewCount": 331805,
      "purchaseCount": 38,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 1,
      "rating4star": 1,
      "rating5star": 0,
      "ratingAvg": 3.5,
      "updated": 1521054403230,
      "created": 1506527176178,
      "id": "5758048710688768_0_1506527176177",
      "indexedName": "day at work",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 4,
      "premiumMedia": [
        {
          "size": 6906355,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "height": 360,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/HLS0400k",
                  "5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/HLS1000k",
                  "5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 142,
            "jobId": "1506527180184-brp738",
            "playlistUrl": "/api/post/5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/playlist.m3u8"
          },
          "duration": 141.149,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_0_1506527176177/premium/day-at-work.mp4",
          "mime": "video/mp4",
          "eTag": "09794a9923c9fd6119b41faafee3df8a",
          "sequencer": "0059CBC7CA1DF998C6",
          "thumbnails": [
            {
              "size": 3173,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1506527199000,
              "key": "5758048710688768_0_1506527176177/premium/video/day-at-work.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "download": "/api/post/5758048710688768_0_1506527176177/media/download?key=5758048710688768_0_1506527176177/premium/day-at-work.mp4"
        }
      ],
      "premWrdCnt": 5,
      "abuseCount": 0,
      "firstLeadWords": "day at work",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_0_1506527176177/thumbnail/image/thumbnail.png",
      "minAge": 0,
      "minCostPrice": 1
    },
    {
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "price": 30,
      "name": "Big buck bunny",
      "tags": [
        "classification-movies",
        "category-children",
        "category-comedy",
        "kind-video"
      ],
      "featured": 2,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 1051,
      "purchaseCount": 24,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 2,
      "rating4star": 0,
      "rating5star": 1,
      "ratingAvg": 3.6666666666666665,
      "updated": 1521071596745,
      "created": 1506529354296,
      "premiumContent": "",
      "id": "5758048710688768_0_1506529354295",
      "indexedName": "big buck bunny",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 5,
      "premiumMedia": [
        {
          "size": 725106140,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1920,
          "height": 1080,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_0_1506529354295/premium/video/big-buck-bunny-1080p-h264.mov/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_0_1506529354295/premium/video/big-buck-bunny-1080p-h264.mov/HLS0400k",
                  "5758048710688768_0_1506529354295/premium/video/big-buck-bunny-1080p-h264.mov/HLS1000k",
                  "5758048710688768_0_1506529354295/premium/video/big-buck-bunny-1080p-h264.mov/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_0_1506529354295/premium/video/big-buck-bunny-1080p-h264.mov/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 597,
            "jobId": "1506529464497-djewvc",
            "playlistUrl": "/api/post/5758048710688768_0_1506529354295/premium/video/big-buck-bunny-1080p-h264.mov/playlist.m3u8"
          },
          "duration": 596.461667,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_0_1506529354295/premium/big-buck-bunny-1080p-h264.mov",
          "mime": "video/quicktime",
          "eTag": "c23ab2ff12023c684f46fcc02c57b585",
          "sequencer": "0059CBD04C634E8439",
          "thumbnails": [],
          "download": "/api/post/5758048710688768_0_1506529354295/media/download?key=5758048710688768_0_1506529354295/premium/big-buck-bunny-1080p-h264.mov"
        }
      ],
      "premWrdCnt": 1,
      "abuseCount": 0,
      "firstLeadWords": "Big buck bunny animated show\nHollaaaABC12",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_0_1506529354295/thumbnail/image/thumbnail.jpg",
      "minAge": 0,
      "minCostPrice": 30
    },
    {
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "price": 26,
      "name": "Right to Health",
      "tags": [
        "classification-opinionscommentary",
        "category-health",
        "category-politicsgovernment",
        "classification-series",
        "kind-video"
      ],
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 77,
      "purchaseCount": 9,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 2,
      "rating5star": 1,
      "ratingAvg": 4.333333333333333,
      "updated": 1520580335648,
      "created": 1506543890078,
      "premiumContent": "",
      "id": "5758048710688768_0_1506543890078",
      "indexedName": "right to health",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 4,
      "premiumMedia": [
        {
          "size": 471746564,
          "formatName": "mpeg",
          "width": 352,
          "height": 480,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/HLS0400k",
                  "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/HLS1000k",
                  "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 936,
            "jobId": "1506543961254-q1f5al",
            "playlistUrl": "/api/post/5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/playlist.m3u8"
          },
          "duration": 935.5346,
          "bucketName": "perplay-media",
          "formatLongName": "MPEG-PS (MPEG-2 Program Stream)",
          "key": "5758048710688768_0_1506543890078/premium/right-to-health-1.mpeg",
          "mime": "video/mpeg",
          "eTag": "4b290300f7979fdd333aac7a239a8315",
          "sequencer": "0059CC09160CDC43EF",
          "thumbnails": [
            {
              "size": 10003,
              "fileName": "00001-144x108.png",
              "download": "/api/post/5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00001-144x108.png",
              "timestamp": 1506544140000,
              "key": "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00001-144x108.png"
            },
            {
              "size": 39481,
              "fileName": "00002-144x108.png",
              "download": "/api/post/5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00002-144x108.png",
              "timestamp": 1506544140000,
              "key": "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00002-144x108.png"
            },
            {
              "size": 34729,
              "fileName": "00003-144x108.png",
              "download": "/api/post/5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00003-144x108.png",
              "timestamp": 1506544140000,
              "key": "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00003-144x108.png"
            },
            {
              "size": 35841,
              "fileName": "00004-144x108.png",
              "download": "/api/post/5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00004-144x108.png",
              "timestamp": 1506544140000,
              "key": "5758048710688768_0_1506543890078/premium/video/right-to-health-1.mpeg/thumbnails/00004-144x108.png"
            }
          ],
          "download": "/api/post/5758048710688768_0_1506543890078/media/download?key=5758048710688768_0_1506543890078/premium/right-to-health-1.mpeg"
        }
      ],
      "premWrdCnt": 1,
      "abuseCount": 0,
      "firstLeadWords": "Right to health",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_0_1506543890078/thumbnail/image/thumbnail.png",
      "minAge": 0,
      "minCostPrice": 20
    },
    {
      "id": "5758048710688768_0_1519583972665",
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "name": "Poor places are more diverse",
      "tags": [
        "classification-opinionscommentary",
        "category-politicsgovernment",
        "kind-video"
      ],
      "indexedName": "poor places are more diverse",
      "state": "PUBLIC",
      "featured": 0,
      "leadMedia": [
        {
          "size": 285310,
          "formatName": "ogg",
          "width": 320,
          "thumbnails": [
            {
              "size": 4057,
              "fileName": "00001-144x108.png",
              "download": "/api/post/5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/thumbnails/00001-144x108.png",
              "timestamp": 1519668549000,
              "key": "5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/thumbnails/00001-144x108.png"
            }
          ],
          "bitrate": 570625,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/HLS0400k",
                  "5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 4,
            "jobId": "1519668543741-u0648p",
            "playlistUrl": "/api/post/5758048710688768_0_1519583972665/lead/video/test-320x240.ogg/playlist.m3u8"
          },
          "duration": 3.99996,
          "bucketName": "perplay-media",
          "formatLongName": "Ogg",
          "key": "5758048710688768_0_1519583972665/lead/test-320x240.ogg",
          "mime": "video/ogg",
          "height": 240,
          "eTag": "3d7b520e9789aac42c58cdae6cc51382",
          "sequencer": "005A944D3F045FA0CA",
          "statusCode": "OK",
          "download": "/api/post/5758048710688768_0_1519583972665/media/download?key=5758048710688768_0_1519583972665/lead/test-320x240.ogg"
        }
      ],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 12345076,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 8052,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1519584004000,
              "key": "5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 464076,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/HLS0400k",
                  "5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 213,
            "jobId": "1519583977263-d3llar",
            "playlistUrl": "/api/post/5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/playlist.m3u8"
          },
          "duration": 212.810878,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_0_1519583972665/premium/Why-Poor-Places-Are-More-Diverse.mp4",
          "mime": "video/mp4",
          "height": 720,
          "eTag": "446ddb7f6bf60ed4e97556f7a7feec09",
          "sequencer": "005A9302E7F8C14B88",
          "statusCode": "OK",
          "download": "/api/post/5758048710688768_0_1519583972665/media/download?key=5758048710688768_0_1519583972665/premium/Why-Poor-Places-Are-More-Diverse.mp4"
        }
      ],
      "premWrdCnt": 1,
      "commentsCount": 0,
      "viewCount": 19,
      "purchaseCount": 1,
      "likesCount": 0,
      "price": 3,
      "currency": "PROD",
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 1,
      "rating5star": 0,
      "abuseCount": 0,
      "minAge": 0,
      "updated": 1521052193740,
      "created": 1519583972667,
      "firstPremWords": "",
      "firstLeadWords": "Diversity vide",
      "minCostPrice": 2,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_0_1519583972665/thumbnail/image/thumbnail.png"
    },
    {
      "id": "5758048710688768_0_1519586160522",
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "name": "Helicopter ski",
      "tags": [
        "classification-videoartists",
        "category-art",
        "classification-caughtlive",
        "category-sports",
        "kind-video"
      ],
      "indexedName": "helicopter ski",
      "state": "PUBLIC",
      "featured": 0,
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 383850820,
          "formatName": "matroska,webm",
          "width": 1920,
          "thumbnails": [
            {
              "size": 16029,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/thumbnails/00001-1280x720.jpg",
              "timestamp": 1519586301000,
              "key": "5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/thumbnails/00001-1280x720.jpg"
            }
          ],
          "bitrate": 13946908,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS0400k",
                  "5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS1000k",
                  "5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS3000k",
                  "5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 221,
            "jobId": "1519586217278-1ur4cb",
            "playlistUrl": "/api/post/5758048710688768_0_1519586160522/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/playlist.m3u8"
          },
          "duration": 220.178296,
          "bucketName": "perplay-media",
          "formatLongName": "Matroska / WebM",
          "key": "5758048710688768_0_1519586160522/premium/WiegelesHeliSki-DivXPlus-19Mbps.mkv",
          "mime": "video/x-matroska",
          "height": 1080,
          "eTag": "130d073bf5ec47089e1f904f9f9b2fae",
          "sequencer": "005A930B73873D0EB4",
          "statusCode": "OK",
          "download": "/api/post/5758048710688768_0_1519586160522/media/download?key=5758048710688768_0_1519586160522/premium/WiegelesHeliSki-DivXPlus-19Mbps.mkv"
        }
      ],
      "premWrdCnt": 3,
      "commentsCount": 0,
      "viewCount": 25,
      "purchaseCount": 1,
      "likesCount": 0,
      "price": 26,
      "currency": "PROD",
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "abuseCount": 0,
      "minAge": 0,
      "updated": 1519973389429,
      "created": 1519586160523,
      "firstPremWords": "Helicopter ski",
      "firstLeadWords": "Helicopter sk",
      "minCostPrice": 16,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_0_1519586160522/thumbnail/image/thumbnail.jpg"
    },
    {
      "id": "5758048710688768_0_1519677092763",
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "name": "The Feds Can Now (Probably) Unlock Every iPhone Model In Existence",
      "tags": [
        "classification-news",
        "category-sciencetechnology",
        "category-politics",
        "kind-text"
      ],
      "indexedName": "the feds can now (probably) unlock every iphone model in existence",
      "state": "PUBLIC",
      "featured": 0,
      "leadMedia": [],
      "leadWrdCnt": 39,
      "premiumMedia": [],
      "premWrdCnt": 1091,
      "commentsCount": 0,
      "viewCount": 6,
      "purchaseCount": 0,
      "likesCount": 0,
      "price": 100,
      "currency": "PROD",
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "abuseCount": 0,
      "minAge": 0,
      "updated": 1519677424400,
      "created": 1519677092763,
      "firstPremWords": "Cellebrite, a Petah Tikva, Israel-based vendor that's become the U.S. government's company of choice",
      "firstLeadWords": "In what appears to be a major breakthrough for law enforcement, and a possible privacy problem for Apple",
      "minCostPrice": 1
    },
    {
      "id": "5758048710688768_0_1519831505418",
      "channelId": "5758048710688768_0",
      "userId": 5758048710688768,
      "name": "quilljs",
      "tags": [
        "classification-essays",
        "category-sciencetechnology",
        "kind-text"
      ],
      "indexedName": "quilljs",
      "state": "PUBLIC",
      "featured": 0,
      "leadMedia": [],
      "leadWrdCnt": 63,
      "premiumMedia": [],
      "premWrdCnt": 614,
      "commentsCount": 0,
      "viewCount": 72,
      "purchaseCount": 1,
      "likesCount": 0,
      "price": 1,
      "currency": "PROD",
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 1,
      "rating4star": 0,
      "rating5star": 1,
      "abuseCount": 0,
      "minAge": 0,
      "updated": 1520580136974,
      "created": 1519831505421,
      "firstPremWords": "Why Quill Content creation has been at the core to the web since its beginning. The <textarea> provides",
      "firstLeadWords": "Content creation has been at the core to the web since its beginning. The <textarea> provides a native",
      "minCostPrice": 1
    },
    {
      "channelId": "5758048710688768_1",
      "userId": 5758048710688768,
      "price": 399,
      "name": "Sintel",
      "tags": [
        "classification-type",
        "category-video",
        "classification-movies",
        "category-art",
        "kind-video"
      ],
      "featured": 1,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 78,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 1,
      "rating5star": 1,
      "ratingAvg": 4.5,
      "updated": 1518890560014,
      "created": 1506527057087,
      "id": "5758048710688768_1_1506527057085",
      "indexedName": "sintel",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 9,
      "premiumMedia": [
        {
          "size": 138173570,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "height": 546,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/HLS0400k",
                  "5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/HLS1000k",
                  "5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 889,
            "jobId": "1506527329270-8s61wm",
            "playlistUrl": "/api/post/5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/playlist.m3u8"
          },
          "duration": 888.047156,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_1_1506527057085/premium/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4",
          "mime": "video/mp4",
          "eTag": "8b5a76c260664e8e6c70066cf8657710",
          "sequencer": "0059CBC8449EEF4AE8",
          "thumbnails": [
            {
              "size": 34011,
              "fileName": "00002-192x82.png",
              "download": "/api/post/5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/thumbnails/00002-192x82.png",
              "timestamp": 1506527451000,
              "key": "5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/thumbnails/00002-192x82.png"
            },
            {
              "size": 19950,
              "fileName": "00003-192x82.png",
              "download": "/api/post/5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/thumbnails/00003-192x82.png",
              "timestamp": 1506527451000,
              "key": "5758048710688768_1_1506527057085/premium/video/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4/thumbnails/00003-192x82.png"
            }
          ],
          "download": "/api/post/5758048710688768_1_1506527057085/media/download?key=5758048710688768_1_1506527057085/premium/Sintel---Third-Open-Movie-by-Blender-Foundation.mp4"
        }
      ],
      "premWrdCnt": 3,
      "abuseCount": 0,
      "firstLeadWords": "Sintel - Third Open Movie by Blender Foundation.",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_1_1506527057085/thumbnail/image/thumbnail.png",
      "firstPremWords": "  sintel ",
      "minAge": 0,
      "minCostPrice": 7
    },
    {
      "channelId": "5758048710688768_1",
      "userId": 5758048710688768,
      "price": 100,
      "name": "Aerial shot of city",
      "tags": [
        "classification-learning",
        "category-travel",
        "classification-videoartists",
        "category-art",
        "kind-video"
      ],
      "featured": 5,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 241,
      "purchaseCount": 19,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 1,
      "rating4star": 0,
      "rating5star": 1,
      "ratingAvg": 4,
      "updated": 1521071810887,
      "created": 1506527057132,
      "premiumContent": "",
      "id": "5758048710688768_1_1506527057130",
      "indexedName": "aerial shot of city",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 5,
      "premiumMedia": [
        {
          "size": 45123756,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1920,
          "height": 1080,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/HLS0400k",
                  "5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/HLS1000k",
                  "5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/playlist.m3u8"
              }
            ],
            "state": "WARNING",
            "duration": 76,
            "jobId": "1506527067274-mf9o8h",
            "playlistUrl": "/api/post/5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/playlist.m3u8"
          },
          "duration": 75.925,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_1_1506527057130/premium/Aerial-Shot-Of-City.mp4",
          "mime": "video/mp4",
          "eTag": "383a79214a3d38653f23c0de0c101c89",
          "sequencer": "0059CBC7537D8B0C1E",
          "thumbnails": [
            {
              "size": 44008,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1506527089000,
              "key": "5758048710688768_1_1506527057130/premium/video/Aerial-Shot-Of-City.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "download": "/api/post/5758048710688768_1_1506527057130/media/download?key=5758048710688768_1_1506527057130/premium/Aerial-Shot-Of-City.mp4"
        }
      ],
      "premWrdCnt": 1,
      "abuseCount": 0,
      "firstLeadWords": "Nice overhead aerial shot.",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_1_1506527057130/thumbnail/image/thumbnail.png",
      "minAge": 0,
      "minCostPrice": 3
    },
    {
      "channelId": "5758048710688768_1",
      "userId": 5758048710688768,
      "price": 800,
      "name": "Drive at night",
      "tags": [
        "classification-caughtlive",
        "category-autos",
        "classification-videoartists",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 3,
      "likesCount": 0,
      "viewCount": 25,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1513946472286,
      "created": 1513945264840,
      "id": "5758048710688768_1_1513945264838",
      "indexedName": "drive at night",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 4,
      "premiumMedia": [
        {
          "size": 153578513,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 3492,
          "thumbnails": [
            {
              "size": 54027,
              "fileName": "00001-1164x720.jpg",
              "download": "/api/post/5758048710688768_1_1513945264838/premium/video/342244658.mp4/thumbnails/00001-1164x720.jpg",
              "timestamp": 1513945352000,
              "key": "5758048710688768_1_1513945264838/premium/video/342244658.mp4/thumbnails/00001-1164x720.jpg"
            }
          ],
          "bitrate": 22340046,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_1_1513945264838/premium/video/342244658.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_1_1513945264838/premium/video/342244658.mp4/HLS0400k",
                  "5758048710688768_1_1513945264838/premium/video/342244658.mp4/HLS1000k",
                  "5758048710688768_1_1513945264838/premium/video/342244658.mp4/HLS2000k",
                  "5758048710688768_1_1513945264838/premium/video/342244658.mp4/HLS3000k",
                  "5758048710688768_1_1513945264838/premium/video/342244658.mp4/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_1_1513945264838/premium/video/342244658.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 56,
            "jobId": "1513945300133-14wenj",
            "playlistUrl": "/api/post/5758048710688768_1_1513945264838/premium/video/342244658.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "934d65d782e4e1f0fb130c152e05afa5",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_1_1513945264838/premium/342244658.mp4",
          "bucketName": "perplay-media",
          "height": 2160,
          "duration": 54.996667,
          "sequencer": "005A3CF8BEB038658A",
          "download": "/api/post/5758048710688768_1_1513945264838/media/download?key=5758048710688768_1_1513945264838/premium/342244658.mp4"
        }
      ],
      "premWrdCnt": 2,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "test ",
      "firstLeadWords": "Car driving at nigh",
      "minCostPrice": 7,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_1_1513945264838/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_1",
      "userId": 5758048710688768,
      "price": 15,
      "name": "Long Chaplin Movie - RPH Test of Create New Video Post",
      "tags": [
        "classification-movies",
        "category-comedy",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 9,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519973999488,
      "created": 1518207745477,
      "leadContent": "This is a long chaplin movie for testing.",
      "premiumContent": '<p><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<span class="ql-cursor">﻿</span></span></p>',
      "id": "5758048710688768_1_1518207745476",
      "indexedName": "long chaplin movie - rph test of create new video post",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 206403588,
          "formatName": "mpeg",
          "width": 352,
          "thumbnails": [
            {
              "size": 5783,
              "fileName": "00001-320x240.jpg",
              "download": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00001-320x240.jpg",
              "timestamp": 1518207998000,
              "key": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00001-320x240.jpg"
            },
            {
              "size": 6699,
              "fileName": "00002-320x240.jpg",
              "download": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00002-320x240.jpg",
              "timestamp": 1518207998000,
              "key": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00002-320x240.jpg"
            },
            {
              "size": 8890,
              "fileName": "00003-320x240.jpg",
              "download": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00003-320x240.jpg",
              "timestamp": 1518207998000,
              "key": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00003-320x240.jpg"
            },
            {
              "size": 9235,
              "fileName": "00004-320x240.jpg",
              "download": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00004-320x240.jpg",
              "timestamp": 1518207998000,
              "key": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00004-320x240.jpg"
            },
            {
              "size": 11053,
              "fileName": "00005-320x240.jpg",
              "download": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00005-320x240.jpg",
              "timestamp": 1518207998000,
              "key": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00005-320x240.jpg"
            },
            {
              "size": 6894,
              "fileName": "00006-320x240.jpg",
              "download": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00006-320x240.jpg",
              "timestamp": 1518207998000,
              "key": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00006-320x240.jpg"
            }
          ],
          "bitrate": 1363512,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/HLS0400k",
                  "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/HLS1000k",
                  "5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1212,
            "jobId": "1518207892811-tjahlr",
            "playlistUrl": "/api/post/5758048710688768_1_1518207745476/premium/video/CC-1916-09-04-TheCount.mpg/playlist.m3u8"
          },
          "mime": "video/mpeg",
          "eTag": "fc4c9ce932b8964d86fbfac424b9bd46",
          "formatLongName": "MPEG-PS (MPEG-2 Program Stream)",
          "key": "5758048710688768_1_1518207745476/premium/CC-1916-09-04-TheCount.mpg",
          "bucketName": "perplay-media",
          "height": 240,
          "duration": 1211.010611,
          "sequencer": "005A7E030328AC482E",
          "download": "/api/post/5758048710688768_1_1518207745476/media/download?key=5758048710688768_1_1518207745476/premium/CC-1916-09-04-TheCount.mpg"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "",
      "minCostPrice": 9,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_1_1518207745476/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_1",
      "userId": 5758048710688768,
      "price": 350,
      "name": "Elephants dream",
      "tags": [
        "classification-movies",
        "category-art",
        "category-children",
        "category-comedy",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 41,
      "purchaseCount": 3,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518890180593,
      "created": 1518888691024,
      "id": "5758048710688768_1_1518888691023",
      "indexedName": "elephants dream",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 8,
      "premiumMedia": [
        {
          "size": 556282998,
          "formatName": "avi",
          "width": 1440,
          "thumbnails": [
            {
              "size": 16167,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00001-1280x720.jpg",
              "timestamp": 1518889015000,
              "key": "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00001-1280x720.jpg"
            },
            {
              "size": 33222,
              "fileName": "00002-1280x720.jpg",
              "download": "/api/post/5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00002-1280x720.jpg",
              "timestamp": 1518889015000,
              "key": "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00002-1280x720.jpg"
            },
            {
              "size": 38334,
              "fileName": "00003-1280x720.jpg",
              "download": "/api/post/5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00003-1280x720.jpg",
              "timestamp": 1518889015000,
              "key": "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00003-1280x720.jpg"
            }
          ],
          "bitrate": 6596659,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS0400k",
                  "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS1000k",
                  "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS2000k",
                  "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS3000k",
                  "5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 675,
            "jobId": "1518888776841-lomneb",
            "playlistUrl": "/api/post/5758048710688768_1_1518888691023/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/playlist.m3u8"
          },
          "mime": "video/x-msvideo",
          "eTag": "aab6b906917ca8554809f0d219e1f287",
          "formatLongName": "AVI (Audio Video Interleaved)",
          "key": "5758048710688768_1_1518888691023/premium/ElephantsDream-DivXHD1080p-ASP.divx",
          "bucketName": "perplay-media",
          "height": 1080,
          "duration": 674.623901,
          "sequencer": "005A8866F43479048A",
          "download": "/api/post/5758048710688768_1_1518888691023/media/download?key=5758048710688768_1_1518888691023/premium/ElephantsDream-DivXHD1080p-ASP.divx"
        }
      ],
      "premWrdCnt": 6,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "test text here. Ipsum lorem. ",
      "firstLeadWords": "Elephants dream short video. Source is 550MB 1080",
      "minCostPrice": 23,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_1_1518888691023/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_2",
      "userId": 5758048710688768,
      "price": 2,
      "name": "TOMORROW",
      "tags": [
        "classification-songs",
        "category-blues",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 12,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1514308214490,
      "created": 1514307758585,
      "id": "5758048710688768_2_1514307758584",
      "indexedName": " tomorrow",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 4127453,
          "formatName": "mp3",
          "bitrate": 112000,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_2_1514307758584/premium/audio/bensound-tomorrow.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_2_1514307758584/premium/audio/bensound-tomorrow.mp3/HLSAudio160k",
                  "5758048710688768_2_1514307758584/premium/audio/bensound-tomorrow.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_2_1514307758584/premium/audio/bensound-tomorrow.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 295,
            "jobId": "1514307772542-bh03nf",
            "playlistUrl": "/api/post/5758048710688768_2_1514307758584/premium/audio/bensound-tomorrow.mp3/playlist.m3u8"
          },
          "duration": 294.818,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5758048710688768_2_1514307758584/premium/bensound-tomorrow.mp3",
          "mime": "audio/mpeg",
          "eTag": "144a1b76468bd3b6ed9875f0b0b77597",
          "sequencer": "005A4280BBAF099A55",
          "download": "/api/post/5758048710688768_2_1514307758584/media/download?key=5758048710688768_2_1514307758584/premium/bensound-tomorrow.mp3"
        }
      ],
      "premWrdCnt": 8,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Royalty Free Music from Bensound",
      "firstLeadWords": "Music: www.bensound.co",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_2_1514307758584/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_2",
      "userId": 5758048710688768,
      "price": 1,
      "name": " DUBSTEP",
      "tags": [
        "classification-songs",
        "category-dance",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 16,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1514310704670,
      "created": 1514308349008,
      "id": "5758048710688768_2_1514308349007",
      "indexedName": " dubstep",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 1749577,
          "formatName": "mp3",
          "bitrate": 112000,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_2_1514308349007/premium/audio/bensound-dubstep.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_2_1514308349007/premium/audio/bensound-dubstep.mp3/HLSAudio160k",
                  "5758048710688768_2_1514308349007/premium/audio/bensound-dubstep.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_2_1514308349007/premium/audio/bensound-dubstep.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 125,
            "jobId": "1514308359892-jkb49h",
            "playlistUrl": "/api/post/5758048710688768_2_1514308349007/premium/audio/bensound-dubstep.mp3/playlist.m3u8"
          },
          "duration": 124.969714,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5758048710688768_2_1514308349007/premium/bensound-dubstep.mp3",
          "mime": "audio/mpeg",
          "eTag": "aa6f13e3253a80e599c91ef985228430",
          "sequencer": "005A4283072F2A7C12",
          "download": "/api/post/5758048710688768_2_1514308349007/media/download?key=5758048710688768_2_1514308349007/premium/bensound-dubstep.mp3"
        }
      ],
      "premWrdCnt": 7,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Royalty Free Music from Bensound",
      "firstLeadWords": "Music: www.bensound.co",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_2_1514308349007/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_2",
      "userId": 5758048710688768,
      "price": 1,
      "name": "Downtown",
      "tags": [
        "classification-songs",
        "category-rock",
        "and",
        "roll",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 13,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1514310684117,
      "created": 1514308542434,
      "id": "5758048710688768_2_1514308542433",
      "indexedName": "downtown",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 2553417,
          "formatName": "mp3",
          "bitrate": 112000,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_2_1514308542433/premium/audio/bensound-downtown.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_2_1514308542433/premium/audio/bensound-downtown.mp3/HLSAudio160k",
                  "5758048710688768_2_1514308542433/premium/audio/bensound-downtown.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_2_1514308542433/premium/audio/bensound-downtown.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 183,
            "jobId": "1514308553835-zmilzg",
            "playlistUrl": "/api/post/5758048710688768_2_1514308542433/premium/audio/bensound-downtown.mp3/playlist.m3u8"
          },
          "duration": 182.386857,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5758048710688768_2_1514308542433/premium/bensound-downtown.mp3",
          "mime": "audio/mpeg",
          "eTag": "2bc3a43313f18bf944cf0c7c92bacda0",
          "sequencer": "005A4283C8AD3EE63B",
          "download": "/api/post/5758048710688768_2_1514308542433/media/download?key=5758048710688768_2_1514308542433/premium/bensound-downtown.mp3"
        }
      ],
      "premWrdCnt": 7,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Royalty Free Music from Bensound",
      "firstLeadWords": "Music: www.bensound.co",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_2_1514308542433/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_2",
      "userId": 5758048710688768,
      "price": 1,
      "name": "Midsummers Night Dream - 0 - Dramatis Personae",
      "tags": [
        "classification-audiobooks",
        "category-history",
        "category-literaturefiction",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 8,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1517857166705,
      "created": 1517856740257,
      "id": "5758048710688768_2_1517856740256",
      "indexedName": "midsummers night dream - 0 - dramatis personae",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 77,
      "premiumMedia": [
        {
          "size": 1203640,
          "formatName": "mp3",
          "bitrate": 64017,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_2_1517856740256/premium/audio/midsummer-0-shakespeare-64kb.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_2_1517856740256/premium/audio/midsummer-0-shakespeare-64kb.mp3/HLSAudio160k",
                  "5758048710688768_2_1517856740256/premium/audio/midsummer-0-shakespeare-64kb.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_2_1517856740256/premium/audio/midsummer-0-shakespeare-64kb.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 151,
            "jobId": "1517856753700-fb3hbc",
            "playlistUrl": "/api/post/5758048710688768_2_1517856740256/premium/audio/midsummer-0-shakespeare-64kb.mp3/playlist.m3u8"
          },
          "duration": 150.413061,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5758048710688768_2_1517856740256/premium/midsummer-0-shakespeare-64kb.mp3",
          "mime": "audio/mpeg",
          "eTag": "b3e33de17cd68ea5679e53521b03d4c8",
          "sequencer": "005A78A7F0F771B7D0",
          "download": "/api/post/5758048710688768_2_1517856740256/media/download?key=5758048710688768_2_1517856740256/premium/midsummer-0-shakespeare-64kb.mp3"
        }
      ],
      "premWrdCnt": 9,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Midsummers Night Dream - 0 - Dramatis Personae ",
      "firstLeadWords": "Full of delightful nursery rhymes, charming poems and engaging stories, folk and fairy tales, this is",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_2_1517856740256/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_2",
      "userId": 5758048710688768,
      "price": 1,
      "name": "Midsummers Night Dream - Act 1",
      "tags": [
        "classification-audiobooks",
        "category-history",
        "category-literaturefiction",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 5,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1517857189795,
      "created": 1517856920882,
      "premiumContent": "",
      "id": "5758048710688768_2_1517856920881",
      "indexedName": "midsummers night dream - act 1",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 227,
      "premiumMedia": [
        {
          "size": 9618412,
          "formatName": "mp3",
          "bitrate": 64002,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_2_1517856920881/premium/audio/midsummer-1-shakespeare-64kb.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_2_1517856920881/premium/audio/midsummer-1-shakespeare-64kb.mp3/HLSAudio160k",
                  "5758048710688768_2_1517856920881/premium/audio/midsummer-1-shakespeare-64kb.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_2_1517856920881/premium/audio/midsummer-1-shakespeare-64kb.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1203,
            "jobId": "1517856929368-mtz2j3",
            "playlistUrl": "/api/post/5758048710688768_2_1517856920881/premium/audio/midsummer-1-shakespeare-64kb.mp3/playlist.m3u8"
          },
          "duration": 1202.259592,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5758048710688768_2_1517856920881/premium/midsummer-1-shakespeare-64kb.mp3",
          "mime": "audio/mpeg",
          "eTag": "0d3767aaf17dedde4a5e4708618cc6ae",
          "sequencer": "005A78A89D6D80D344",
          "download": "/api/post/5758048710688768_2_1517856920881/media/download?key=5758048710688768_2_1517856920881/premium/midsummer-1-shakespeare-64kb.mp3"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Magic, fairies, young lovers chasing each other through a forest, a man with a donkey's head, and impish",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_2_1517856920881/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_2",
      "userId": 5758048710688768,
      "price": 1,
      "name": "Midsummers Night Dream - Act 2",
      "tags": [
        "classification-audiobooks",
        "category-history",
        "category-literaturefiction",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 4,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 1,
      "ratingAvg": 5,
      "updated": 1517858435464,
      "created": 1517857039608,
      "premiumContent": "",
      "id": "5758048710688768_2_1517857039606",
      "indexedName": "midsummers night dream - act 2",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 227,
      "premiumMedia": [
        {
          "size": 11648440,
          "formatName": "mp3",
          "bitrate": 64001,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_2_1517857039606/premium/audio/midsummer-2-shakespeare-64kb.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_2_1517857039606/premium/audio/midsummer-2-shakespeare-64kb.mp3/HLSAudio160k",
                  "5758048710688768_2_1517857039606/premium/audio/midsummer-2-shakespeare-64kb.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_2_1517857039606/premium/audio/midsummer-2-shakespeare-64kb.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1456,
            "jobId": "1517857049298-xwk98g",
            "playlistUrl": "/api/post/5758048710688768_2_1517857039606/premium/audio/midsummer-2-shakespeare-64kb.mp3/playlist.m3u8"
          },
          "duration": 1456.013061,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5758048710688768_2_1517857039606/premium/midsummer-2-shakespeare-64kb.mp3",
          "mime": "audio/mpeg",
          "eTag": "413e766a8e35f1d94357454e4c2d65bc",
          "sequencer": "005A78A914F20588CB",
          "download": "/api/post/5758048710688768_2_1517857039606/media/download?key=5758048710688768_2_1517857039606/premium/midsummer-2-shakespeare-64kb.mp3"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Magic, fairies, young lovers chasing each other through a forest, a man with a donkey's head, and impish",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_2_1517857039606/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_6",
      "userId": 5758048710688768,
      "price": 2,
      "name": "night sky",
      "tags": [
        "classification-videoartists",
        "category-photography",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 0,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519381534371,
      "created": 1517346151533,
      "id": "5758048710688768_6_1517346151530",
      "indexedName": "night sky",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 6410370,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 82424,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1517346172000,
              "key": "5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/thumbnails/00001-1280x720.jpg"
            }
          ],
          "bitrate": 2491479,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/HLS0400k",
                  "5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/HLS1000k",
                  "5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 21,
            "jobId": "1517346163212-urdepw",
            "playlistUrl": "/api/post/5758048710688768_6_1517346151530/premium/video/Night---1099.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "1c370bbbec980060a9f50f406ad6098b",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_6_1517346151530/premium/Night---1099.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 20.583333,
          "sequencer": "005A70DD6D7D19A960",
          "download": "/api/post/5758048710688768_6_1517346151530/media/download?key=5758048710688768_6_1517346151530/premium/Night---1099.mp4"
        }
      ],
      "premWrdCnt": 6,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "night sky 21 secs long ",
      "firstLeadWords": "night sky vide",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_6_1517346151530/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_6",
      "userId": 5758048710688768,
      "price": 33,
      "name": "Jellyfish",
      "tags": [
        "classification-caughtlive",
        "category-animalspets",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 2,
      "likesCount": 0,
      "viewCount": 15,
      "purchaseCount": 4,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1517359388138,
      "created": 1517359208118,
      "location": {
        "type": "Point",
        "coordinates": [
          -80.1917902,
          25.7616798
        ]
      },
      "id": "5758048710688768_6_1517359208110",
      "indexedName": "jellyfish",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 9,
      "premiumMedia": [
        {
          "size": 3194375,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "thumbnails": [
            {
              "size": 33219,
              "fileName": "00001-640x360.jpg",
              "download": "/api/post/5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/thumbnails/00001-640x360.jpg",
              "timestamp": 1517359231000,
              "key": "5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/thumbnails/00001-640x360.jpg"
            }
          ],
          "bitrate": 2983073,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/HLS0400k",
                  "5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/HLS1000k",
                  "5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 9,
            "jobId": "1517359223435-atiqyb",
            "playlistUrl": "/api/post/5758048710688768_6_1517359208110/premium/video/IMG-6170.m4v/playlist.m3u8"
          },
          "mime": "video/quicktime",
          "eTag": "bfa8878722722d34bb813dab718195f1",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_6_1517359208110/premium/IMG-6170.m4v",
          "bucketName": "perplay-media",
          "height": 360,
          "duration": 8.566667,
          "sequencer": "005A7110765930BE33",
          "download": "/api/post/5758048710688768_6_1517359208110/media/download?key=5758048710688768_6_1517359208110/premium/IMG-6170.m4v"
        }
      ],
      "premWrdCnt": 90,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse bibendum ac tellus rutrum pellentesque.",
      "firstLeadWords": "Jellyfish exhibit at Frost Science Museum in Miami, F",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_6_1517359208110/thumbnail/image/thumbnail.jpg",
      "latitude": 25.7616798,
      "longitude": -80.1917902,
      "address": {
        "countryName": "United States",
        "countryCode": "US",
        "city": "Miami",
        "latitude": 25.7616798,
        "longitude": -80.1917902,
        "region": "Florida"
      }
    },
    {
      "channelId": "5758048710688768_6",
      "userId": 5758048710688768,
      "price": 1,
      "name": "Tala the Great",
      "tags": [
        "classification-interviews",
        "category-actors",
        "classification-caughtlive",
        "category-animalspets",
        "classification-instruction",
        "category-art",
        "classification-videoartists",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 2,
      "likesCount": 0,
      "viewCount": 60,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 1,
      "ratingAvg": 5,
      "updated": 1519074162331,
      "created": 1517487755175,
      "premiumContent": "",
      "id": "5758048710688768_6_1517487755174",
      "indexedName": "tala the great",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 5,
      "premiumMedia": [
        {
          "size": 2437770,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "thumbnails": [
            {
              "size": 19329,
              "fileName": "00001-640x360.jpg",
              "download": "/api/post/5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/thumbnails/00001-640x360.jpg",
              "timestamp": 1517487775000,
              "key": "5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/thumbnails/00001-640x360.jpg"
            }
          ],
          "bitrate": 3324231,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/HLS0400k",
                  "5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/HLS1000k",
                  "5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 6,
            "jobId": "1517487768486-zfk7sp",
            "playlistUrl": "/api/post/5758048710688768_6_1517487755174/premium/video/IMG-6519.m4v/playlist.m3u8"
          },
          "mime": "video/quicktime",
          "eTag": "63d4b08ed886fe687bd2fa9eec00aac3",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_6_1517487755174/premium/IMG-6519.m4v",
          "bucketName": "perplay-media",
          "height": 360,
          "duration": 5.866667,
          "sequencer": "005A73069770224559",
          "download": "/api/post/5758048710688768_6_1517487755174/media/download?key=5758048710688768_6_1517487755174/premium/IMG-6519.m4v"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Happy Tala in the sno",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_6_1517487755174/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_7",
      "userId": 5758048710688768,
      "price": 25,
      "name": "Snow",
      "tags": [
        "classification-caughtlive",
        "category-science",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 9,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519973672716,
      "created": 1519740396007,
      "premiumContent": "",
      "location": {
        "type": "Point",
        "coordinates": [
          -84.5037164,
          38.0405837
        ]
      },
      "id": "5758048710688768_7_1519740396006",
      "indexedName": "snow",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 2798270,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 360,
          "thumbnails": [
            {
              "size": 31897,
              "fileName": "00001-360x640.jpg",
              "download": "/api/post/5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/thumbnails/00001-360x640.jpg",
              "timestamp": 1519740409000,
              "key": "5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/thumbnails/00001-360x640.jpg"
            }
          ],
          "bitrate": 3391842,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/HLS0400k",
                  "5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/HLS1000k",
                  "5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 7,
            "jobId": "1519740402244-g3ah10",
            "playlistUrl": "/api/post/5758048710688768_7_1519740396006/premium/video/IMG-6542.m4v/playlist.m3u8"
          },
          "duration": 6.6,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_7_1519740396006/premium/IMG-6542.m4v",
          "mime": "video/quicktime",
          "height": 640,
          "eTag": "07c7568f740c135c2cc1cb836a69dd31",
          "sequencer": "005A9565F19DF741AA",
          "statusCode": "OK",
          "download": "/api/post/5758048710688768_7_1519740396006/media/download?key=5758048710688768_7_1519740396006/premium/IMG-6542.m4v"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Let it sno",
      "minCostPrice": 1,
      "latitude": 38.0405837,
      "longitude": -84.5037164,
      "address": {
        "countryName": "United States",
        "countryCode": "US",
        "city": "Lexington",
        "latitude": 38.0405837,
        "longitude": -84.5037164,
        "region": "Kentucky"
      },
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_7_1519740396006/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5758048710688768_7",
      "userId": 5758048710688768,
      "price": 25,
      "name": "Tala the Great",
      "tags": [
        "classification-caughtlive",
        "category-science",
        "category-sciencetechnology",
        "category-animalspets",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 10,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519752753185,
      "created": 1519740845386,
      "location": {
        "type": "Point",
        "coordinates": [
          -84.7299464,
          38.05257599999999
        ]
      },
      "id": "5758048710688768_7_1519740845385",
      "indexedName": "tala the great",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 8,
      "premiumMedia": [
        {
          "size": 2437770,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "thumbnails": [
            {
              "size": 19329,
              "fileName": "00001-640x360.jpg",
              "download": "/api/post/5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/thumbnails/00001-640x360.jpg",
              "timestamp": 1519740857000,
              "key": "5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/thumbnails/00001-640x360.jpg"
            }
          ],
          "bitrate": 3324231,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/HLS0400k",
                  "5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/HLS1000k",
                  "5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 6,
            "jobId": "1519740851090-c56m0k",
            "playlistUrl": "/api/post/5758048710688768_7_1519740845385/premium/video/IMG-6519.m4v/playlist.m3u8"
          },
          "duration": 5.866667,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_7_1519740845385/premium/IMG-6519.m4v",
          "mime": "video/quicktime",
          "height": 360,
          "eTag": "63d4b08ed886fe687bd2fa9eec00aac3",
          "sequencer": "005A9567B26BCBF180",
          "statusCode": "OK",
          "download": "/api/post/5758048710688768_7_1519740845385/media/download?key=5758048710688768_7_1519740845385/premium/IMG-6519.m4v"
        }
      ],
      "premWrdCnt": 405,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et sodales massa. Morbi non semper libero.",
      "firstLeadWords": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_7_1519740845385/thumbnail/image/thumbnail.jpg",
      "latitude": 38.05257599999999,
      "longitude": -84.7299464,
      "address": {
        "countryName": "United States",
        "countryCode": "US",
        "city": "Versailles",
        "latitude": 38.05257599999999,
        "longitude": -84.7299464,
        "region": "Kentucky",
        "postalCode": "40383"
      }
    },
    {
      "channelId": "5758048710688768_7",
      "userId": 5758048710688768,
      "price": 33,
      "name": "Jellllllly Fish",
      "tags": [
        "classification-caughtlive",
        "category-science",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 5,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519752413221,
      "created": 1519743260422,
      "id": "5758048710688768_7_1519743260420",
      "indexedName": "jellllllly fish",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 12,
      "premiumMedia": [
        {
          "size": 3194375,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "thumbnails": [
            {
              "size": 33219,
              "fileName": "00001-640x360.jpg",
              "download": "/api/post/5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/thumbnails/00001-640x360.jpg",
              "timestamp": 1519743274000,
              "key": "5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/thumbnails/00001-640x360.jpg"
            }
          ],
          "bitrate": 2983073,
          "transcoded": {
            "playlists": [
              {
                "name": "5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/HLS0400k",
                  "5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/HLS1000k",
                  "5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 9,
            "jobId": "1519743268165-74ybtx",
            "playlistUrl": "/api/post/5758048710688768_7_1519743260420/premium/video/IMG-6170.m4v/playlist.m3u8"
          },
          "duration": 8.566667,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5758048710688768_7_1519743260420/premium/IMG-6170.m4v",
          "mime": "video/quicktime",
          "height": 360,
          "eTag": "bfa8878722722d34bb813dab718195f1",
          "sequencer": "005A9571236165514D",
          "statusCode": "OK",
          "download": "/api/post/5758048710688768_7_1519743260420/media/download?key=5758048710688768_7_1519743260420/premium/IMG-6170.m4v"
        }
      ],
      "premWrdCnt": 405,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et sodales massa. Morbi non semper libero.",
      "firstLeadWords": "Proin et blandit purus morbi et sodales mauris. Aliquam venenatis tincidunt tincidunt",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5758048710688768_7_1519743260420/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "new test",
      "tags": [
        "test"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 7,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1509831821818,
      "created": 1507970796803,
      "id": "5765983402721280_0_1507970796802",
      "indexedName": "new test",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 4,
      "premiumMedia": [],
      "premWrdCnt": 3,
      "abuseCount": 0,
      "firstLeadWords": "test lead here2",
      "minAge": 0,
      "minCostPrice": 1,
      "thumbnailImage": "https://perplay-public-images.s3.amazonaws.com/channel/5765983402721280_0/profile/icon.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "Cosmos Laundromat",
      "tags": [],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 6841,
      "purchaseCount": 10,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1517150594039,
      "created": 1507970796823,
      "id": "5765983402721280_0_1507970796822",
      "indexedName": "cosmos laundromat",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 123404072,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "height": 536,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/HLS0400k",
                  "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/HLS1000k",
                  "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/playlist.m3u8"
              }
            ],
            "state": "WARNING",
            "duration": 731,
            "jobId": "1507970818606-rzlpz2",
            "playlistUrl": "/api/post/5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/playlist.m3u8"
          },
          "duration": 730.522989,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1507970796822/premium/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4",
          "mime": "video/mp4",
          "eTag": "a03dbafb94ac4b97640e897379ebc8ae",
          "sequencer": "0059E1CEF0C7FEA92E",
          "thumbnails": [
            {
              "size": 11444,
              "fileName": "00001-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00001-1280x536.jpg",
              "timestamp": 1507970949000,
              "key": "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00001-1280x536.jpg"
            },
            {
              "size": 36764,
              "fileName": "00002-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00002-1280x536.jpg",
              "timestamp": 1507970949000,
              "key": "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00002-1280x536.jpg"
            },
            {
              "size": 20148,
              "fileName": "00003-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00003-1280x536.jpg",
              "timestamp": 1507970949000,
              "key": "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00003-1280x536.jpg"
            },
            {
              "size": 21721,
              "fileName": "00004-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00004-1280x536.jpg",
              "timestamp": 1507970949000,
              "key": "5765983402721280_0_1507970796822/premium/video/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4/thumbnails/00004-1280x536.jpg"
            }
          ],
          "download": "/api/post/5765983402721280_0_1507970796822/media/download?key=5765983402721280_0_1507970796822/premium/Cosmos-Laundromat---First-Cycle.-Official-Blender-Foundation-release.mp4"
        }
      ],
      "premWrdCnt": 3,
      "abuseCount": 0,
      "firstLeadWords": "test lead ",
      "minCostPrice": 6,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1507970796822/thumbnail/image/thumbnail.jpg",
      "minAge": 0
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "Night of the living dead",
      "tags": [
        "classification-movies",
        "category-horror",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 489,
      "purchaseCount": 232,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519250398955,
      "created": 1510080386473,
      "id": "5765983402721280_0_1510080386470",
      "indexedName": "night of the living dead",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 415473786,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 320,
          "thumbnails": [
            {
              "size": 7499,
              "fileName": "00001-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00001-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00001-144x108.png"
            },
            {
              "size": 16801,
              "fileName": "00002-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00002-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00002-144x108.png"
            },
            {
              "size": 18291,
              "fileName": "00003-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00003-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00003-144x108.png"
            },
            {
              "size": 8362,
              "fileName": "00004-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00004-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00004-144x108.png"
            },
            {
              "size": 13264,
              "fileName": "00005-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00005-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00005-144x108.png"
            },
            {
              "size": 15571,
              "fileName": "00006-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00006-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00006-144x108.png"
            },
            {
              "size": 15949,
              "fileName": "00007-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00007-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00007-144x108.png"
            },
            {
              "size": 16107,
              "fileName": "00008-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00008-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00008-144x108.png"
            },
            {
              "size": 18382,
              "fileName": "00009-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00009-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00009-144x108.png"
            },
            {
              "size": 18203,
              "fileName": "00010-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00010-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00010-144x108.png"
            },
            {
              "size": 18212,
              "fileName": "00011-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00011-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00011-144x108.png"
            },
            {
              "size": 15591,
              "fileName": "00012-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00012-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00012-144x108.png"
            },
            {
              "size": 16945,
              "fileName": "00013-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00013-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00013-144x108.png"
            },
            {
              "size": 17303,
              "fileName": "00014-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00014-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00014-144x108.png"
            },
            {
              "size": 7711,
              "fileName": "00015-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00015-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00015-144x108.png"
            },
            {
              "size": 14239,
              "fileName": "00016-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00016-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00016-144x108.png"
            },
            {
              "size": 18655,
              "fileName": "00017-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00017-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00017-144x108.png"
            },
            {
              "size": 14447,
              "fileName": "00018-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00018-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00018-144x108.png"
            },
            {
              "size": 21856,
              "fileName": "00019-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00019-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00019-144x108.png"
            },
            {
              "size": 11251,
              "fileName": "00020-144x108.png",
              "download": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00020-144x108.png",
              "timestamp": 1510080850000,
              "key": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/thumbnails/00020-144x108.png"
            }
          ],
          "bitrate": 581364,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/HLS0400k",
                  "5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 5718,
            "jobId": "1510080504575-6xym9i",
            "playlistUrl": "/api/post/5765983402721280_0_1510080386470/premium/video/night-of-the-living-dead-512kb.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "e45cddd4bc3d158ee9eabd800158ec4c",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1510080386470/premium/night-of-the-living-dead-512kb.mp4",
          "bucketName": "perplay-media",
          "height": 240,
          "duration": 5717.218,
          "sequencer": "005A01FFBFC327FBC5",
          "download": "/api/post/5765983402721280_0_1510080386470/media/download?key=5765983402721280_0_1510080386470/premium/night-of-the-living-dead-512kb.mp4"
        }
      ],
      "premWrdCnt": 2,
      "currency": "PROD",
      "abuseCount": 0,
      "firstLeadWords": "tes",
      "minCostPrice": 18,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1510080386470/thumbnail/image/thumbnail.png",
      "minAge": 0
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 999,
      "name": "Aussie risy business",
      "tags": [
        "classification-news",
        "category-business",
        "category-environmental",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 13,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518359117617,
      "created": 1518201027781,
      "id": "5765983402721280_0_1518201027780",
      "indexedName": "aussie risy business",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 319098443,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 22134,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00001-1280x720.jpg"
            },
            {
              "size": 62518,
              "fileName": "00002-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00002-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00002-1280x720.jpg"
            },
            {
              "size": 44812,
              "fileName": "00003-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00003-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00003-1280x720.jpg"
            },
            {
              "size": 54553,
              "fileName": "00004-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00004-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00004-1280x720.jpg"
            },
            {
              "size": 126766,
              "fileName": "00005-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00005-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00005-1280x720.jpg"
            },
            {
              "size": 71446,
              "fileName": "00006-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00006-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00006-1280x720.jpg"
            },
            {
              "size": 72518,
              "fileName": "00007-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00007-1280x720.jpg",
              "timestamp": 1518201396000,
              "key": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/thumbnails/00007-1280x720.jpg"
            }
          ],
          "bitrate": 1699480,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/HLS0400k",
                  "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/HLS1000k",
                  "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/HLS2000k",
                  "5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/HLS3000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1503,
            "jobId": "1518201073586-j624oa",
            "playlistUrl": "/api/post/5765983402721280_0_1518201027780/premium/video/101-East---Australia-s-Risky-Business--720p-.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "71ebae206089933970084987bdb73902",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1518201027780/premium/101-East---Australia-s-Risky-Business--720p-.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 1502.098856,
          "sequencer": "005A7DE8C526D698EC",
          "download": "/api/post/5765983402721280_0_1518201027780/media/download?key=5765983402721280_0_1518201027780/premium/101-East---Australia-s-Risky-Business--720p-.mp4"
        }
      ],
      "premWrdCnt": 33,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Some of the world’s largest energy giants are moving into eastern Australia and investing billions of",
      "firstLeadWords": "Description text her",
      "minCostPrice": 14,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518201027780/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 321,
      "name": "Falcon heavy",
      "tags": [
        "classification-news",
        "category-technology",
        "classification-publicity",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 20,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518356483027,
      "created": 1518208943483,
      "premiumContent": "",
      "id": "5765983402721280_0_1518208943482",
      "indexedName": "falcon heavy",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 359019176,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 14964,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00001-1280x720.jpg"
            },
            {
              "size": 66591,
              "fileName": "00002-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00002-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00002-1280x720.jpg"
            },
            {
              "size": 64438,
              "fileName": "00003-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00003-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00003-1280x720.jpg"
            },
            {
              "size": 61250,
              "fileName": "00004-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00004-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00004-1280x720.jpg"
            },
            {
              "size": 50220,
              "fileName": "00005-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00005-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00005-1280x720.jpg"
            },
            {
              "size": 48142,
              "fileName": "00006-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00006-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00006-1280x720.jpg"
            },
            {
              "size": 61828,
              "fileName": "00007-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00007-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00007-1280x720.jpg"
            },
            {
              "size": 72411,
              "fileName": "00008-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00008-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00008-1280x720.jpg"
            },
            {
              "size": 43015,
              "fileName": "00009-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00009-1280x720.jpg",
              "timestamp": 1518209395000,
              "key": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/thumbnails/00009-1280x720.jpg"
            }
          ],
          "bitrate": 1395189,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/HLS0400k",
                  "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/HLS1000k",
                  "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/HLS2000k",
                  "5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/HLS3000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 2059,
            "jobId": "1518208998684-8pfvt9",
            "playlistUrl": "/api/post/5765983402721280_0_1518208943482/premium/video/Falcon-Heavy-Test-Flight.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "dba156af42500967fee002d587ad9e07",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1518208943482/premium/Falcon-Heavy-Test-Flight.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 2058.611511,
          "sequencer": "005A7E07B2F4640BFA",
          "download": "/api/post/5765983402721280_0_1518208943482/media/download?key=5765983402721280_0_1518208943482/premium/Falcon-Heavy-Test-Flight.mp4"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Spacex test fligh",
      "minCostPrice": 0,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518208943482/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1111,
      "name": "Cosmos Lanundromat",
      "tags": [
        "classification-movies",
        "category-sciencefiction",
        "category-children",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 42,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518632366718,
      "created": 1518298131617,
      "id": "5765983402721280_0_1518298131615",
      "indexedName": "cosmos lanundromat",
      "state": "PUBLIC",
      "leadMedia": [
        {
          "size": 5201363,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [],
          "bitrate": 838966,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518298131615/lead/video/Cosmos-Laundromat--Pilot-Trailer.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518298131615/lead/video/Cosmos-Laundromat--Pilot-Trailer.mp4/HLS0400k",
                  "5765983402721280_0_1518298131615/lead/video/Cosmos-Laundromat--Pilot-Trailer.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518298131615/lead/video/Cosmos-Laundromat--Pilot-Trailer.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 50,
            "jobId": "1518370214243-skxfyp",
            "playlistUrl": "/api/post/5765983402721280_0_1518298131615/lead/video/Cosmos-Laundromat--Pilot-Trailer.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "10a59b8d028f68ef9370c4c5cac706dd",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1518298131615/lead/Cosmos-Laundromat--Pilot-Trailer.mp4",
          "bucketName": "perplay-media",
          "height": 536,
          "duration": 49.597822,
          "sequencer": "005A807DA5354AF84B",
          "download": "/api/post/5765983402721280_0_1518298131615/media/download?key=5765983402721280_0_1518298131615/lead/Cosmos-Laundromat--Pilot-Trailer.mp4"
        }
      ],
      "leadWrdCnt": 36,
      "premiumMedia": [
        {
          "size": 220087570,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1920,
          "thumbnails": [
            {
              "size": 11444,
              "fileName": "00001-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00001-1280x536.jpg",
              "timestamp": 1518298439000,
              "key": "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00001-1280x536.jpg"
            },
            {
              "size": 37086,
              "fileName": "00002-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00002-1280x536.jpg",
              "timestamp": 1518298439000,
              "key": "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00002-1280x536.jpg"
            },
            {
              "size": 20920,
              "fileName": "00003-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00003-1280x536.jpg",
              "timestamp": 1518298439000,
              "key": "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00003-1280x536.jpg"
            },
            {
              "size": 21549,
              "fileName": "00004-1280x536.jpg",
              "download": "/api/post/5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00004-1280x536.jpg",
              "timestamp": 1518298439000,
              "key": "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/thumbnails/00004-1280x536.jpg"
            }
          ],
          "bitrate": 2409885,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/HLS0400k",
                  "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/HLS1000k",
                  "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/HLS2000k",
                  "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/HLS3000k",
                  "5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 731,
            "jobId": "1518298164401-xa7vcc",
            "playlistUrl": "/api/post/5765983402721280_0_1518298131615/premium/video/Cosmos-Laundromat---First-Cycle--1080p-.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "f22bb01a114965a0ded9574ee6792214",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1518298131615/premium/Cosmos-Laundromat---First-Cycle--1080p-.mp4",
          "bucketName": "perplay-media",
          "height": 804,
          "duration": 730.616,
          "sequencer": "005A7F6414B0A8F750",
          "download": "/api/post/5765983402721280_0_1518298131615/media/download?key=5765983402721280_0_1518298131615/premium/Cosmos-Laundromat---First-Cycle--1080p-.mp4"
        }
      ],
      "premWrdCnt": 30,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Details \n\n \n Official Sites: \n Official Blog \n\n \n Country: \n Netherlands \n\n \n Language: \n English \n\n",
      "firstLeadWords": "On a desolate island, suicidal sheep Franck meets his fate in a quirky salesman, who offers him the gift",
      "minCostPrice": 10,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518298131615/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 2,
      "name": "Violin",
      "tags": [
        "classification-music",
        "category-children",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 11,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518460489866,
      "created": 1518460367621,
      "premiumContent": "",
      "id": "5765983402721280_0_1518460367620",
      "indexedName": "violin",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 4335504,
          "formatName": "mp3",
          "bitrate": 320359,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518460367620/premium/audio/29-Track-29--2-.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518460367620/premium/audio/29-Track-29--2-.mp3/HLSAudio160k",
                  "5765983402721280_0_1518460367620/premium/audio/29-Track-29--2-.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518460367620/premium/audio/29-Track-29--2-.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 109,
            "jobId": "1518460371322-3w4xk7",
            "playlistUrl": "/api/post/5765983402721280_0_1518460367620/premium/audio/29-Track-29--2-.mp3/playlist.m3u8"
          },
          "duration": 108.266,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5765983402721280_0_1518460367620/premium/29-Track-29--2-.mp3",
          "mime": "audio/mpeg",
          "eTag": "ff380164efce8e2d0386541d03ca5022",
          "sequencer": "005A81DDD21777581D",
          "download": "/api/post/5765983402721280_0_1518460367620/media/download?key=5765983402721280_0_1518460367620/premium/29-Track-29--2-.mp3"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Violin practice musi",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518460367620/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 22,
      "name": "HeliSki",
      "tags": [
        "classification-caughtlive",
        "category-sports",
        "category-stunts",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 7,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518651089759,
      "created": 1518650748118,
      "id": "5765983402721280_0_1518650748116",
      "indexedName": "heliski",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 383850820,
          "formatName": "matroska,webm",
          "width": 1920,
          "thumbnails": [
            {
              "size": 16029,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/thumbnails/00001-1280x720.jpg",
              "timestamp": 1518650908000,
              "key": "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/thumbnails/00001-1280x720.jpg"
            }
          ],
          "bitrate": 13946908,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS0400k",
                  "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS1000k",
                  "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS2000k",
                  "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS3000k",
                  "5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 221,
            "jobId": "1518650822982-h08e0w",
            "playlistUrl": "/api/post/5765983402721280_0_1518650748116/premium/video/WiegelesHeliSki-DivXPlus-19Mbps.mkv/playlist.m3u8"
          },
          "mime": "video/x-matroska",
          "eTag": "130d073bf5ec47089e1f904f9f9b2fae",
          "formatLongName": "Matroska / WebM",
          "key": "5765983402721280_0_1518650748116/premium/WiegelesHeliSki-DivXPlus-19Mbps.mkv",
          "bucketName": "perplay-media",
          "height": 1080,
          "duration": 220.178296,
          "sequencer": "005A84C57D3ED0E1DD",
          "download": "/api/post/5765983402721280_0_1518650748116/media/download?key=5765983402721280_0_1518650748116/premium/WiegelesHeliSki-DivXPlus-19Mbps.mkv"
        }
      ],
      "premWrdCnt": 3,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Lets go ",
      "firstLeadWords": "Skiing in Helicopte",
      "minCostPrice": 16,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518650748116/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 11,
      "name": "Elephants dream",
      "tags": [
        "classification-movies",
        "category-children",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 13,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518651413810,
      "created": 1518650946015,
      "id": "5765983402721280_0_1518650946014",
      "indexedName": "elephants dream",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 556282998,
          "formatName": "avi",
          "width": 1440,
          "thumbnails": [
            {
              "size": 16167,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00001-1280x720.jpg",
              "timestamp": 1518651264000,
              "key": "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00001-1280x720.jpg"
            },
            {
              "size": 33222,
              "fileName": "00002-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00002-1280x720.jpg",
              "timestamp": 1518651264000,
              "key": "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00002-1280x720.jpg"
            },
            {
              "size": 38334,
              "fileName": "00003-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00003-1280x720.jpg",
              "timestamp": 1518651264000,
              "key": "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/thumbnails/00003-1280x720.jpg"
            }
          ],
          "bitrate": 6596659,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS0400k",
                  "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS1000k",
                  "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS2000k",
                  "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS3000k",
                  "5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 675,
            "jobId": "1518651037466-ha24wl",
            "playlistUrl": "/api/post/5765983402721280_0_1518650946014/premium/video/ElephantsDream-DivXHD1080p-ASP.divx/playlist.m3u8"
          },
          "mime": "video/x-msvideo",
          "eTag": "aab6b906917ca8554809f0d219e1f287",
          "formatLongName": "AVI (Audio Video Interleaved)",
          "key": "5765983402721280_0_1518650946014/premium/ElephantsDream-DivXHD1080p-ASP.divx",
          "bucketName": "perplay-media",
          "height": 1080,
          "duration": 674.623901,
          "sequencer": "005A84C645042E22FC",
          "download": "/api/post/5765983402721280_0_1518650946014/media/download?key=5765983402721280_0_1518650946014/premium/ElephantsDream-DivXHD1080p-ASP.divx"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Elephants drea",
      "minCostPrice": 23,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518650946014/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "Night sky",
      "tags": [
        "classification-videoartists",
        "category-photography",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 11,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518733312039,
      "created": 1518717642087,
      "premiumContent": "",
      "id": "5765983402721280_0_1518717642086",
      "indexedName": "night sky",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 4,
      "premiumMedia": [
        {
          "size": 6410370,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 82424,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1518717657000,
              "key": "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/thumbnails/00001-1280x720.jpg"
            }
          ],
          "bitrate": 2491479,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/HLS0400k",
                  "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/HLS1000k",
                  "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/HLS2000k",
                  "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/HLS3000k",
                  "5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 21,
            "jobId": "1518717645823-v1s2hx",
            "playlistUrl": "/api/post/5765983402721280_0_1518717642086/premium/video/Night---1099.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "1c370bbbec980060a9f50f406ad6098b",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1518717642086/premium/Night---1099.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 20.583333,
          "sequencer": "005A85CACB8BEB1E5D",
          "download": "/api/post/5765983402721280_0_1518717642086/media/download?key=5765983402721280_0_1518717642086/premium/Night---1099.mp4"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "see the night sk",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518717642086/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "text articl",
      "tags": [
        "classification-type",
        "category-text",
        "classification-essays",
        "category-health",
        "category-history",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 13,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518734205345,
      "created": 1518733909625,
      "id": "5765983402721280_0_1518733909624",
      "indexedName": "text articl",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 69,
      "premiumMedia": [],
      "premWrdCnt": 121,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti",
      "firstLeadWords": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1518733909624/thumbnail/image/thumbnail.jpg",
      "minCostPrice": 1
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 2,
      "name": "Lorem Ipsum Text Article",
      "tags": [
        "classification-essays",
        "category-history",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 43,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1520112076207,
      "created": 1519159114796,
      "id": "5765983402721280_0_1519159114795",
      "indexedName": "lorem ipsum text article",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 91,
      "premiumMedia": [],
      "premWrdCnt": 566,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
      "firstLeadWords": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
      "minCostPrice": 1,
      "thumbnailImage": "https://perplay-public-images.s3.amazonaws.com/channel/5765983402721280_0/profile/icon.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "Think Different",
      "tags": [
        "classification-instruction",
        "category-engineering",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 11,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519323454338,
      "created": 1519322875665,
      "id": "5765983402721280_0_1519322875664",
      "indexedName": "think different",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 4,
      "premiumMedia": [
        {
          "size": 1557640,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 320,
          "thumbnails": [],
          "bitrate": 391502,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1519322875664/premium/video/heineken-think-different.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1519322875664/premium/video/heineken-think-different.mp4/HLS0400k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1519322875664/premium/video/heineken-think-different.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 32,
            "jobId": "1519322878591-q9eoy9",
            "playlistUrl": "/api/post/5765983402721280_0_1519322875664/premium/video/heineken-think-different.mp4/playlist.m3u8"
          },
          "duration": 31.829,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1519322875664/premium/heineken-think-different.mp4",
          "mime": "video/mp4",
          "height": 240,
          "eTag": "8b2efabf68185d3033383da40e2f8f28",
          "sequencer": "005A8F06FD5E373D06",
          "statusCode": "OK",
          "download": "/api/post/5765983402721280_0_1519322875664/media/download?key=5765983402721280_0_1519322875664/premium/heineken-think-different.mp4"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Heineken think different prom",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1519322875664/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 5,
      "name": "Mining Platinum From the Road",
      "tags": [
        "classification-type",
        "category-video",
        "classification-instruction",
        "category-science",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 10,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519475418951,
      "created": 1519324112696,
      "id": "5765983402721280_0_1519324112693",
      "indexedName": "mining platinum from the road",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 5,
      "premiumMedia": [
        {
          "size": 89766938,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 17140,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1519324272000,
              "key": "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/thumbnails/00001-1280x720.jpg"
            },
            {
              "size": 43786,
              "fileName": "00002-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/thumbnails/00002-1280x720.jpg",
              "timestamp": 1519324272000,
              "key": "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/thumbnails/00002-1280x720.jpg"
            },
            {
              "size": 32990,
              "fileName": "00003-1280x720.jpg",
              "download": "/api/post/5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/thumbnails/00003-1280x720.jpg",
              "timestamp": 1519324272000,
              "key": "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/thumbnails/00003-1280x720.jpg"
            }
          ],
          "bitrate": 1134579,
          "transcoded": {
            "playlists": [
              {
                "name": "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/HLS0400k",
                  "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/HLS1000k",
                  "5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/HLS3000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 633,
            "jobId": "1519324129527-93hg2l",
            "playlistUrl": "/api/post/5765983402721280_0_1519324112693/premium/video/Mining-Platinum-From-the-Road-Part-One.mp4/playlist.m3u8"
          },
          "duration": 632.952733,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5765983402721280_0_1519324112693/premium/Mining-Platinum-From-the-Road-Part-One.mp4",
          "mime": "video/mp4",
          "height": 720,
          "eTag": "db53c02c9d2de49c1fa19f3e22722615",
          "sequencer": "005A8F0BD42DDE6433",
          "statusCode": "OK",
          "download": "/api/post/5765983402721280_0_1519324112693/media/download?key=5765983402721280_0_1519324112693/premium/Mining-Platinum-From-the-Road-Part-One.mp4"
        }
      ],
      "premWrdCnt": 7,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Mining Platinum From the Road ﻿",
      "firstLeadWords": "Mining Platinum From the Roa",
      "minCostPrice": 5,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5765983402721280_0_1519324112693/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5765983402721280_0",
      "userId": 5765983402721280,
      "price": 1,
      "name": "Florida students take on NRA, set eyes on midterm elections",
      "tags": [
        "classification-news",
        "category-national",
        "category-politics",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 16,
      "purchaseCount": 2,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519486328297,
      "created": 1519484621429,
      "leadContent": "",
      "id": "5765983402721280_0_1519484621428",
      "indexedName": "florida students take on nra, set eyes on midterm elections",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 79,
      "premiumMedia": [
        {
          "size": 168792,
          "mime": "image/jpeg",
          "bucketName": "perplay-media",
          "key": "5765983402721280_0_1519484621428/premium/gun-control-.jpeg",
          "eTag": "0ebe4551c7d2fcfb4ea6ea1afffc9d49",
          "sequencer": "005A91850A174258F5",
          "statusCode": "OK",
          "download": "/api/post/5765983402721280_0_1519484621428/media/download?key=5765983402721280_0_1519484621428/premium/gun-control-.jpeg"
        }
      ],
      "premWrdCnt": 810,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "(Reuters) - In the week since 17 of David Hogg’s classmates and teachers were gunned down in Florida,",
      "firstLeadWords": "(Reuters) - In the week since 17 of David Hogg’s classmates and teachers were gunned down in Florida,",
      "minCostPrice": 1,
      "thumbnailImage": "https://perplay-public-images.s3.amazonaws.com/channel/5765983402721280_0/profile/icon.jpg"
    },
    {
      "channelId": "5769457217568768_0",
      "userId": 5769457217568768,
      "price": 5,
      "name": "Episode 1:  The boston tea party. ",
      "tags": [
        "classification-movies",
        "category-history",
        "category-documentary",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 3196,
      "purchaseCount": 3,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1512162738210,
      "created": 1507976213166,
      "id": "5769457217568768_0_1507976213164",
      "indexedName": "episode 1:  the boston tea party. ",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 99021144,
          "formatName": "ogg",
          "width": 400,
          "height": 300,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/HLS0400k",
                  "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/HLS1000k",
                  "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1297,
            "jobId": "1507976231904-rbaig0",
            "playlistUrl": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/playlist.m3u8"
          },
          "duration": 1296.207528,
          "bucketName": "perplay-media",
          "formatLongName": "Ogg",
          "key": "5769457217568768_0_1507976213164/premium/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv",
          "mime": "video/ogg",
          "eTag": "a2b83fe4c0b0ccd5ed740105ac08c0e4",
          "sequencer": "0059E1E4182D8F7301",
          "thumbnails": [
            {
              "size": 2501,
              "fileName": "00001-400x300.jpg",
              "download": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00001-400x300.jpg",
              "timestamp": 1507976360000,
              "key": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00001-400x300.jpg"
            },
            {
              "size": 8402,
              "fileName": "00002-400x300.jpg",
              "download": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00002-400x300.jpg",
              "timestamp": 1507976360000,
              "key": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00002-400x300.jpg"
            },
            {
              "size": 8806,
              "fileName": "00003-400x300.jpg",
              "download": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00003-400x300.jpg",
              "timestamp": 1507976360000,
              "key": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00003-400x300.jpg"
            },
            {
              "size": 9089,
              "fileName": "00004-400x300.jpg",
              "download": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00004-400x300.jpg",
              "timestamp": 1507976360000,
              "key": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00004-400x300.jpg"
            },
            {
              "size": 5900,
              "fileName": "00005-400x300.jpg",
              "download": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00005-400x300.jpg",
              "timestamp": 1507976360000,
              "key": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00005-400x300.jpg"
            },
            {
              "size": 9915,
              "fileName": "00006-400x300.jpg",
              "download": "/api/post/5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00006-400x300.jpg",
              "timestamp": 1507976360000,
              "key": "5769457217568768_0_1507976213164/premium/video/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv/thumbnails/00006-400x300.jpg"
            }
          ],
          "download": "/api/post/5769457217568768_0_1507976213164/media/download?key=5769457217568768_0_1507976213164/premium/Liberty-s-Kids-01-The-Boston-Tea-Party.ogv"
        }
      ],
      "premWrdCnt": 2,
      "abuseCount": 0,
      "firstLeadWords": "test",
      "minCostPrice": 5,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5769457217568768_0_1507976213164/thumbnail/image/thumbnail.jpg",
      "minAge": 0
    },
    {
      "channelId": "5769457217568768_0",
      "userId": 5769457217568768,
      "price": 1,
      "name": "HD Test2",
      "tags": [],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 3968,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1508072118243,
      "created": 1508071775928,
      "id": "5769457217568768_0_1508071775926",
      "indexedName": "hd test2",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 725106140,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1920,
          "thumbnails": [
            {
              "size": 14964,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/thumbnails/00001-1280x720.jpg",
              "timestamp": 1508072115000,
              "key": "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/thumbnails/00001-1280x720.jpg"
            },
            {
              "size": 44181,
              "fileName": "00002-1280x720.jpg",
              "download": "/api/post/5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/thumbnails/00002-1280x720.jpg",
              "timestamp": 1508072115000,
              "key": "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/thumbnails/00002-1280x720.jpg"
            },
            {
              "size": 56165,
              "fileName": "00003-1280x720.jpg",
              "download": "/api/post/5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/thumbnails/00003-1280x720.jpg",
              "timestamp": 1508072115000,
              "key": "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/thumbnails/00003-1280x720.jpg"
            }
          ],
          "bitrate": 9725434,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/HLS0400k",
                  "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/HLS1000k",
                  "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/HLS2000k",
                  "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/HLS3000k",
                  "5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/HLS6000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 597,
            "jobId": "1508071902227-9dn1p1",
            "playlistUrl": "/api/post/5769457217568768_0_1508071775926/premium/video/big-buck-bunny-1080p-h264.mov/playlist.m3u8"
          },
          "mime": "video/quicktime",
          "eTag": "c23ab2ff12023c684f46fcc02c57b585",
          "formatLongName": "QuickTime / MOV",
          "key": "5769457217568768_0_1508071775926/premium/big-buck-bunny-1080p-h264.mov",
          "bucketName": "perplay-media",
          "height": 1080,
          "duration": 596.461667,
          "sequencer": "0059E359802679B406",
          "download": "/api/post/5769457217568768_0_1508071775926/media/download?key=5769457217568768_0_1508071775926/premium/big-buck-bunny-1080p-h264.mov"
        }
      ],
      "premWrdCnt": 1,
      "abuseCount": 0,
      "firstLeadWords": "tes",
      "minCostPrice": 30,
      "minAge": 0
    },
    {
      "channelId": "5769457217568768_0",
      "userId": 5769457217568768,
      "price": 4,
      "name": "Episode 2:  The intolerable",
      "tags": [
        "classification-learning",
        "category-history",
        "classification-movies",
        "category-documentary",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 81,
      "purchaseCount": 3,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1512162720987,
      "created": 1510521226074,
      "id": "5769457217568768_0_1510521226074",
      "indexedName": "episode 2:  the intolerable",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 99069583,
          "formatName": "ogg",
          "width": 400,
          "thumbnails": [
            {
              "size": 27602,
              "fileName": "00002-144x108.png",
              "download": "/api/post/5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00002-144x108.png",
              "timestamp": 1510521379000,
              "key": "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00002-144x108.png"
            },
            {
              "size": 29027,
              "fileName": "00003-144x108.png",
              "download": "/api/post/5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00003-144x108.png",
              "timestamp": 1510521379000,
              "key": "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00003-144x108.png"
            },
            {
              "size": 29236,
              "fileName": "00004-144x108.png",
              "download": "/api/post/5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00004-144x108.png",
              "timestamp": 1510521379000,
              "key": "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00004-144x108.png"
            },
            {
              "size": 28389,
              "fileName": "00005-144x108.png",
              "download": "/api/post/5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00005-144x108.png",
              "timestamp": 1510521379000,
              "key": "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/thumbnails/00005-144x108.png"
            }
          ],
          "bitrate": 611760,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/HLS0400k",
                  "5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/playlist.m3u8"
              }
            ],
            "state": "WARNING",
            "duration": 1296,
            "jobId": "1510521278775-rlikk3",
            "playlistUrl": "/api/post/5769457217568768_0_1510521226074/premium/video/Liberty-s-Kids-02-The-Intolerable-Act.ogv/playlist.m3u8"
          },
          "mime": "video/ogg",
          "eTag": "57beabc17e1ff9c94bec854200e902af",
          "formatLongName": "Ogg",
          "key": "5769457217568768_0_1510521226074/premium/Liberty-s-Kids-02-The-Intolerable-Act.ogv",
          "bucketName": "perplay-media",
          "height": 300,
          "duration": 1295.53415,
          "sequencer": "005A08B9B13978D9EA",
          "download": "/api/post/5769457217568768_0_1510521226074/media/download?key=5769457217568768_0_1510521226074/premium/Liberty-s-Kids-02-The-Intolerable-Act.ogv"
        }
      ],
      "premWrdCnt": 3,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "test content ",
      "firstLeadWords": "test Descriptio",
      "minCostPrice": 5,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5769457217568768_0_1510521226074/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5769457217568768_0",
      "userId": 5769457217568768,
      "price": 1,
      "name": "Episode 3: United we stand",
      "tags": [
        "classification-learning",
        "category-history",
        "classification-movies",
        "classification-series",
        "category-documentary",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 1,
      "likesCount": 0,
      "viewCount": 839,
      "purchaseCount": 13,
      "rating1star": 0,
      "rating2star": 1,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 2,
      "updated": 1515884650544,
      "created": 1510523225220,
      "id": "5769457217568768_0_1510523225219",
      "indexedName": "episode 3: united we stand",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 99054575,
          "formatName": "ogg",
          "width": 400,
          "thumbnails": [
            {
              "size": 26207,
              "fileName": "00002-144x108.png",
              "download": "/api/post/5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00002-144x108.png",
              "timestamp": 1510523344000,
              "key": "5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00002-144x108.png"
            },
            {
              "size": 23626,
              "fileName": "00003-144x108.png",
              "download": "/api/post/5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00003-144x108.png",
              "timestamp": 1510523344000,
              "key": "5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00003-144x108.png"
            },
            {
              "size": 26013,
              "fileName": "00004-144x108.png",
              "download": "/api/post/5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00004-144x108.png",
              "timestamp": 1510523344000,
              "key": "5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00004-144x108.png"
            },
            {
              "size": 23623,
              "fileName": "00005-144x108.png",
              "download": "/api/post/5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00005-144x108.png",
              "timestamp": 1510523344000,
              "key": "5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/thumbnails/00005-144x108.png"
            }
          ],
          "bitrate": 610890,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/HLS0400k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1298,
            "jobId": "1510523265922-i3e96o",
            "playlistUrl": "/api/post/5769457217568768_0_1510523225219/premium/video/Liberty-s-Kids-03-United-We-Stand.ogv/playlist.m3u8"
          },
          "mime": "video/ogg",
          "eTag": "7b9b72d553c85eef444614c954304bc2",
          "formatLongName": "Ogg",
          "key": "5769457217568768_0_1510523225219/premium/Liberty-s-Kids-03-United-We-Stand.ogv",
          "bucketName": "perplay-media",
          "height": 300,
          "duration": 1297.182766,
          "sequencer": "005A08C172E6207606",
          "download": "/api/post/5769457217568768_0_1510523225219/media/download?key=5769457217568768_0_1510523225219/premium/Liberty-s-Kids-03-United-We-Stand.ogv"
        }
      ],
      "premWrdCnt": 6,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Episode 3: United we stand ",
      "firstLeadWords": "Episode ",
      "minCostPrice": 5,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5769457217568768_0_1510523225219/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5769457217568768_1",
      "userId": 5769457217568768,
      "price": 1,
      "name": "Popeye For president",
      "tags": [
        "entertainment"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 31,
      "purchaseCount": 3,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1510529011495,
      "created": 1510524020029,
      "id": "5769457217568768_1_1510524020028",
      "indexedName": "popeye for president",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 179044352,
          "formatName": "mpeg",
          "width": 720,
          "thumbnails": [
            {
              "size": 6146,
              "fileName": "00001-640x480.jpg",
              "download": "/api/post/5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/thumbnails/00001-640x480.jpg",
              "timestamp": 1510524229000,
              "key": "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/thumbnails/00001-640x480.jpg"
            },
            {
              "size": 17794,
              "fileName": "00002-640x480.jpg",
              "download": "/api/post/5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/thumbnails/00002-640x480.jpg",
              "timestamp": 1510524229000,
              "key": "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/thumbnails/00002-640x480.jpg"
            }
          ],
          "bitrate": 3868886,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/HLS0400k",
                  "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/HLS1000k",
                  "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/HLS2000k",
                  "5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/HLS3000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/playlist.m3u8"
              }
            ],
            "state": "WARNING",
            "duration": 371,
            "jobId": "1510524144411-fw1mg5",
            "playlistUrl": "/api/post/5769457217568768_1_1510524020028/premium/video/Popeye-forPresident.mpeg/playlist.m3u8"
          },
          "mime": "video/mpeg",
          "eTag": "bd4c5f9641ed19cd3fda389c70a00eba",
          "formatLongName": "MPEG-PS (MPEG-2 Program Stream)",
          "key": "5769457217568768_1_1510524020028/premium/Popeye-forPresident.mpeg",
          "bucketName": "perplay-media",
          "height": 480,
          "duration": 370.224,
          "sequencer": "005A08C4D8386EAFB2",
          "download": "/api/post/5769457217568768_1_1510524020028/media/download?key=5769457217568768_1_1510524020028/premium/Popeye-forPresident.mpeg"
        }
      ],
      "premWrdCnt": 4,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Popeye For president ",
      "firstLeadWords": "Popeye For presiden",
      "minCostPrice": 8,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5769457217568768_1_1510524020028/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5769457217568768_1",
      "userId": 5769457217568768,
      "price": 5,
      "name": "Spree Lunch",
      "tags": [],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 218,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1511624602675,
      "created": 1510527733512,
      "id": "5769457217568768_1_1510527733511",
      "indexedName": "spree lunch",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 307081220,
          "formatName": "mpeg",
          "width": 720,
          "thumbnails": [
            {
              "size": 6344,
              "fileName": "00001-640x480.jpg",
              "download": "/api/post/5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/thumbnails/00001-640x480.jpg",
              "timestamp": 1510527942000,
              "key": "5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/thumbnails/00001-640x480.jpg"
            },
            {
              "size": 27559,
              "fileName": "00002-640x480.jpg",
              "download": "/api/post/5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/thumbnails/00002-640x480.jpg",
              "timestamp": 1510527942000,
              "key": "5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/thumbnails/00002-640x480.jpg"
            }
          ],
          "bitrate": 6762732,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/HLS0400k",
                  "5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/HLS1000k",
                  "5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 364,
            "jobId": "1510527855133-8xsj50",
            "playlistUrl": "/api/post/5769457217568768_1_1510527733511/premium/video/spree-lunch.mpeg/playlist.m3u8"
          },
          "mime": "video/mpeg",
          "eTag": "904af926fcf51a848c2551267a319f39",
          "formatLongName": "MPEG-PS (MPEG-2 Program Stream)",
          "key": "5769457217568768_1_1510527733511/premium/spree-lunch.mpeg",
          "bucketName": "perplay-media",
          "height": 480,
          "duration": 363.2629,
          "sequencer": "005A08D3440969A49B",
          "download": "/api/post/5769457217568768_1_1510527733511/media/download?key=5769457217568768_1_1510527733511/premium/spree-lunch.mpeg"
        }
      ],
      "premWrdCnt": 47,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Publication date  1957 \n\n Usage  Public Domain \n\n Topics  popeye ,  animation \n\n Publisher Paramount",
      "firstLeadWords": "Spree Lunc",
      "minCostPrice": 13,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5769457217568768_1_1510527733511/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5769457217568768_3",
      "userId": 5769457217568768,
      "price": 600,
      "name": "Best of MLS Skills 2017",
      "tags": [
        "classification-videoartists",
        "category-sports",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 12,
      "likesCount": 0,
      "viewCount": 1944,
      "purchaseCount": 4,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 1,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 3,
      "updated": 1519668813366,
      "created": 1513942496984,
      "id": "5769457217568768_3_1513942496983",
      "indexedName": "best of mls skills 2017",
      "state": "PUBLIC",
      "leadMedia": [
        {
          "size": 1063202,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "height": 360,
          "bitrate": 712661,
          "duration": 11.935,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5769457217568768_3_1513942496983/lead/villa-score.mp4",
          "eTag": "b2667156a5e634dbaa0785c9e0471754",
          "sequencer": "005A944E4CD8A260E0",
          "statusCode": "LEAD_TOO_LONG",
          "mime": "video/mp4",
          "download": "/api/post/5769457217568768_3_1513942496983/media/download?key=5769457217568768_3_1513942496983/lead/villa-score.mp4"
        }
      ],
      "leadWrdCnt": 9,
      "premiumMedia": [
        {
          "size": 36801186,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 62961,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1513942878000,
              "key": "5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/thumbnails/00001-1280x720.jpg"
            }
          ],
          "bitrate": 1784791,
          "transcoded": {
            "playlists": [
              {
                "name": "5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/HLS0400k",
                  "5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/HLS1000k",
                  "5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 166,
            "jobId": "1513942839536-cky7en",
            "playlistUrl": "/api/post/5769457217568768_3_1513942496983/premium/video/Best-Skills-of-2017-MLS.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "ab3e3b8f5daae78cdb0a909c36bb5aff",
          "formatLongName": "QuickTime / MOV",
          "key": "5769457217568768_3_1513942496983/premium/Best-Skills-of-2017-MLS.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 164.954556,
          "sequencer": "005A3CEE417AAFA367",
          "download": "/api/post/5769457217568768_3_1513942496983/media/download?key=5769457217568768_3_1513942496983/premium/Best-Skills-of-2017-MLS.mp4"
        }
      ],
      "premWrdCnt": 11,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "This is example content of this video. Only for testing.",
      "firstLeadWords": "Watch the best skills of 2017 MLS regular season",
      "minCostPrice": 3,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/5769457217568768_3_1513942496983/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Sample Audio Post",
      "tags": [
        "kind-audio",
        "classification-songs",
        "category-jazz"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 121,
      "purchaseCount": 14,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 1,
      "rating5star": 0,
      "ratingAvg": 4,
      "updated": 1514663205642,
      "created": 1506702555002,
      "leadContent": "",
      "premiumContent": "",
      "id": "5746721642512384_0_1506702555001",
      "indexedName": "sample audio post",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 725240,
          "formatName": "mp3",
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1506702555001/premium/audio/SampleAudio-0.7mb.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1506702555001/premium/audio/SampleAudio-0.7mb.mp3/HLSAudio160k",
                  "5746721642512384_0_1506702555001/premium/audio/SampleAudio-0.7mb.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1506702555001/premium/audio/SampleAudio-0.7mb.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 46,
            "jobId": "1506702559871-szmozz",
            "playlistUrl": "/api/post/5746721642512384_0_1506702555001/premium/audio/SampleAudio-0.7mb.mp3/playlist.m3u8"
          },
          "mime": "audio/mpeg",
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5746721642512384_0_1506702555001/premium/SampleAudio-0.7mb.mp3",
          "sequencer": "0059CE74DE5655305D",
          "eTag": "71e5cb4f2f80f10e6e6c1c071896b6f3",
          "duration": 45.326875,
          "download": "/api/post/5746721642512384_0_1506702555001/media/download?key=5746721642512384_0_1506702555001/premium/SampleAudio-0.7mb.mp3"
        }
      ],
      "premWrdCnt": 1,
      "abuseCount": 0,
      "firstLeadWords": "",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1506702555001/thumbnail/image/thumbnail.jpg",
      "minAge": 0,
      "minCostPrice": 1
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 2,
      "name": "Audi's Driver Assist Feature",
      "tags": [
        "classification-publicity",
        "category-autos",
        "category-technology",
        "classification-news",
        "category-businessfinance",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 1590,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519065559633,
      "created": 1510000534971,
      "id": "5746721642512384_0_1510000534970",
      "indexedName": "audi's driver assist feature",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 37,
      "premiumMedia": [
        {
          "size": 26875036,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 14964,
              "fileName": "00001-1280x720.jpg",
              "download": "/api/post/5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/thumbnails/00001-1280x720.jpg",
              "timestamp": 1510000602000,
              "key": "5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/thumbnails/00001-1280x720.jpg"
            }
          ],
          "bitrate": 1851934,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/HLS0400k",
                  "5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/HLS1000k",
                  "5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/playlist.m3u8"
              }
            ],
            "state": "WARNING",
            "duration": 117,
            "jobId": "1510000570430-d3ksof",
            "playlistUrl": "/api/post/5746721642512384_0_1510000534970/premium/video/audi-traffic-assist.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "2b16ee5535c6a539f01aaade865166bd",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_0_1510000534970/premium/audi-traffic-assist.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 116.095,
          "sequencer": "005A00C7AC7A896A52",
          "download": "/api/post/5746721642512384_0_1510000534970/media/download?key=5746721642512384_0_1510000534970/premium/audi-traffic-assist.mp4"
        }
      ],
      "premWrdCnt": 92,
      "currency": "PROD",
      "abuseCount": 0,
      "firstLeadWords": "Audi assists drivers with a broad range of assistance systems – from turn and parking assistants to the",
      "minCostPrice": 0,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1510000534970/thumbnail/image/thumbnail.png",
      "firstPremWords": "Audi pre sense basic initiates preventive safety measures for occupants as soon as it recognizes a critical",
      "minAge": 0
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Test",
      "tags": [
        "classification-interviews",
        "category-health",
        "classification-opinionscommentary",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 112,
      "purchaseCount": 6,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1517793252772,
      "created": 1511457114987,
      "leadContent": "",
      "id": "5746721642512384_0_1511457114986",
      "indexedName": "test",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 10498677,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 53644,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1511457146000,
              "key": "5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 1347820,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/HLS0400k",
                  "5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 63,
            "jobId": "1511457131128-ltjxum",
            "playlistUrl": "/api/post/5746721642512384_0_1511457114986/premium/video/SampleVideo-1280x720-10mb.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "798ce2689035bc7ed07c1f9bf75f754c",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_0_1511457114986/premium/SampleVideo-1280x720-10mb.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 62.315,
          "sequencer": "005A1701632CF37F51",
          "download": "/api/post/5746721642512384_0_1511457114986/media/download?key=5746721642512384_0_1511457114986/premium/SampleVideo-1280x720-10mb.mp4"
        }
      ],
      "premWrdCnt": 2,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 13,
      "firstPremWords": "test ",
      "firstLeadWords": "",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1511457114986/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Test Audio Jazz",
      "tags": [
        "classification-music",
        "category-jazz",
        "kind-audio"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 51,
      "purchaseCount": 9,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1517094651650,
      "created": 1517094506903,
      "premiumContent": "",
      "id": "5746721642512384_0_1517094506902",
      "indexedName": "test audio jazz",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 3,
      "premiumMedia": [
        {
          "size": 1422314,
          "formatName": "mp3",
          "bitrate": 64009,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1517094506902/premium/audio/2to2-64kb.mp3/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1517094506902/premium/audio/2to2-64kb.mp3/HLSAudio160k",
                  "5746721642512384_0_1517094506902/premium/audio/2to2-64kb.mp3/HLSAudio064k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1517094506902/premium/audio/2to2-64kb.mp3/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 178,
            "jobId": "1517094515489-gdd01t",
            "playlistUrl": "/api/post/5746721642512384_0_1517094506902/premium/audio/2to2-64kb.mp3/playlist.m3u8"
          },
          "duration": 177.763265,
          "bucketName": "perplay-media",
          "formatLongName": "MP2/3 (MPEG audio layer 2/3)",
          "key": "5746721642512384_0_1517094506902/premium/2to2-64kb.mp3",
          "mime": "audio/mpeg",
          "eTag": "4e6efb7a58b4a2fe4260abaa2494edb3",
          "sequencer": "005A6D0672142B8DD2",
          "download": "/api/post/5746721642512384_0_1517094506902/media/download?key=5746721642512384_0_1517094506902/premium/2to2-64kb.mp3"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "Jazz music cli",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1517094506902/thumbnail/image/thumbnail.jpg"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 99,
      "name": "Here's a test article",
      "tags": [
        "classification-opinioncommentary",
        "category-government",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 130,
      "purchaseCount": 1,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 1,
      "ratingAvg": 5,
      "updated": 1520703071674,
      "created": 1518630833960,
      "id": "5746721642512384_0_1518630833958",
      "indexedName": "here's a test article",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 8,
      "premiumMedia": [],
      "premWrdCnt": 4,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Test content here ",
      "firstLeadWords": "The description of the article is here. findteststrin",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1518630833958/thumbnail/image/thumbnail.jpg",
      "minCostPrice": 1
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 10,
      "name": "Turn Article to Video",
      "tags": [
        "classification-series",
        "category-art",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 60,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519247908647,
      "created": 1519240739923,
      "id": "5746721642512384_0_1519240739922",
      "indexedName": "turn article to video",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 908766,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 596,
          "thumbnails": [
            {
              "size": 44337,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1519240765000,
              "key": "5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 951462,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS0400k",
                  "5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 8,
            "jobId": "1519240758812-nde87p",
            "playlistUrl": "/api/post/5746721642512384_0_1519240739922/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
          },
          "duration": 7.641,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_0_1519240739922/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
          "mime": "video/mp4",
          "height": 336,
          "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
          "sequencer": "005A8DC6362E91F25A",
          "statusCode": "OK",
          "download": "/api/post/5746721642512384_0_1519240739922/media/download?key=5746721642512384_0_1519240739922/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4"
        }
      ],
      "premWrdCnt": 3,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 13,
      "firstPremWords": "some content ",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1519240739922/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Title for Article",
      "tags": [
        "classification-essays",
        "category-environment",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 36,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519262059155,
      "created": 1519260929254,
      "id": "5746721642512384_0_1519260929253",
      "indexedName": "title for article",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [],
      "premWrdCnt": 2,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "content ",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 2,
      "name": "Fashion Post Test",
      "tags": [
        "classification-news",
        "category-fashion",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 655,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519316105596,
      "created": 1519315253437,
      "id": "5746721642512384_0_1519315253435",
      "indexedName": "fashion post test",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 908766,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 596,
          "thumbnails": [
            {
              "size": 44337,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1519315263000,
              "key": "5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 951462,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS0400k",
                  "5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 8,
            "jobId": "1519315256601-0p5uce",
            "playlistUrl": "/api/post/5746721642512384_0_1519315253435/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
          },
          "duration": 7.641,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_0_1519315253435/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
          "mime": "video/mp4",
          "height": 336,
          "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
          "sequencer": "005A8EE9380024849D",
          "statusCode": "OK",
          "download": "/api/post/5746721642512384_0_1519315253435/media/download?key=5746721642512384_0_1519315253435/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4"
        }
      ],
      "premWrdCnt": 6,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "content seems to be working",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1519315253435/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Test Post to check Pricing and Featured",
      "tags": [
        "classification-instruction",
        "category-craftshobbies",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 21,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519408888154,
      "created": 1519408668150,
      "id": "5746721642512384_0_1519408668150",
      "indexedName": "test post to check pricing and featured",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 908766,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 596,
          "thumbnails": [
            {
              "size": 44337,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1519408678000,
              "key": "5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 951462,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS0400k",
                  "5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 8,
            "jobId": "1519408671953-527yds",
            "playlistUrl": "/api/post/5746721642512384_0_1519408668150/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
          },
          "duration": 7.641,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_0_1519408668150/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
          "mime": "video/mp4",
          "height": 336,
          "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
          "sequencer": "005A90561F466899F9",
          "statusCode": "OK",
          "download": "/api/post/5746721642512384_0_1519408668150/media/download?key=5746721642512384_0_1519408668150/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4"
        }
      ],
      "premWrdCnt": 2,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "content",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1519408668150/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Video without content",
      "tags": [
        "classification-interviews",
        "category-athletes",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 33,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519429902849,
      "created": 1519429378909,
      "premiumContent": "",
      "id": "5746721642512384_0_1519429378908",
      "indexedName": "video without content",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [
        {
          "size": 908766,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 596,
          "thumbnails": [
            {
              "size": 44337,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1519429388000,
              "key": "5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 951462,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS0400k",
                  "5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 8,
            "jobId": "1519429382174-wri3uq",
            "playlistUrl": "/api/post/5746721642512384_0_1519429378908/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8"
          },
          "duration": 7.641,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_0_1519429378908/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
          "mime": "video/mp4",
          "height": 336,
          "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
          "sequencer": "005A90A704C8EA343C",
          "statusCode": "OK",
          "download": "/api/post/5746721642512384_0_1519429378908/media/download?key=5746721642512384_0_1519429378908/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4"
        }
      ],
      "premWrdCnt": 1,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1519429378908/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 2,
      "name": "Article without media",
      "tags": [
        "classification-essays",
        "category-culture",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 11,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519430389574,
      "created": 1519430068037,
      "id": "5746721642512384_0_1519430068036",
      "indexedName": "article without media",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [],
      "premWrdCnt": 3,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "added content\t",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1
    },
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 3,
      "name": "Article with Thumbnail",
      "tags": [
        "classification-essays",
        "category-culture",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 99,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519579314190,
      "created": 1519523801565,
      "id": "5746721642512384_0_1519523801565",
      "indexedName": "article with thumbnail",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [],
      "premWrdCnt": 4,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Here's some content",
      "firstLeadWords": "findteststrin",
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_0_1519523801565/thumbnail/image/thumbnail.jpg",
      "minCostPrice": 1
    },
    /*
    {
      "channelId": "5746721642512384_0",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Shakespeare Long Content",
      "tags": [
        "classification-essays",
        "category-fiction",
        "kind-text"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 39,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1519859707637,
      "created": 1519859056376,
      "id": "5746721642512384_0_1519859056374",
      "indexedName": "shakespeare long content",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 1,
      "premiumMedia": [],
      "premWrdCnt": 32010,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "ACT I SCENE I. Elsinore. A platform before the castle. FRANCISCO at his post. Enter to him BERNARDO BERNARDO",
      "firstLeadWords": "findteststrin",
      "minCostPrice": 1
    },
    */
    {
      "channelId": "5746721642512384_1",
      "userId": 5746721642512384,
      "price": 1,
      "name": "Cigar Making is an Art (poor quality media)",
      "tags": [],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 229,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1510246231819,
      "created": 1509822951193,
      "id": "5746721642512384_1_1509822951192",
      "indexedName": "cigar making is an art (poor quality media)",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 7,
      "premiumMedia": [
        {
          "size": 908766,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 596,
          "thumbnails": [
            {
              "size": 44337,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_1_1509822951192/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1509830927000,
              "key": "5746721642512384_1_1509822951192/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 951462,
          "transcoded": {
            "playlists": [
              {
                "statusDetail": "1001 835661d7-04a6-47d3-8221-ea86f4fdc97f: Amazon Elastic Transcoder could not generate the playlist because it encountered an error with one or more of the playlist's dependencies.",
                "name": "5746721642512384_1_1509822951192/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
                "status": "Error",
                "outputKeys": [
                  "5746721642512384_1_1509822951192/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS0400k",
                  "5746721642512384_1_1509822951192/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/HLS1000k"
                ],
                "format": "HLSv3"
              }
            ],
            "state": "ERROR",
            "duration": null,
            "jobId": "1509994936451-00tk0o"
          },
          "mime": "video/mp4",
          "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_1_1509822951192/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
          "bucketName": "perplay-media",
          "height": 336,
          "duration": 7.641,
          "sequencer": "0059FE31069479B560",
          "download": "/api/post/5746721642512384_1_1509822951192/media/download?key=5746721642512384_1_1509822951192/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4"
        }
      ],
      "premWrdCnt": 112,
      "currency": "PROD",
      "abuseCount": 0,
      "firstLeadWords": "This woman shows how cigars are made",
      "minCostPrice": 1,
      "minAge": 0
    },
    {
      "channelId": "5746721642512384_1",
      "userId": 5746721642512384,
      "price": 2,
      "name": "Amazing Animation",
      "tags": [
        "kind-video",
        "classification-movies",
        "category-art"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 1,
      "likesCount": 0,
      "viewCount": 274,
      "purchaseCount": 7,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518290035015,
      "created": 1510341860611,
      "location": {
        "type": "Point",
        "coordinates": [
          -118.26112747192383,
          34.040569396471156
        ]
      },
      "id": "5746721642512384_1_1510341860610",
      "indexedName": "amazing animation",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 7,
      "premiumMedia": [
        {
          "size": 31491130,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 1280,
          "thumbnails": [
            {
              "size": 53644,
              "fileName": "00001-192x108.png",
              "download": "/api/post/5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/thumbnails/00001-192x108.png",
              "timestamp": 1510341920000,
              "key": "5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/thumbnails/00001-192x108.png"
            }
          ],
          "bitrate": 1474485,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/HLS0400k",
                  "5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 171,
            "jobId": "1510341894823-i9w82u",
            "playlistUrl": "/api/post/5746721642512384_1_1510341860610/premium/video/SampleVideo-1280x720-30mb.mp4/playlist.m3u8"
          },
          "mime": "video/mp4",
          "eTag": "545e11bab5aa685bb6f8f5092be87199",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_1_1510341860610/premium/SampleVideo-1280x720-30mb.mp4",
          "bucketName": "perplay-media",
          "height": 720,
          "duration": 170.859,
          "sequencer": "005A05FCEFE93CC1A0",
          "download": "/api/post/5746721642512384_1_1510341860610/media/download?key=5746721642512384_1_1510341860610/premium/SampleVideo-1280x720-30mb.mp4"
        }
      ],
      "premWrdCnt": 105,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstLeadWords": "Animation has become quite complex and lifelike",
      "minCostPrice": 2,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_1_1510341860610/thumbnail/image/thumbnail.png",
      "latitude": 34.040569396471156,
      "longitude": -118.26112747192383,
      "address": {
        "countryName": "United States",
        "countryCode": "US",
        "city": "Los Angeles",
        "latitude": 34.040326,
        "longitude": -118.260911,
        "region": "California",
        "postalCode": "90015"
      }
    },
    {
      "channelId": "5746721642512384_1",
      "userId": 5746721642512384,
      "price": 13,
      "name": "Big Chaplin",
      "tags": [
        "classification-movies",
        "category-comedy",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 860,
      "purchaseCount": 0,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1518981993531,
      "created": 1517004314269,
      "id": "5746721642512384_1_1517004314268",
      "indexedName": "big chaplin",
      "state": "PUBLIC",
      "leadMedia": [],
      "leadWrdCnt": 2,
      "premiumMedia": [
        {
          "size": 206403588,
          "formatName": "mpeg",
          "width": 352,
          "thumbnails": [
            {
              "size": 11371,
              "fileName": "00001-144x108.png",
              "download": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00001-144x108.png",
              "timestamp": 1517004654000,
              "key": "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00001-144x108.png"
            },
            {
              "size": 20119,
              "fileName": "00002-144x108.png",
              "download": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00002-144x108.png",
              "timestamp": 1517004654000,
              "key": "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00002-144x108.png"
            },
            {
              "size": 15482,
              "fileName": "00003-144x108.png",
              "download": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00003-144x108.png",
              "timestamp": 1517004654000,
              "key": "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00003-144x108.png"
            },
            {
              "size": 22358,
              "fileName": "00004-144x108.png",
              "download": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00004-144x108.png",
              "timestamp": 1517004654000,
              "key": "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00004-144x108.png"
            },
            {
              "size": 20134,
              "fileName": "00005-144x108.png",
              "download": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00005-144x108.png",
              "timestamp": 1517004654000,
              "key": "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/thumbnails/00005-144x108.png"
            }
          ],
          "bitrate": 1363512,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/HLS0400k",
                  "5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 1212,
            "jobId": "1517004560980-2h9d7h",
            "playlistUrl": "/api/post/5746721642512384_1_1517004314268/premium/video/CC-1916-09-04-TheCount.mpg/playlist.m3u8"
          },
          "mime": "video/mpeg",
          "eTag": "fc4c9ce932b8964d86fbfac424b9bd46",
          "formatLongName": "MPEG-PS (MPEG-2 Program Stream)",
          "key": "5746721642512384_1_1517004314268/premium/CC-1916-09-04-TheCount.mpg",
          "bucketName": "perplay-media",
          "height": 240,
          "duration": 1211.010611,
          "sequencer": "005A6BA680C18740DA",
          "download": "/api/post/5746721642512384_1_1517004314268/media/download?key=5746721642512384_1_1517004314268/premium/CC-1916-09-04-TheCount.mpg"
        }
      ],
      "premWrdCnt": 3,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "test upload ",
      "firstLeadWords": "test findteststrin",
      "minCostPrice": 9,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_1_1517004314268/thumbnail/image/thumbnail.png"
    },
    {
      "channelId": "5746721642512384_1",
      "userId": 5746721642512384,
      "price": 16,
      "name": "Democracy Now",
      "tags": [
        "classification-type",
        "category-video",
        "classification-interviews",
        "category-personalities",
        "classification-news",
        "category-politics",
        "kind-video"
      ],
      "featured": 0,
      "publicPost": true,
      "commentsCount": 0,
      "likesCount": 0,
      "viewCount": 69,
      "purchaseCount": 24,
      "rating1star": 0,
      "rating2star": 0,
      "rating3star": 0,
      "rating4star": 0,
      "rating5star": 0,
      "ratingAvg": 0,
      "updated": 1520883063171,
      "created": 1520739198674,
      "id": "5746721642512384_1_1520739198673",
      "indexedName": "democracy now",
      "state": "PUBLIC",
      "leadMedia": [
        {
          "size": 3892245,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 960,
          "thumbnails": [
            {
              "size": 8724,
              "fileName": "00001-960x540.jpg",
              "download": "/api/post/5746721642512384_1_1520739198673/lead/video/DN.mp4/thumbnails/00001-960x540.jpg",
              "timestamp": 1520740907000,
              "key": "5746721642512384_1_1520739198673/lead/video/DN.mp4/thumbnails/00001-960x540.jpg"
            }
          ],
          "bitrate": 2219384,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_1_1520739198673/lead/video/DN.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_1_1520739198673/lead/video/DN.mp4/HLS0400k",
                  "5746721642512384_1_1520739198673/lead/video/DN.mp4/HLS1000k",
                  "5746721642512384_1_1520739198673/lead/video/DN.mp4/HLS2000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_1_1520739198673/lead/video/DN.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 15,
            "jobId": "1520740899373-dsxv5p",
            "playlistUrl": "/api/post/5746721642512384_1_1520739198673/lead/video/DN.mp4/playlist.m3u8"
          },
          "duration": 14.03,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_1_1520739198673/lead/DN.mp4",
          "mime": "video/mp4",
          "height": 540,
          "eTag": "6dcac472d35f63670c05f4a35dc0c3d2",
          "sequencer": "005AA4AA22889C429A",
          "statusCode": "OK",
          "download": "/api/post/5746721642512384_1_1520739198673/media/download?key=5746721642512384_1_1520739198673/lead/DN.mp4"
        }
      ],
      "leadWrdCnt": 5,
      "premiumMedia": [
        {
          "size": 368136760,
          "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
          "width": 640,
          "thumbnails": [
            {
              "size": 50315,
              "fileName": "00002-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00002-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00002-192x108.png"
            },
            {
              "size": 53837,
              "fileName": "00003-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00003-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00003-192x108.png"
            },
            {
              "size": 41422,
              "fileName": "00004-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00004-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00004-192x108.png"
            },
            {
              "size": 42651,
              "fileName": "00005-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00005-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00005-192x108.png"
            },
            {
              "size": 35529,
              "fileName": "00006-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00006-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00006-192x108.png"
            },
            {
              "size": 40758,
              "fileName": "00007-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00007-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00007-192x108.png"
            },
            {
              "size": 42180,
              "fileName": "00008-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00008-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00008-192x108.png"
            },
            {
              "size": 42564,
              "fileName": "00009-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00009-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00009-192x108.png"
            },
            {
              "size": 41946,
              "fileName": "00010-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00010-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00010-192x108.png"
            },
            {
              "size": 36058,
              "fileName": "00011-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00011-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00011-192x108.png"
            },
            {
              "size": 39644,
              "fileName": "00012-192x108.png",
              "download": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00012-192x108.png",
              "timestamp": 1520739786000,
              "key": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/thumbnails/00012-192x108.png"
            }
          ],
          "bitrate": 831458,
          "transcoded": {
            "playlists": [
              {
                "name": "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/playlist",
                "status": "Complete",
                "outputKeys": [
                  "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/HLS0400k",
                  "5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/HLS1000k"
                ],
                "format": "HLSv3",
                "playlistUrl": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/playlist.m3u8"
              }
            ],
            "state": "COMPLETED",
            "duration": 3543,
            "jobId": "1520739468655-z9v43s",
            "playlistUrl": "/api/post/5746721642512384_1_1520739198673/premium/video/dn2018-0302-vid.mp4/playlist.m3u8"
          },
          "duration": 3542.082,
          "bucketName": "perplay-media",
          "formatLongName": "QuickTime / MOV",
          "key": "5746721642512384_1_1520739198673/premium/dn2018-0302-vid.mp4",
          "mime": "video/mp4",
          "height": 360,
          "eTag": "873e1692cd906439d9bc6386e2367b8b",
          "sequencer": "005AA4A3870A94A6C7",
          "statusCode": "OK",
          "download": "/api/post/5746721642512384_1_1520739198673/media/download?key=5746721642512384_1_1520739198673/premium/dn2018-0302-vid.mp4"
        }
      ],
      "premWrdCnt": 4,
      "currency": "PROD",
      "abuseCount": 0,
      "minAge": 0,
      "firstPremWords": "Democracy Now episode",
      "firstLeadWords": "An episode of Democracy Now",
      "minCostPrice": 16,
      "thumbnailImage": "https://s3.amazonaws.com/perplay-media/6453567359549440_1_1520739198673/thumbnail/image/thumbnail.png"
    }
  ],
  "flagged": [
    {
      "actionTaken": 0,
      "state": "NEW",
      "id": "6453567359549440_0_1519248024484_5748119283171328",
      "postId": "6453567359549440_0_1519248024484",
      "userId": 5748119283171328,
      "reason": 1000,
      "comments": "This post is broken, old data.  should be removed.  (karl)",
      "channelId": "6453567359549440_0",
      "updated": 1520190860463,
      "created": 1520190860463
    }
  ],
  "countries_dict": {
    "AD": "AndorrA",
    "AF": "Afghanistan",
    "AL": "Albania",
    "AS": "American Samoa",
    "AX": "Åland Islands",
    "DZ": "Algeria",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Democratic Republic",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D'Ivoire",
    "HR": "Croatia",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KR": "Korea (South)",
    "XK": "Kosovo",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Laos",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RW": "Rwanda",
    "SH": "Saint Helena",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "ME": "Montenegro",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "country_states": {
    "US": [
      "Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware",
      "District of Columbia","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas",
      "Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi",
      "Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York",
      "North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island",
      "South Carolina","South Dakota","Tennessee","Texas","Utah","Vermont","Virginia","Washington",
      "West Virginia","Wisconsin","Wyoming"
    ]
  },
  "excluded": [ // keys excluded from database content log
    "error", "countries_dict", "country_states", "premium_media"
  ],
  "error": {
    "errors" : {
      "404": {"code": "DOES_NOT_EXIST", "error": null, "message": "Does not exist", "status": 404}
    },
    "statusText" : {
      "404": "Not Found"
    }
  },
  "premium_media" : {
    "bitrate": 951462,
    "bucketName": "perplay-media",
    "download": "/api/post/6453567359549440_2_1522610523855/media/download?key=6453567359549440_2_1522610523855/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
    "duration": 7.641,
    "eTag": "a19a5a28440de2f67b0f63bdd3bf2652",
    "formatLongName": "QuickTime / MOV",
    "formatName": "mov,mp4,m4a,3gp,3g2,mj2",
    "height": 336,
    "key": "6453567359549440_2_1522610523855/premium/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4",
    "mime": "video/mp4",
    "sequencer": "005AC1315E5C011E53",
    "size": 908766,
    "statusCode": "OK",
    "thumbnails": [{
    "download": "/api/post/5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/thumbnails/00001-192x108.png",
    "fileName": "00001-192x108.png",
    "key": "5758048710688768_0_1519583972665/premium/video/Why-Poor-Places-Are-More-Diverse.mp4/thumbnails/00001-192x108.png",
    "size": 8052,
    "timestamp": 1519584004000
    }],
    "transcoded": {
    "duration": 8,
    "jobId": "1522610526774-p71uyn",
    "playlistUrl": "/api/post/6453567359549440_2_1522610523855/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8",
    "playlists": [{
      "format": "HLSv3",
      "name": "6453567359549440_2_1522610523855/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist",
      "outputKeys": ["6453567359549440_2_1522610523855/premium/video/sto…ng-a-cigar-cigar-production-handmade.mp4/HLS0400k",
        "6453567359549440_2_1522610523855/premium/video/sto…ng-a-cigar-cigar-production-handmade.mp4/HLS1000k"],
      "playlistUrl ": "/api/post/6453567359549440_2_1522610523855/premium/video/stock-footage-close-up-of-woman-s-hands-manufacturing-a-cigar-cigar-production-handmade.mp4/playlist.m3u8",
      "status": "Complete"
    }],
    "state": "COMPLETED"
    },
    "width": 596
  },
  "payouts" : [
    {
      "id":"Payout_5748234360193024_1537343631979",
      "userId":5746721642512384,
      "destType":1,
      "bank": {
        "userId":5746721642512384,
        "created":1534510406785,
        "name":"Sabadell",
        "transferMethod":1,
        "routingNumber":"123456789",
        "accountNumber":"172839399997",
        "accountHolder":"Jose Gonzalez",
        "id":"Bank_5748234360193024_1534510406785",
        "updated":1534510406785
      },
      "status":1,
      "currency":"PROD",
      "amountReq":249,
      "fee":200,
      "amountSent":49,
      "reqIpAddress":"83.39.80.89",
      "updated":1540934963868,
      "created":1537343631979,
      "staffNotes":""
    },
    {
      "id":"Payout_5748234360193024_1534179700332",
      "userId":5746721642512384,
      "destType":1,
      "bank": {
        "userId":5746721642512384,
        "created":1532085766188,
        "id":"Bank_5748234360193024_1532085766188",
        "updated":1532085766188
      },
      "status":1,"currency":"PROD",
      "amountReq":205,
      "fee":200,
      "amountSent":5,
      "reqIpAddress":"83.41.2.239","updated":1534179700332,
      "created":1534179700332
    }
  ],
  "comments" : [
    {
      "id": "5765983402721280_0_1540660304208_5748234360193024_1540804234425",
      "postId": "5765983402721280_0_1540660304208",
      "channelId": "5765983402721280_0",
      "userId": 5746721642512384,
      "comments": "Great hamadryas baboons",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 2,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1540804234425,
      "created": 1540804234425
    },
    {
      "id": "5748234360193024_1_1534180517398_5748234360193024_1534180541174",
      "postId": "5748234360193024_1_1534180517398",
      "channelId": "5748234360193024_1",
      "userId": 5746721642512384,
      "comments": "My comment",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 1,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180541175,
      "created": 1534180541175
    },
    {
      "id": "5758048710688768_7_1525092095846_5748234360193024_1534180773750",
      "postId": "5758048710688768_7_1525092095846",
      "parentId": "5758048710688768_7_1525092095846_5748234360193024_1534180729981",
      "channelId": "5758048710688768_7",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180773751,
      "created": 1534180773751
    },
    {
      "id": "5748234360193024_1_1535703448679_5748234360193024_1535704904985",
      "postId": "5748234360193024_1_1535703448679",
      "parentId": "5748234360193024_1_1535703448679_5748234360193024_1535704892586",
      "channelId": "5748234360193024_1",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1535704904986,
      "created": 1535704904986
    },
    {
      "id": "5765983402721280_0_1540660304208_5748234360193024_1540804344274",
      "postId": "5765983402721280_0_1540660304208",
      "parentId": "5765983402721280_0_1540660304208_5748234360193024_1540804234425",
      "channelId": "5765983402721280_0",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1540804344275,
      "created": 1540804344275
    },
    {
      "id": "5758048710688768_1_1518207745476_5748234360193024_1533282266678",
      "postId": "5758048710688768_1_1518207745476",
      "channelId": "5758048710688768_1",
      "userId": 5746721642512384,
      "comments": "Great movie!",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 2,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1533282266680,
      "created": 1533282266680
    },
    {
      "id": "5748234360193024_1_1531837922253_5748234360193024_1534444457046",
      "postId": "5748234360193024_1_1531837922253",
      "parentId": "5748234360193024_1_1531837922253_5748234360193024_1534439452095",
      "channelId": "5748234360193024_1",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534444457048,
      "created": 1534444457048
    },
    {
      "id": "5748234360193024_1_1535703448679_5748234360193024_1535704892586",
      "postId": "5748234360193024_1_1535703448679",
      "channelId": "5748234360193024_1",
      "userId": 5746721642512384,
      "comments": "Comment",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 1,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1535704892587,
      "created": 1535704892587
    },
    {
      "id": "5758048710688768_1_1518207745476_5748234360193024_1533282287358",
      "postId": "5758048710688768_1_1518207745476",
      "parentId": "5758048710688768_1_1518207745476_5748234360193024_1533282266678",
      "channelId": "5758048710688768_1",
      "userId": 5746721642512384,
      "comments": "2nd comment",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1533282287359,
      "created": 1533282287359
    },
    {
      "id": "5758048710688768_7_1525092095846_5748234360193024_1534180765481",
      "postId": "5758048710688768_7_1525092095846",
      "parentId": "5758048710688768_7_1525092095846_5758048710688768_1529828097460",
      "channelId": "5758048710688768_7",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180765482,
      "created": 1534180765482
    },
    {
      "id": "5758048710688768_1_1518207745476_5748234360193024_1533282274379",
      "postId": "5758048710688768_1_1518207745476",
      "parentId": "5758048710688768_1_1518207745476_5748234360193024_1533282266678",
      "channelId": "5758048710688768_1",
      "userId": 5746721642512384,
      "comments": "comment",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1533282274380,
      "created": 1533282274380
    },
    {
      "id": "5748234360193024_1_1531837922253_5748234360193024_1534439452095",
      "postId": "5748234360193024_1_1531837922253",
      "channelId": "5748234360193024_1",
      "userId": 5746721642512384,
      "comments": "comment",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 2,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534439452099,
      "created": 1534439452099
    },
    {
      "id": "5758048710688768_7_1525092095846_5748234360193024_1534180840890",
      "postId": "5758048710688768_7_1525092095846",
      "parentId": "5758048710688768_7_1525092095846_5758048710688768_1529828097460",
      "channelId": "5758048710688768_7",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180840890,
      "created": 1534180840890
    },
    {
      "id": "5758048710688768_7_1525092095846_5748234360193024_1534180824338",
      "postId": "5758048710688768_7_1525092095846",
      "parentId": "5758048710688768_7_1525092095846_5748234360193024_1534180729981",
      "channelId": "5758048710688768_7",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180824339,
      "created": 1534180824339
    },
    {
      "id": "5758048710688768_7_1525092095846_5748234360193024_1534180651695",
      "postId": "5758048710688768_7_1525092095846",
      "parentId": "5758048710688768_7_1525092095846_5758048710688768_1529828097460",
      "channelId": "5758048710688768_7",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180651696,
      "created": 1534180651696
    },
    {
      "id": "5748234360193024_1_1540225573500_5748234360193024_1540814235020",
      "postId": "5748234360193024_1_1540225573500",
      "channelId": "5748234360193024_1",
      "userId": 5746721642512384,
      "comments": "Comment",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1540814235020,
      "created": 1540814235020
    },
    {
      "id": "5758048710688768_7_1525092095846_5748234360193024_1534180757983",
      "postId": "5758048710688768_7_1525092095846",
      "parentId": "5758048710688768_7_1525092095846_5758048710688768_1529828097460",
      "channelId": "5758048710688768_7",
      "userId": 5746721642512384,
      "comments": "reply",
      "likes": 0,
      "dislikes": 0,
      "abuse": 0,
      "childComments": 0,
      "childLikes": 0,
      "childDislikes": 0,
      "updated": 1534180757984,
      "created": 1534180757984
    }
  ],
  "ratings" : [
    {
      "id": "5758048710688768_1_1533539615404_5746721642512384",
      "postId": "5758048710688768_1_1533539615404",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5758048710688768_1",
      "created": 1534179620199,
      "updated": 1534179651493
    },
    {
      "id": "5758048710688768_7_1525092095846_5746721642512384",
      "postId": "5758048710688768_7_1525092095846",
      "userId": 5746721642512384,
      "rating": 4,
      "channelId": "5758048710688768_7",
      "updated": 1531843497631,
      "created": 1531843497631
    },
    {
      "id": "5758048710688768_5_1532965796491_5746721642512384",
      "postId": "5758048710688768_5_1532965796491",
      "userId": 5746721642512384,
      "rating": 4,
      "channelId": "5758048710688768_5",
      "updated": 1540891755432,
      "created": 1540891755432
    },
    {
      "id": "5758048710688768_0_1506529354295_5746721642512384",
      "postId": "5758048710688768_0_1506529354295",
      "userId": 5746721642512384,
      "rating": 4,
      "subject": "great",
      "comments": "greatxsfew",
      "channelId": "5758048710688768_0",
      "created": 1537515367607,
      "updated": 1538378855332
    },
    {
      "id": "5765983402721280_0_1540660304208_5746721642512384",
      "postId": "5765983402721280_0_1540660304208",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5765983402721280_0",
      "created": 1540804318630,
      "updated": 1540804397330
    },
    {
      "id": "5746721642512384_2_1535440870456_5746721642512384",
      "postId": "5746721642512384_2_1535440870456",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5746721642512384_2",
      "created": 1535445060394,
      "updated": 1536220332562
    },
    {
      "id": "6453567359549440_1_1520739198673_5746721642512384",
      "postId": "6453567359549440_1_1520739198673",
      "userId": 5746721642512384,
      "rating": 5,
      "subject": "Super",
      "comments": "review",
      "channelId": "6453567359549440_1",
      "updated": 1533716286963,
      "created": 1533716286963
    },
    {
      "id": "5746721642512384_1_1540890196238_5746721642512384",
      "postId": "5746721642512384_1_1540890196238",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5746721642512384_1",
      "updated": 1540891432129,
      "created": 1540891432129
    },
    {
      "id": "5746721642512384_1_1540225573500_5746721642512384",
      "postId": "5746721642512384_1_1540225573500",
      "userId": 5746721642512384,
      "rating": 4,
      "subject": "My review",
      "comments": "review",
      "channelId": "5746721642512384_1",
      "created": 1540814203664,
      "updated": 1540814225154
    },
    {
      "id": "5746721642512384_1_1539611057815_5746721642512384",
      "postId": "5746721642512384_1_1539611057815",
      "userId": 5746721642512384,
      "rating": 5,
      "subject": "My review",
      "channelId": "5746721642512384_1",
      "updated": 1540814008132,
      "created": 1540814008132
    },
    {
      "id": "5765983402721280_0_1522617216244_5746721642512384",
      "postId": "5765983402721280_0_1522617216244",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5765983402721280_0",
      "created": 1534447116405,
      "updated": 1534447125199
    },
    {
      "id": "5746721642512384_1_1531847033779_5746721642512384",
      "postId": "5746721642512384_1_1531847033779",
      "userId": 5746721642512384,
      "rating": 4,
      "channelId": "5746721642512384_1",
      "created": 1539158634084,
      "updated": 1539158640501
    },
    {
      "id": "5746721642512384_1_1532431796973_5746721642512384",
      "postId": "5746721642512384_1_1532431796973",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5746721642512384_1",
      "created": 1534580194957,
      "updated": 1534580199519
    },
    {
      "id": "5746721642512384_1_1535703448679_5746721642512384",
      "postId": "5746721642512384_1_1535703448679",
      "userId": 5746721642512384,
      "rating": 5,
      "subject": "Title",
      "comments": "review",
      "channelId": "5746721642512384_1",
      "created": 1535704908632,
      "updated": 1535704955152
    },
    {
      "id": "5758048710688768_1_1518207745476_5746721642512384",
      "postId": "5758048710688768_1_1518207745476",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5758048710688768_1",
      "created": 1533282237864,
      "updated": 1534580181008
    },
    {
      "id": "5746721642512384_1_1531837922253_5746721642512384",
      "postId": "5746721642512384_1_1531837922253",
      "userId": 5746721642512384,
      "rating": 5,
      "channelId": "5746721642512384_1",
      "created": 1533037355358,
      "updated": 1540802318463
    }
  ]
}
